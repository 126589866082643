/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';

import { useAppDispatch, RootState } from '../../../../../store';
import 'react-datepicker/dist/react-datepicker.css';

import SideCreate from '../../../../../components/elements/SideCreate';
import { BidStages } from '../../../../../modules/bids/enums';
import { fetchPorts } from '../../../../../modules/ports/features';
import {
    fetchSingleBid,
    updateBid,
} from '../../../../../modules/bids/features/singleBidSlice';
import { resetBidStatus } from '../../../../../modules/bids/features/bidsSlice';

type FormData = {
    name: string;
    definitionDateFrom: Date;
    definitionDateTo: Date;
    bidDateFrom: Date;
    bidDateTo: Date;
    portId: string;
};

export default function EditBid({
    show = false,
    toggle,
}: {
    show: boolean;
    toggle: () => void;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<FormData>();

    const { t, i18n } = useTranslation();

    const dispatch = useAppDispatch();
    const ports = useSelector((state: RootState) => state.ports);
    const singleBid = useSelector((state: RootState) => state.singleBid);
    const [updateStatus, setUpdateStatus] = useState('idle');

    const definitionDateFrom = watch('definitionDateFrom');
    const definitionDateTo = watch('definitionDateTo');
    const bidDateFrom = watch('bidDateFrom');
    const bidDateTo = watch('bidDateTo');
    const startDate = new Date();
    const endDate = new Date();

    useEffect(() => {
        if (ports.status === 'idle') {
            dispatch(fetchPorts());
        }
    }, [dispatch, ports.status]);

    useEffect(() => {
        register('definitionDateFrom', { required: true });
        register('definitionDateTo', { required: true });
        register('bidDateFrom', { required: true });
        register('bidDateTo', { required: true });
    }, [register]);

    useEffect(() => {
        if (i18n.language === 'es') {
            registerLocale('es', es);
        } else {
            registerLocale('en', enUS);
        }
        setDefaultLocale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        if (
            singleBid.status === 'success' &&
            singleBid.data &&
            ports.status === 'success'
        ) {
            const bidDateStart = singleBid.data.stages?.filter(
                (stage) => stage.stage === BidStages.BID
            )[0].startDate;
            const bidDateEnd = singleBid.data.stages?.filter(
                (stage) => stage.stage === BidStages.BID
            )[0].endDate;

            const definitionDateEnd = singleBid.data.stages?.filter(
                (stage) => stage.stage === BidStages.DEFINITION
            )[0].endDate;
            setValue('name', singleBid.data.name);
            setValue('portId', singleBid.data.portId);
            setValue('definitionDateFrom', new Date(singleBid.data.startDate));
            setValue(
                'definitionDateTo',
                definitionDateEnd ? new Date(definitionDateEnd) : new Date()
            );
            setValue(
                'bidDateFrom',
                bidDateStart ? new Date(bidDateStart) : new Date()
            );
            setValue(
                'bidDateTo',
                bidDateEnd ? new Date(bidDateEnd) : new Date()
            );
        }
    }, [singleBid, setValue, ports.status]);

    const onSubmit = async (data: FormData) => {
        if (
            singleBid.status !== 'success' ||
            !singleBid.data ||
            !singleBid.data.id
        ) {
            return;
        }

        const stages = [
            {
                stage: BidStages.DEFINITION,
                startDate: data.definitionDateFrom,
                endDate: data.definitionDateTo,
            },
            {
                stage: BidStages.BID,
                startDate: data.bidDateFrom,
                endDate: data.bidDateTo,
            },
        ];

        const payload = {
            name: data.name,
            portId: data.portId,
            startDate: data.definitionDateFrom,
            endDate: data.definitionDateTo,
            stages,
        };

        try {
            setUpdateStatus('loading');
            await dispatch(
                updateBid({ data: payload, id: singleBid.data.id })
            ).unwrap();
            dispatch(resetBidStatus());
            setUpdateStatus('success');
            await dispatch(fetchSingleBid(singleBid.data.id)).unwrap();
        } catch (error) {
            setUpdateStatus('error');
        }
    };

    const onHide = () => {
        setUpdateStatus('idle');
        toggle();
    };

    return (
        <SideCreate show={show} title={t('bids.update.title')} toggle={onHide}>
            <div className="mb-20">
                {singleBid.status === 'success' && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.name')}
                                <div className="mt-1">
                                    <input
                                        id="name"
                                        type="text"
                                        className={`${
                                            errors.name
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('name', {
                                            required: true,
                                        })}
                                    />
                                    {errors.name && (
                                        <p className="text-red-600 ml-2">
                                            {errors.name.type === 'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                        <div className="mt-4">
                            <p className="font-bold">
                                {t('bids.create.deadlines')}
                            </p>
                        </div>
                        <div className="mt-2 flex space-x-2 items-center bg-gray-100 p-2 rounded-md">
                            <div className="w-1/3">
                                <p className="font-bold text-gray-600">
                                    {t('bids.stages.definition')}
                                </p>
                            </div>
                            <div className="w-1/3">
                                <label
                                    htmlFor="definitionDateFrom"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    <span>{t('common.from')}</span>
                                    <div className="mt-1">
                                        <DatePicker
                                            selected={definitionDateFrom}
                                            onChange={(date: Date) =>
                                                setValue(
                                                    'definitionDateFrom',
                                                    date
                                                )
                                            }
                                            minDate={startDate}
                                            selectsStart
                                            startDate={
                                                definitionDateFrom ?? startDate
                                            }
                                            endDate={
                                                definitionDateTo ?? endDate
                                            }
                                            dateFormat="P"
                                        />
                                        {errors.definitionDateFrom && (
                                            <p className="text-red-600 ml-2">
                                                {errors.definitionDateFrom
                                                    .type === 'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <div className="w-1/3">
                                <label
                                    htmlFor="definitionDateTo"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    <span>{t('common.to')}</span>
                                    <div className="mt-1">
                                        <DatePicker
                                            selected={definitionDateTo}
                                            onChange={(date: Date) =>
                                                setValue(
                                                    'definitionDateTo',
                                                    date
                                                )
                                            }
                                            minDate={
                                                definitionDateFrom ?? startDate
                                            }
                                            selectsEnd
                                            startDate={
                                                definitionDateFrom ?? startDate
                                            }
                                            endDate={
                                                definitionDateTo ?? endDate
                                            }
                                            dateFormat="P"
                                        />
                                        {errors.definitionDateTo && (
                                            <p className="text-red-600 ml-2">
                                                {errors.definitionDateTo
                                                    .type === 'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="mt-2 flex space-x-2 items-center bg-gray-100 p-2 rounded-md">
                            <div className="w-1/3">
                                <p className="font-bold text-gray-600">
                                    {t('bids.stages.bid')}
                                </p>
                            </div>
                            <div className="w-1/3">
                                <label
                                    htmlFor="bidDateFrom"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    <span>{t('common.from')}</span>
                                    <div className="mt-1">
                                        <DatePicker
                                            selected={bidDateFrom}
                                            onChange={(date: Date) =>
                                                setValue('bidDateFrom', date)
                                            }
                                            minDate={
                                                definitionDateTo ?? startDate
                                            }
                                            selectsStart
                                            startDate={
                                                bidDateFrom ?? definitionDateTo
                                            }
                                            endDate={
                                                bidDateTo ?? definitionDateTo
                                            }
                                            dateFormat="P"
                                        />
                                        {errors.bidDateFrom && (
                                            <p className="text-red-600 ml-2">
                                                {errors.bidDateFrom.type ===
                                                    'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <div className="w-1/3">
                                <label
                                    htmlFor="bidDateTo"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    <span>{t('common.to')}</span>
                                    <div className="mt-1">
                                        <DatePicker
                                            selected={bidDateTo}
                                            onChange={(date: Date) =>
                                                setValue('bidDateTo', date)
                                            }
                                            minDate={
                                                bidDateFrom ?? definitionDateTo
                                            }
                                            selectsEnd
                                            startDate={
                                                bidDateFrom ?? definitionDateTo
                                            }
                                            endDate={
                                                bidDateTo ?? definitionDateTo
                                            }
                                            dateFormat="P"
                                        />
                                        {errors.bidDateTo && (
                                            <p className="text-red-600 ml-2">
                                                {errors.bidDateTo.type ===
                                                    'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                        {/* START Ports */}
                        <div className="w-full mt-4 mb-4">
                            <label
                                htmlFor="portId"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.port')}*
                                <div className="mt-1">
                                    <select
                                        id="portId"
                                        className={`${
                                            errors.portId
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('portId', {
                                            required: true,
                                            validate: (value) => value !== '',
                                        })}
                                    >
                                        <option value="">
                                            {ports.status === 'loading'
                                                ? t('common.loading')
                                                : t('lots.create.selectAPort')}
                                        </option>
                                        {ports.status === 'success' &&
                                            ports.data.map((port) => (
                                                <option
                                                    key={port.id}
                                                    value={port.id}
                                                >
                                                    {port.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </label>
                            {errors.portId && (
                                <p className="text-red-600 ml-2">
                                    {t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* END Ports */}

                        <div className="mt-4">
                            {/* {error && (
                                <p className="text-red-600 mb-2">
                                    {handleSignInError(error)}
                                </p>
                            )} */}
                            {updateStatus === 'success' && (
                                <p className="text-green-600 mb-2">
                                    {t('bids.update.success')}
                                </p>
                            )}
                            <button
                                disabled={updateStatus === 'loading'}
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            >
                                {updateStatus === 'loading'
                                    ? t('wait.message')
                                    : t('common.save')}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </SideCreate>
    );
}
