export async function stall(stallTime = 3000) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, stallTime));
}

type DateOptions = {
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
    day?: 'numeric' | '2-digit';
    weekday?: 'narrow' | 'short' | 'long';
};

export const dateOptions: DateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};
