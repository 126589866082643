import axios from 'axios';
import { ChangeEvent, useRef, useState } from 'react';
import { t } from 'i18next';
import { getSignedUrl } from '../../../../../services/api/upload';

const acceptedMimeTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv',
    'text/plain',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/x-tar',
    'application/x-gzip',
    'application/x-bzip2',
    'application/x-7z-compressed',
    'application/x-rar-compressed',
    'application/x-zip-compressed',
    'application/zip',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.graphics',
    'application/vnd.oasis.opendocument.chart',
    'application/vnd.oasis.opendocument.formula',
    'application/vnd.oasis.opendocument.database',
    'application/vnd.oasis.opendocument.image',
];

const s3Update = async (file: File, key: string) => {
    try {
        const preSignedUrl = await getSignedUrl({
            file: {
                fileName: file.name,
                fileType: file.type,
                key,
            },
            type: 'upload',
        });
        const formData = new FormData();
        formData.append('Content-Type', file.type);
        formData.append('file', file);

        const response = await axios.put(preSignedUrl.signedUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });
        if (response.status === 200) {
            return {
                key,
                type: file.type,
                name: file.name,
            };
        }
        throw new Error('Upload failed');
    } catch (err) {
        throw new Error('Upload failed');
    }
};

export default function UpdateFile({ fileKey }: { fileKey: string }) {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const [uploadFileState, setUploadFileState] = useState('idle');
    const [errorMessage, setErrorMessage] = useState('');

    const handleClick = () => {
        if (hiddenFileInput?.current) {
            hiddenFileInput.current.click();
            setUploadFileState('idle');
            setErrorMessage('');
        }
    };

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        // const { files } = e.target;
        // const fileArr = Array.from(files);

        const file = e.target.files[0];

        try {
            setUploadFileState('loading');
            await s3Update(file, fileKey);
            // const uploadPromises = fileArr.map((f) => s3Update(f));

            // const uploads = await Promise.all(uploadPromises);

            // const upFiles = uploads.map((u) => ({
            //     key: u.key,
            //     type: u.type,
            //     name: u.name,
            //     bidId,
            //     offerId,
            // }));

            // await dispatch(createBidFiles(upFiles)).unwrap();
            setUploadFileState('success');
        } catch (err) {
            setUploadFileState('error');
        }

        // const reader = new FileReader();

        // reader.onload = (evt) => {
        //     if (!evt.target) {
        //         return;
        //     }

        //     const bstr = evt.target.result;
        // };
        // reader.readAsBinaryString(file);
    };
    return (
        <div className="text-black">
            <button
                type="button"
                disabled={uploadFileState === 'loading'}
                className="flex w-full h-auto items-center justify-center -mt-1"
                onClick={handleClick}
            >
                <label
                    htmlFor="file"
                    className={`flex items-center px-4 py-1 space-x-4  rounded-lg tracking-wide hover:bg-blue  ${
                        uploadFileState === 'loading'
                            ? 'text-white hover:text-gray-100 cursor-progress'
                            : 'text-sym-primary-200 hover:text-sym-primary-600'
                    }`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                    </svg>
                    {uploadFileState === 'loading' && (
                        <span className="bg-orange-800 text-orange-100 font-bold text-center mt-2 p-1">
                            {t('bids.positions.fileUploading')}
                        </span>
                    )}
                    {uploadFileState === 'success' && (
                        <span className="bg-green-800 text-green-100 font-bold text-center mt-2 p-1">
                            {t('bids.positions.fileUpdated')}
                        </span>
                    )}
                    {uploadFileState === 'error' && (
                        <span className="bg-red-800 text-red-100 font-bold text-center mt-2 p-1">
                            {errorMessage}
                        </span>
                    )}
                    <input
                        name="file"
                        type="file"
                        ref={hiddenFileInput}
                        className="hidden"
                        onChange={onChange}
                        accept={acceptedMimeTypes.concat().join(',')}
                    />
                </label>
            </button>
        </div>
    );
}
