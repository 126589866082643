import APIClient from './client';

export const getSignedUrl = async (data: {
    file?: { fileName?: string; fileType?: string; key?: string };
    type: 'download' | 'upload';
}) => {
    const response = await APIClient.postData('/users/upload', data);
    return response.data;
};

export const deleteFile = async (key: string) => {
    const response = await APIClient.deleteData(`/users/upload/${key}`);
    return response.data;
};
