import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IOfferPosition, IOfferPositionUpdateAttr } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface OfferPositionState {
    data: IOfferPosition[];
    status: Status;
    error: string | null;
}

const initialState: OfferPositionState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const updateOfferPositions = createAsyncThunk(
    'offerPositions/updateOfferPositions',
    async (positions: IOfferPositionUpdateAttr[]) => {
        const response = await APIClient.putData('/offerPositions', {
            positions,
        });
        return response.data;
    }
);

export const offerPositionsSlice = createSlice({
    name: 'offerPositions',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(updateOfferPositions.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data.push(action.payload);
            })
            .addCase(updateOfferPositions.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            });
    },
});
