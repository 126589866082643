function PriceBlock({ value }: { value?: number }) {
    return (
        <div className="w-full text-right">
            {value?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })}
        </div>
    );
}

PriceBlock.defaultProps = {
    value: 0,
};

export default PriceBlock;
