/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    ILot,
    ILotPosition,
    ILotOffer,
    IOfferLot,
    IBuyLot,
} from '../../../../modules/bids/interfaces';
import { BuyLotStatus } from '../../../../modules/bids/enums';
import { RootState, useAppDispatch } from '../../../../store';
import QualifyOfferLotPositionList from './QualifyOfferLotPositionList';
import { Button } from '../../../../components/elements/Buttons';
import { updateOfferPositions } from '../../../../modules/bids/features/offerPositionsSlice';
import { fetchSingleBid } from '../../../../modules/bids/features/singleBidSlice';
import RoleCheck from '../../../../components/RoleCheck';
import { UserRoles } from '../../../../modules/users/enums';
import useAuth from '../../../../context/useAuth';

export interface INewOfferPositions {
    id?: string;
    buyPositionId: string;
    volume?: number;
    price?: number;
    observationsPlain?: string;
    observationsRaw?: string;
    offerLotId?: string;
}

type FormData = {
    observationsRaw: string;
    observationsPlain: string;
    mill: string;
    shipmentDate: Date;
    paymentFormId: string;
    paymentTermsId: string;
    days: number | null;
    offerPositions: INewOfferPositions[];
    asked: { id: string; askedVolume: number; askedPrice: number }[];
};

export default function QualiOfferLotItem({
    lot,
    className,
    id,
    // buyPositions,
    status,
    cloned,
    buyLot,
    close,
}: {
    lot: ILot;
    className?: string;
    id: string;
    // buyPositions: IBuyPosition[];
    status: BuyLotStatus;
    cloned?: boolean;
    buyLot: IBuyLot;
    close: () => void;
}) {
    const dispatch = useAppDispatch();
    const { user } = useAuth();

    const {
        register,
        formState: { errors },
        setValue,
        watch,
        handleSubmit,
    } = useForm<FormData>({
        defaultValues: {
            offerPositions: [],
            asked: [],
        },
    });

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [editorFocus, setEditorFocus] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editor = useRef<any>(null);
    function focusEditor() {
        if (editor && editor.current) {
            editor.current.focus();
        }
    }

    useEffect(() => {
        const raw = JSON.stringify(
            convertToRaw(editorState.getCurrentContent())
        );

        const plain = editorState.getCurrentContent().getPlainText();
        setValue('observationsRaw', raw);
        setValue('observationsPlain', plain);
    }, [editorState, setValue]);

    const { t, i18n } = useTranslation();

    const [showDetails] = useState(true);

    const [currentOffer, setCurrentOffer] = useState<IOfferLot | undefined>();

    const [newPositions, setNewPositions] = useState<ILotPosition[]>();

    // const offerBid = useSelector((state: RootState) => {
    //     if (state.singleBid.data && state.singleBid.data.buyLots) {
    //         const offers = state.singleBid.data.buyLots.find(
    //             (l) => l.id === id
    //         )?.offers;
    //         if (offers) {
    //             return offers[0];
    //         }
    //     }
    //     return undefined;
    // });

    const buyPositions = useSelector((state: RootState) => {
        if (state.singleBid.data && state.singleBid.data.buyLots) {
            return state.singleBid.data.buyLots.find((l) => l.id === id)
                ?.positions;
        }
        return [];
    });

    const singleBid = useSelector((state: RootState) => state.singleBid.data);

    const paymentForm = useSelector((state: RootState) => state.paymentForm);
    const paymentTerms = useSelector((state: RootState) => state.paymentTerms);

    // GET VALUES FROM EXISTING OFFER
    useEffect(() => {
        if (singleBid && (!currentOffer || currentOffer.id !== id)) {
            const { offers } = buyLot;
            setValue('days', 0);
            if (!offers || offers.length === 0) {
                return;
            }
            const offer = offers[0];
            if (offer?.mill) {
                setValue('mill', offer.mill);
            }

            if (offer?.shipmentDate) {
                setValue('shipmentDate', new Date(offer.shipmentDate));
            }

            if (offer?.observationsRaw) {
                const content = convertFromRaw(
                    JSON.parse(offer.observationsRaw)
                );

                setEditorState(EditorState.createWithContent(content));
                setValue('observationsRaw', offer.observationsRaw);
            }

            if (offer?.observationsPlain) {
                setValue('observationsPlain', offer.observationsPlain);
            }
            if (offer?.days) {
                setValue('days', offer.days);
            }
            if (offer?.paymentFormId) {
                setValue('paymentFormId', offer.paymentFormId);
            }
            if (offer?.paymentTermsId) {
                setValue('paymentTermsId', offer.paymentTermsId);
            }

            setCurrentOffer(offer);
        }
    }, [singleBid, id, currentOffer, setValue, buyLot]);

    const [volumes, setVolumes] = useState<
        { id: string; lotPositionId: string; value: string }[]
    >([]);

    const [updateState, setUpdateState] = useState('idle');

    const shipmentDate = watch('shipmentDate');
    const askedValues = watch('asked');

    const initialShipmentDate = new Date();

    useEffect(() => {
        if (i18n.language === 'es') {
            registerLocale('es', es);
        } else {
            registerLocale('en', enUS);
        }
        setDefaultLocale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        register('shipmentDate', { required: true });
        register('observationsRaw');
        register('observationsPlain');
        register('offerPositions');
        register('asked');
    }, [register]);

    useEffect(() => {
        if (buyPositions && buyPositions.length > 0) {
            const lotVolumes = buyPositions.map((p) => ({
                id: p.id ?? '',
                lotPositionId: p.lotPositionId,
                value: p.volume?.toString() ?? '',
            }));

            if (status === BuyLotStatus.CONFIRMED) {
                // SHOW ONLY ACTIVE POSITIONS

                const activeLotsPositions = buyPositions
                    // eslint-disable-next-line array-callback-return
                    .map((bp) => {
                        const getPositon = lot.positions?.find(
                            (lp) => bp.lotPositionId === lp.id
                        );

                        if (getPositon) {
                            const offer = bp?.offerPositions?.find(
                                (op) =>
                                    buyLot.offers &&
                                    buyLot.offers[0].id &&
                                    op.offerLotId === buyLot.offers[0].id
                            );

                            return {
                                ...getPositon,
                                volume: bp.volume,
                                buyLotPositionId: bp.id,
                                offerPositionId: offer?.id ?? '',
                                offeredVolume: offer?.volume,
                                askedVolume: offer?.askedVolume,
                                price: offer?.price,
                                askedPrice: offer?.askedPrice,
                                comment: offer?.observationsPlain,
                            };
                        }
                    })
                    .filter((p) => p !== undefined) as ILotOffer[];

                setNewPositions(activeLotsPositions);
            }

            setVolumes(lotVolumes);
        }
    }, [buyLot.offers, buyPositions, lot, status]);

    const generalSpec = () => {
        if (lot.generalSpecsRaw) {
            return { __html: draftToHtml(JSON.parse(lot.generalSpecsRaw)) };
        }
        if (lot.generalSpecsPlain) {
            return { __html: lot.generalSpecsPlain };
        }
        return { __html: '' };
    };

    const updateData = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        type: 'askedVolume' | 'askedPrice',
        offerPositionId: string,
        value: string
    ) => {
        const askedIndex = askedValues.findIndex(
            (d) => d.id === offerPositionId
        );
        const position = newPositions?.find(
            (p) => p.offerPositionId === offerPositionId
        );

        if (askedIndex !== -1) {
            askedValues[askedIndex][type] = parseFloat(value);
            setValue('asked', askedValues);
        } else {
            let finalPrice = 0;
            let finalVolume = 0;

            if (type === 'askedPrice') {
                finalPrice = parseFloat(value);
                finalVolume = parseFloat(position?.askedVolume ?? '0');
            }

            if (type === 'askedVolume') {
                finalPrice = parseFloat(position?.askedPrice ?? '0');
                finalVolume = parseFloat(value);
            }

            askedValues.push({
                id: offerPositionId,
                askedPrice: finalPrice,
                askedVolume: finalVolume,
            });
            setValue('asked', askedValues);
        }
    };

    const submit = async (data: FormData) => {
        if (!buyLot.offers) {
            return;
        }
        try {
            setUpdateState('loading');
            await dispatch(updateOfferPositions(data.asked)).unwrap();
            setUpdateState('success');
            await dispatch(fetchSingleBid(buyLot.offers[0].bidId)).unwrap();
        } catch (error) {
            setUpdateState('error');
        }
    };

    return (
        <div
            className={`mt-4  last:mb-20 px-10 py-4 text-slate-800 ${className}`}
        >
            <header className="flex rounded-md py-2 items-strech  text-white text-left h-full bg-slate-700">
                <div className="w-2/12 border-r border-slate-300 px-4">
                    <p>{t('common.code')}</p>
                    <p>
                        <strong>
                            {lot.code} {cloned ? '(Duplicated)' : null}
                        </strong>
                    </p>
                </div>
                <div className="w-4/12 border-slate-300 px-4">
                    <p>{t('common.description')}</p>
                    <p>
                        <strong>{lot.descriptionEn}</strong>
                    </p>
                </div>

                <div className="w-2/12 border-l border-r border-slate-300 px-4">
                    <p>{t('lots.lotType')}</p>
                    <p>
                        <strong>{lot.type?.name}</strong>
                    </p>
                </div>
                <div className="w-2/12 px-4 border-r border-slate-300">
                    <p>{t('lots.lotMetric')}</p>
                    <p>
                        <strong>{lot.metric?.nameEn}</strong>
                    </p>
                </div>
                <div className="w-2/12 px-4">
                    <p>{t('lots.lotVolume')}</p>
                    <p>
                        <strong>
                            {volumes.reduce(
                                (acc, object) => acc + Number(object.value),
                                0
                            )}
                        </strong>
                    </p>
                </div>
            </header>

            {/* <button
                type="button"
                onClick={() => setShowDetails(!showDetails)}
                className="mt-4 mb-2 text-center w-full bg-slate-50 flex items-center justify-center"
            >
                {showDetails ? (
                    <>
                        <span className="mr-2">{t('common.hideDetails')}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 11l7-7 7 7M5 19l7-7 7 7"
                            />
                        </svg>
                    </>
                ) : (
                    <>
                        <span className="mr-2">{t('common.showDetails')}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                            />
                        </svg>
                    </>
                )}
            </button> */}

            {showDetails && (
                <div className="flex mb-2 pt-4 space-x-4">
                    {generalSpec() && (
                        <div className="w-1/3 bg-slate-100 p-4 rounded-md">
                            <h3 className="font-bold py-2">
                                {t('lots.generalSpecifications')}
                            </h3>
                            <hr className="border border-slate-500 mb-2" />
                            {/* <div dangerouslySetInnerHTML={generalSpec()} /> */}
                            <div>{lot.generalSpecsPlain}</div>
                        </div>
                    )}
                    <div className="w-1/3 bg-slate-100 p-4 rounded-md">
                        <h3 className="font-bold py-2">
                            {t('common.observations')}
                        </h3>
                        <hr className="border border-slate-500 mb-2" />
                        <div
                            role="textbox"
                            className={`bg-white h-52 overflow-y-auto border border-slate-200 p-2 rounded-lg my-4 ${
                                editorFocus && 'ring-2 ring-indigo-700'
                            }`}
                            onClick={focusEditor}
                            onKeyDown={focusEditor}
                        >
                            <Editor
                                ref={editor}
                                editorState={editorState}
                                readOnly
                                onChange={setEditorState}
                                placeholder=""
                                onFocus={() => setEditorFocus(true)}
                                onBlur={() => setEditorFocus(false)}
                            />
                        </div>
                    </div>
                    <div className="w-1/3 bg-slate-100 p-4 rounded-md">
                        <h3 className="font-bold py-2">
                            {t('common.details')}
                        </h3>
                        <hr className="border border-slate-500 mb-2" />
                        <div>
                            <div>
                                <label
                                    htmlFor="mill"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('lots.mill')}*
                                    <div className="mt-1">
                                        <input
                                            id="mill"
                                            type="text"
                                            disabled
                                            className={`${
                                                errors.mill
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('mill', {
                                                required: true,
                                            })}
                                        />
                                        {errors.mill && (
                                            <p className="text-red-600 ml-2">
                                                {errors.mill.type ===
                                                    'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <div className="mt-2">
                                <label
                                    htmlFor="shipmentDate"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    <span>{t('lots.shipmentDate')}*</span>
                                    <div className="mt-1">
                                        <DatePicker
                                            readOnly
                                            selected={shipmentDate}
                                            onChange={(date: Date) =>
                                                setValue('shipmentDate', date)
                                            }
                                            minDate={initialShipmentDate}
                                            selectsStart
                                            startDate={
                                                shipmentDate ??
                                                initialShipmentDate
                                            }
                                            dateFormat="P"
                                        />
                                        {errors.shipmentDate && (
                                            <p className="text-red-600 ml-2">
                                                {errors.shipmentDate.type ===
                                                    'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            {/* START PaymentTerms */}
                            <div className="w-full mt-4 mb-4">
                                <label
                                    htmlFor="paymentTermsId"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('lots.paymentTerms')}*
                                    <div className="mt-1">
                                        <select
                                            id="paymentTermsId"
                                            disabled
                                            className={`${
                                                errors.paymentTermsId
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('paymentTermsId', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        >
                                            <option value="">
                                                {paymentTerms.status ===
                                                'loading'
                                                    ? t('common.loading')
                                                    : t(
                                                          'lots.offer.selectPaymentTerm'
                                                      )}
                                            </option>
                                            {paymentTerms.status ===
                                                'success' &&
                                                paymentTerms.data.map(
                                                    (paymentTerm) => (
                                                        <option
                                                            key={paymentTerm.id}
                                                            value={
                                                                paymentTerm.id
                                                            }
                                                        >
                                                            {paymentTerm.name}
                                                        </option>
                                                    )
                                                )}
                                        </select>
                                    </div>
                                </label>
                                {errors.paymentTermsId && (
                                    <p className="text-red-600 ml-2">
                                        {t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                            {/* END PaymentTerm */}
                            {/* START Days */}
                            <div>
                                <label
                                    htmlFor="days"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('common.days')}*
                                    <div className="mt-1">
                                        <input
                                            id="days"
                                            type="number"
                                            disabled
                                            step={1}
                                            className={`${
                                                errors.days
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('days', {
                                                required: true,
                                                min: 0,
                                            })}
                                        />
                                        {errors.days && (
                                            <p className="text-red-600 ml-2">
                                                {errors.days.type ===
                                                    'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                                {errors.days.type === 'min' && (
                                                    <span>
                                                        {t(
                                                            'common.errors.equalGreaterThan'
                                                        )}{' '}
                                                        0
                                                    </span>
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            {/* END Days */}
                            {/* START PaymentForm */}
                            <div className="w-full mt-4 mb-4">
                                <label
                                    htmlFor="paymentFormId"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('lots.paymentForms')}*
                                    <div className="mt-1">
                                        <select
                                            id="paymentFormId"
                                            disabled
                                            className={`${
                                                errors.paymentFormId
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('paymentFormId', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        >
                                            <option value="">
                                                {paymentForm.status ===
                                                'loading'
                                                    ? t('common.loading')
                                                    : t(
                                                          'lots.offer.selectPaymentForm'
                                                      )}
                                            </option>
                                            {paymentForm.status === 'success' &&
                                                paymentForm.data.map(
                                                    (paymentTerm) => (
                                                        <option
                                                            key={paymentTerm.id}
                                                            value={
                                                                paymentTerm.id
                                                            }
                                                        >
                                                            {paymentTerm.name}
                                                        </option>
                                                    )
                                                )}
                                        </select>
                                    </div>
                                </label>
                                {errors.paymentFormId && (
                                    <p className="text-red-600 ml-2">
                                        {t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                            {/* END PaymentForm */}
                        </div>
                    </div>
                </div>
            )}

            {showDetails && lot.positions && lot.positions?.length > 0 && (
                <div className="mt-10">
                    <QualifyOfferLotPositionList
                        buyLotId={id}
                        lotPositions={newPositions ?? []}
                        type={lot.type.name}
                        volumes={volumes}
                        updateData={updateData}
                    />
                </div>
            )}
            <RoleCheck
                allowedRoles={[UserRoles.ADMIN, UserRoles.SHOPPING_LEADER]}
                currentRole={user?.role || 'USER'}
            >
                <div className="flex justify-end mt-10 space-x-4">
                    <div>
                        <Button
                            disabled={updateState === 'loading'}
                            type="button"
                            variant="link"
                            onClick={close}
                            label={t('common.close')}
                        />
                    </div>
                    <div className="w-1/3">
                        <Button
                            disabled={updateState === 'loading'}
                            variant="success"
                            type="button"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            onClick={handleSubmit(submit)}
                            label={
                                updateState === 'loading'
                                    ? t('wait.message')
                                    : t('common.save')
                            }
                        />
                    </div>
                </div>
            </RoleCheck>
        </div>
    );
}

QualiOfferLotItem.defaultProps = {
    className: '',
    cloned: false,
};
