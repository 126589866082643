/// More loaders https://cssloaders.github.io/

import './bars.css';
import './quarter-circle.css';

export interface ILoaderProps {
    loader?: 'quarter-circle' | 'bars';
}

export default function Loader({ loader = 'bars' }: ILoaderProps) {
    switch (loader) {
        case 'bars':
            return (
                <div className="symlab-loader__wrapper h-20 w-20">
                    <span className="symlab-loader__item bars text-symlab-loader-1" />
                </div>
            );

        case 'quarter-circle':
            return (
                <div className="symlab-loader__wrapper h-20 w-40">
                    <span className="symlab-loader__item quarter-circle border-symlab-loader-1 border-b-symlab-loader-2" />
                </div>
            );
        default:
            return (
                <div className="symlab-loader__wrapper h-20 w-40">
                    <span className="symlab-loader__item bars text-gray-800" />
                </div>
            );
    }
}
