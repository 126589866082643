import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../components/elements/Buttons';
import i18n from '../../../../../intl/i18n';
import { BidStages } from '../../../../../modules/bids/enums';
import { dateOptions } from '../../../../../utils/helpers';
import { IStage } from './interfaces';

export default function SingleStages({
    stages,
    currentStage,
}: {
    stages: IStage[];
    currentStage?: BidStages;
}) {
    const { t } = useTranslation();
    let locale = 'en-US';

    if (i18n.language === 'es') {
        locale = 'es-ES';
    }

    return (
        <div className="relative w-full my-10 px-16 mx-auto overflow-x-auto">
            <div className="flex-none md:flex md:space-x-10 md:justify-center">
                {stages.map((stage) => (
                    <div
                        key={stage.id}
                        className={`w-full mt-10 md:mt-0 mx-10 relative text-center md:w-auto ${
                            stage.slug === currentStage && 'text-slate-700'
                        }`}
                    >
                        <div
                            className={`
                                 
                                rounded-full 
                                mx-auto
                                w-24 
                                h-24
                                p-4
                                flex items-center justify-center 
                                border-4
                                ${
                                    stage.slug === currentStage
                                        ? 'border-slate-600 bg-green-200'
                                        : 'border-gray-400 bg-white'
                                }
                                `}
                        >
                            <img
                                src={stage.icon}
                                alt={stage.name}
                                className="w-12 h-12"
                            />
                        </div>
                        <p className="font-bold mt-2">{t(stage.name)}</p>
                        <div className="mt-2 text-sm">
                            <p>
                                {t('common.start')}:{' '}
                                {stage.startDate
                                    ? `
                                        ${new Date(
                                            stage.startDate
                                        ).toLocaleDateString(
                                            locale,
                                            dateOptions
                                        )}`
                                    : '-'}
                            </p>

                            <p>
                                {t('common.end')}:{' '}
                                {stage.endDate
                                    ? `
                                        ${new Date(
                                            stage.endDate
                                        ).toLocaleDateString(
                                            locale,
                                            dateOptions
                                        )}`
                                    : '-'}
                            </p>
                        </div>

                        {stage.subtext && (
                            <p className="text-xs">{stage.subtext}</p>
                        )}
                        <div className="mt-2">
                            {stage.slug === currentStage &&
                                stage.actions?.map((action) => (
                                    <Button
                                        key={action.label}
                                        label={t(action.label)}
                                        icon={action.icon ? action.icon : ''}
                                        type="button"
                                        onClick={action.action}
                                        variant="outline"
                                        size="sm"
                                    />
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

SingleStages.defaultProps = {
    currentStage: BidStages.BID,
};
