import { Routes, Route } from 'react-router-dom';

import useAuth from './context/useAuth';

/** UnAuthenticated Pages */
import Landing from './pages/Landing/Landing';
import SignIn from './pages/Landing/SignIn';
import SignUp from './pages/Landing/SignUp';
import ForgotSend from './pages/Landing/Forgot/Send';
import ForgotChange from './pages/Landing/Forgot/Change';
import Loading from './pages/Landing/Loading';

/** Authenticated Pages */
import Dashboard from './pages/Dashboard/Dashboard';
import Bids from './pages/Dashboard/Bids';
import ListBids from './pages/Dashboard/Bids/List';
import SingleBids from './pages/Dashboard/Bids/Single';
import Users from './pages/Dashboard/Users';
import ListUsers from './pages/Dashboard/Users/List';
import Lots from './pages/Dashboard/Lots';
import ListLots from './pages/Dashboard/Lots/List';
import AddLot from './pages/Dashboard/Lots/components/AddLot';
import EditLot from './pages/Dashboard/Lots/components/EditLot';
import LotAdmin from './pages/Dashboard/Lots/LotAdmin';

import Providers from './pages/Dashboard/Providers';
import ListProviders from './pages/Dashboard/Providers/List';
import NotFound from './pages/Dashboard/NotFound';
import { UserRoles } from './modules/users/enums';

import QuestionsAdmin from './pages/Dashboard/Questions/QuestionsAdmin';

function AdminRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />}>
                <Route path="/" element={<Bids />}>
                    <Route index element={<ListBids />} />
                    <Route path="/bids/:id" element={<SingleBids />} />
                </Route>
                <Route path="users" element={<Users />}>
                    <Route index element={<ListUsers />} />
                </Route>
                <Route path="providers" element={<Providers />}>
                    <Route index element={<ListProviders />} />
                </Route>
                <Route path="lots" element={<Lots />}>
                    <Route index element={<ListLots />} />
                    <Route path="edit/:id" element={<EditLot />} />
                    <Route path="add" element={<AddLot />} />
                    <Route path="admin" element={<LotAdmin />} />
                </Route>

                <Route path="/questions" element={<QuestionsAdmin />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

function BranchRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />}>
                <Route path="/" element={<Bids />}>
                    <Route index element={<ListBids />} />
                    <Route path="/bids/:id" element={<SingleBids />} />
                </Route>
                <Route path="lots" element={<Lots />}>
                    <Route index element={<ListLots />} />
                    <Route path="edit/:id" element={<EditLot />} />
                    <Route path="add" element={<AddLot />} />
                    <Route path="admin" element={<LotAdmin />} />
                </Route>

                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

function ProviderRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />}>
                <Route path="/" element={<Bids />}>
                    <Route index element={<ListBids />} />
                    <Route path="/bids/:id" element={<SingleBids />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

function ShoppingLeaderRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />}>
                <Route path="/" element={<Bids />}>
                    <Route index element={<ListBids />} />
                    <Route path="/bids/:id" element={<SingleBids />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

function App() {
    const { user, initialLoading } = useAuth();

    if (initialLoading) {
        return <Loading />;
    }
    if (user && user.role) {
        switch (user.role) {
            case UserRoles.ADMIN:
                return <AdminRoutes />;
            case UserRoles.PROVIDER:
                return <ProviderRoutes />;
            case UserRoles.SHOPPING_LEADER:
                return <ShoppingLeaderRoutes />;
            case UserRoles.USER:
                return <BranchRoutes />;
            default:
                return <NotFound />;
        }
    }

    return (
        <Routes>
            <Route path="/" element={<Landing />}>
                <Route index element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot" element={<ForgotSend />} />
                <Route path="/forgot/code" element={<ForgotChange />} />
                <Route path="*" element={<SignIn />} />
            </Route>
        </Routes>
    );
}

export default App;
