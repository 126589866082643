import { ILot } from '../../../../../../modules/bids/interfaces';

export default function AutoCompleteItem({
    item,
    onClick,
}: {
    item: ILot;
    onClick: () => void;
}) {
    return (
        <li>
            <button
                type="button"
                onClick={onClick}
                className="text-left hover:bg-slate-200 flex gap-4 p-4 w-full"
            >
                <div>
                    <h3 className="text-sm font-semibold">{item.code}</h3>
                    <p className="text-xs text-gray-600">
                        {item.descriptionEn}
                    </p>
                </div>
            </button>
        </li>
    );
}
