import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBuyLot, IBuyLotCreateAttr } from '../interfaces';
import { IBuyLotUpdateAttr } from '../interfaces/IBuyLotUpdateAttr';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface LotsState {
    data: IBuyLot[];
    status: Status;
    error: string | null;
}

const initialState: LotsState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchBuyLots = createAsyncThunk(
    'buyLots/fetchBuyLots',
    async () => {
        const response = await APIClient.getData('/buyLots');
        return response.data;
    }
);

export const addNewBuyLot = createAsyncThunk(
    'buyLots/addNewBuyLot',
    async (lot: IBuyLotCreateAttr) => {
        const response = await APIClient.postData('/buyOrders', lot);
        return response.data;
    }
);

export const addNewBuyLotBulk = createAsyncThunk(
    'buyLots/addNewBuyLotBulk',
    async (lots: IBuyLotCreateAttr[]) => {
        const response = await APIClient.postData('/buyOrders/bulk', lots);
        return response.data;
    }
);

export const updateBuyLot = createAsyncThunk(
    'buyLots/updateBuyLot',
    async (lot: { id: string; data: IBuyLotUpdateAttr }) => {
        const response = await APIClient.putData('/buyLots', lot);
        return response.data;
    }
);

export const deleteBuyLot = createAsyncThunk(
    'buyLots/deleteBuyLot',
    async (id: string) => {
        await APIClient.deleteData(`/buyLots/${id}`);
    }
);

export const buyLotsSlice = createSlice({
    name: 'buyLots',
    initialState,
    reducers: {
        setBuyLots: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBuyLots.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchBuyLots.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchBuyLots.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(addNewBuyLot.fulfilled, (state, action) => {
                state.data.push(action.payload);
            })
            .addCase(addNewBuyLot.rejected, (state, action) => {
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(addNewBuyLotBulk.rejected, (state, action) => {
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(updateBuyLot.fulfilled, (state, action) => {
                const index = state.data.findIndex(
                    (lot) => lot.id === action.payload.id
                );
                state.data[index] = action.payload;
            })
            .addCase(updateBuyLot.rejected, (state, action) => {
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(deleteBuyLot.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(deleteBuyLot.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            });
    },
});

export const { setBuyLots } = buyLotsSlice.actions;
