/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    ILot,
    ILotPosition,
    ILotOffer,
    IOfferLot,
    IOfferPosition,
    IBuyLot,
} from '../../../../modules/bids/interfaces';
import { BuyLotStatus, OfferLotStatus } from '../../../../modules/bids/enums';
import { RootState, useAppDispatch } from '../../../../store';
import BuyLotOfferPositionList from './OfferLotPositionList';
import {
    createOfferLots,
    deleteOfferLot,
    duplicateOfferLot,
    updateOfferLot,
} from '../../../../modules/bids/features/offerLotsSlice';
import { Button } from '../../../../components/elements/Buttons';
import { fetchSingleBid } from '../../../../modules/bids/features/singleBidSlice';

export interface INewOfferPositions {
    id?: string;
    buyPositionId: string;
    volume?: number;
    price?: number;
    originalPrice?: number;
    observationsPlain?: string;
    observationsRaw?: string;
    offerLotId?: string;
}

type FormData = {
    observationsRaw: string;
    observationsPlain: string;
    mill: string;
    shipmentDate: Date;
    paymentFormId: string;
    paymentTermsId: string;
    days: number | null;
    offerPositions: INewOfferPositions[];
};

export default function OfferLotItem({
    lot,
    className,
    id,
    // buyPositions,
    status,
    cloned,
    buyLot,
}: {
    lot: ILot;
    className?: string;
    id: string;
    // buyPositions: IBuyPosition[];
    status: BuyLotStatus;
    cloned?: boolean;
    buyLot: IBuyLot;
}) {
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
    } = useForm<FormData>({
        defaultValues: {
            offerPositions: [],
        },
    });

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [editorFocus, setEditorFocus] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editor = useRef<any>(null);
    function focusEditor() {
        if (editor && editor.current) {
            editor.current.focus();
        }
    }

    useEffect(() => {
        const raw = JSON.stringify(
            convertToRaw(editorState.getCurrentContent())
        );

        const plain = editorState.getCurrentContent().getPlainText();
        setValue('observationsRaw', raw);
        setValue('observationsPlain', plain);
    }, [editorState, setValue]);

    const { t, i18n } = useTranslation();

    const [showDetails, setShowDetails] = useState(false);

    const [currentOffer, setCurrentOffer] = useState<IOfferLot | undefined>();

    const [newPositions, setNewPositions] = useState<ILotPosition[]>();

    const buyPositions = useSelector((state: RootState) => {
        if (state.singleBid.data && state.singleBid.data.buyLots) {
            return state.singleBid.data.buyLots.find((l) => l.id === id)
                ?.positions;
        }
        return [];
    });

    const singleBid = useSelector((state: RootState) => state.singleBid.data);

    const paymentForm = useSelector((state: RootState) => state.paymentForm);
    const paymentTerms = useSelector((state: RootState) => state.paymentTerms);

    const [offerState, setOfferState] = useState('idle');
    const [cloneOfferState, setCloneOfferState] = useState('idle');

    const [invalidPositions, setInvalidPositions] = useState(false);

    // GET VALUES FROM EXISTING OFFER
    useEffect(() => {
        if (
            singleBid &&
            singleBid.id &&
            (!currentOffer || offerState === 'success')
        ) {
            const { offers } = buyLot;
            if (!offers || offers.length === 0) {
                return;
            }
            const offer = offers[0];
            if (offer?.mill) {
                setValue('mill', offer.mill);
            }

            if (offer?.shipmentDate) {
                setValue('shipmentDate', new Date(offer.shipmentDate));
            }

            if (offer?.observationsRaw) {
                const content = convertFromRaw(
                    JSON.parse(offer.observationsRaw)
                );

                setEditorState(EditorState.createWithContent(content));
                setValue('observationsRaw', offer.observationsRaw);
            }

            if (offer?.observationsPlain) {
                setValue('observationsPlain', offer.observationsPlain);
            }
            if (offer?.days) {
                setValue('days', offer.days);
            }
            if (offer?.paymentFormId) {
                setValue('paymentFormId', offer.paymentFormId);
            }
            if (offer?.paymentTermsId) {
                setValue('paymentTermsId', offer.paymentTermsId);
            }

            setCurrentOffer(offer);
        }
    }, [singleBid, id, currentOffer, setValue, buyLot, offerState]);

    const [volumes, setVolumes] = useState<
        { id: string; lotPositionId: string; value: string }[]
    >([]);

    const shipmentDate = watch('shipmentDate');
    const offerPositions = watch('offerPositions');
    const initialShipmentDate = new Date();

    const paymentId = watch('paymentTermsId');
    const [disabledDays, setDisabledDays] = useState(false);

    useEffect(() => {
        if (i18n.language === 'es') {
            registerLocale('es', es);
        } else {
            registerLocale('en', enUS);
        }
        setDefaultLocale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        register('shipmentDate', { required: true });
        register('observationsRaw');
        register('observationsPlain');
        register('offerPositions');
    }, [register]);

    useEffect(() => {
        if (buyPositions && buyPositions.length > 0) {
            const lotVolumes = buyPositions.map((p) => ({
                id: p.id ?? '',
                lotPositionId: p.lotPositionId,
                value: p.volume?.toString() ?? '',
            }));

            if (status === BuyLotStatus.CONFIRMED) {
                // SHOW ONLY ACTIVE POSITIONS

                const activeLotsPositions = buyPositions
                    // eslint-disable-next-line array-callback-return
                    .map((bp) => {
                        const getPosition = lot.positions?.find(
                            (lp) => bp.lotPositionId === lp.id
                        );

                        if (getPosition) {
                            const offer = bp?.offerPositions?.find(
                                (op) =>
                                    buyLot.offers &&
                                    buyLot.offers[0]?.id &&
                                    op.offerLotId === buyLot.offers[0].id
                            );

                            return {
                                ...getPosition,
                                volume: bp.volume,
                                buyLotPositionId: bp.id,
                                offeredVolume: offer?.volume,
                                price: offer?.price,
                                comment: offer?.observationsPlain,
                            };
                        }
                    })
                    .filter((p) => p !== undefined) as ILotOffer[];

                setNewPositions(activeLotsPositions);
            }

            setVolumes(lotVolumes);
        }
    }, [buyLot.offers, buyPositions, lot, status]);

    // Set paymentdays to 0 and disable if paymentId is Telex Transfer or CashAgainstDocuments
    useEffect(() => {
        if (paymentTerms.status === 'success' && paymentId) {
            const term = paymentTerms.data?.findIndex(
                (p) => p.id === paymentId
            );
            if (term !== -1) {
                if (
                    (paymentTerms.data &&
                        paymentTerms.data[term].name.toLowerCase() ===
                            'telex transfer') ||
                    (paymentTerms.data &&
                        paymentTerms.data[term].name.toLowerCase() ===
                            'cash against documents')
                ) {
                    setValue('days', 0);
                    setDisabledDays(true);
                } else {
                    setDisabledDays(false);
                }
            }
        }
    }, [paymentId, paymentTerms.data, paymentTerms.status, setValue]);

    const generalSpec = () => {
        if (lot.generalSpecsRaw) {
            return { __html: draftToHtml(JSON.parse(lot.generalSpecsRaw)) };
        }
        if (lot.generalSpecsPlain) {
            return { __html: lot.generalSpecsPlain };
        }
        return { __html: '' };
    };

    const updateValue = (
        type: 'comments' | 'prices' | 'volumesPos',
        buyPositionId: string,
        value: string
    ) => {
        switch (type) {
            case 'comments': {
                if (!value || value === '') {
                    return;
                }
                const exist = offerPositions.findIndex(
                    (c) => c.buyPositionId === buyPositionId
                );
                if (exist !== -1) {
                    offerPositions[exist].observationsPlain = value;
                } else {
                    offerPositions.push({
                        buyPositionId,
                        observationsPlain: value,
                    });
                }
                setValue('offerPositions', offerPositions);

                break;
            }
            case 'prices': {
                if (!value || parseFloat(value) < 0) {
                    return;
                }
                const exist = offerPositions.findIndex(
                    (c) => c.buyPositionId === buyPositionId
                );

                if (exist !== -1) {
                    offerPositions[exist].price = parseFloat(value);
                    offerPositions[exist].originalPrice = parseFloat(value);
                } else {
                    offerPositions.push({
                        buyPositionId,
                        price: parseFloat(value),
                        originalPrice: parseFloat(value),
                    });
                }

                setValue('offerPositions', offerPositions);

                break;
            }
            case 'volumesPos': {
                if (!value || parseFloat(value) < 0) {
                    return;
                }
                const exist = offerPositions.findIndex(
                    (c) => c.buyPositionId === buyPositionId
                );
                if (exist !== -1) {
                    offerPositions[exist].volume = parseFloat(value);
                } else {
                    offerPositions.push({
                        buyPositionId,
                        volume: parseFloat(value),
                    });
                }
                setValue('offerPositions', offerPositions);

                break;
            }
            default:
                break;
        }
    };

    const submit = async (data: FormData) => {
        setInvalidPositions(false);

        const offerLot = {
            bidId: singleBid?.id ?? '',
            buyLotId: id,
            mill: data.mill,
            observationsRaw: data.observationsRaw,
            observationsPlain: data.observationsPlain,
            shipmentDate: data.shipmentDate,
            paymentFormId: data.paymentFormId,
            paymentTermsId: data.paymentTermsId,
            days: data.days ? +data.days : 0,
        };

        let validPositions = true;

        offerPositions.forEach((of) => {
            if (of.volume && of.price) {
                if (of.volume > 0 && of.price === 0) {
                    validPositions = false;
                }
                if (of.volume === 0 && of.price > 0) {
                    validPositions = false;
                }
            }

            if (of.volume && !of.price) {
                validPositions = false;
            }

            if (!of.volume && of.price) {
                validPositions = false;
            }
        });

        if (!validPositions) {
            setInvalidPositions(true);
            return;
        }

        const request = {
            offerLot,
            offerPositions,
        };
        try {
            setOfferState('loading');
            await dispatch(createOfferLots(request)).unwrap();
            reset();
            setOfferState('success');
            await dispatch(fetchSingleBid(singleBid?.id ?? '')).unwrap();
        } catch (e) {
            setOfferState('error');
        }
    };

    const update = async (data: FormData) => {
        const offerLot = {
            mill: data.mill,
            observationsRaw: data.observationsRaw,
            observationsPlain: data.observationsPlain,
            shipmentDate: data.shipmentDate,
            days: data.days,
            paymentFormId: data.paymentFormId,
            paymentTermsId: data.paymentTermsId,
        };
        const updatedOfferPos: INewOfferPositions[] = [];
        const newOfferPos: INewOfferPositions[] = [];

        offerPositions.forEach((op) => {
            if (!buyLot.offers) {
                return;
            }
            const exist = buyLot.offers[0]?.positions?.find(
                (ob: IOfferPosition) => ob.buyPositionId === op.buyPositionId
            );

            if (exist) {
                updatedOfferPos.push({
                    ...op,
                    id: exist.id,
                });
            } else {
                newOfferPos.push({ ...op, offerLotId: buyLot.offers[0]?.id });
            }
        });

        let validPositions = true;

        newOfferPos.forEach((of) => {
            if (of.volume && of.price) {
                if (of.volume > 0 && of.price === 0) {
                    validPositions = false;
                }
                if (of.volume === 0 && of.price > 0) {
                    validPositions = false;
                }
            }

            if (of.volume && !of.price) {
                validPositions = false;
            }

            if (!of.volume && of.price) {
                validPositions = false;
            }
        });

        updatedOfferPos.forEach((of) => {
            if (of.volume === 0) {
                validPositions = false;
            }
            if (of.price === 0) {
                validPositions = false;
            }
            if (of.volume === 0 && of.price === 0) {
                validPositions = true;
            }
        });

        if (!validPositions) {
            setInvalidPositions(true);
            return;
        }

        const request = {
            id: buyLot.offers ? buyLot.offers[0]?.id ?? '' : '',
            body: {
                offerLot,
                updatedPositions: updatedOfferPos,
                newPositions: newOfferPos,
            },
        };

        try {
            setOfferState('loading');
            await dispatch(updateOfferLot(request)).unwrap();
            await dispatch(fetchSingleBid(singleBid?.id ?? '')).unwrap();
            setOfferState('success');
        } catch (e) {
            setOfferState('error');
        }
    };

    const clone = async () => {
        if (!currentOffer || !currentOffer.id || !singleBid || !singleBid.id) {
            return;
        }

        try {
            setCloneOfferState('loading');

            await dispatch(duplicateOfferLot(currentOffer.id)).unwrap();
            await dispatch(fetchSingleBid(singleBid.id)).unwrap();
            setCloneOfferState('success');
        } catch (error) {
            setCloneOfferState('idle');
        }
    };
    const deleteOffer = async () => {
        if (!currentOffer || !currentOffer.id || !singleBid || !singleBid.id) {
            return;
        }

        try {
            setCloneOfferState('loading');

            await dispatch(deleteOfferLot(currentOffer.id)).unwrap();
            await dispatch(fetchSingleBid(singleBid.id)).unwrap();
            setCloneOfferState('success');
        } catch (error) {
            setCloneOfferState('idle');
        }
    };

    return (
        <div
            className={`mt-4  last:mb-20 px-10 py-4 text-slate-800 ${className}`}
        >
            <header
                className={`flex rounded-md py-2 items-strech  text-white text-left h-full ${
                    cloned ? 'bg-orange-700' : 'bg-slate-700'
                } ${
                    currentOffer?.status === OfferLotStatus.CONFIRMED
                        ? 'bg-green-700'
                        : ''
                }`}
            >
                <div className="w-2/12 border-r border-slate-300 px-4">
                    <p>{t('common.code')}</p>
                    <p>
                        <strong>
                            {lot.code} {cloned ? '(Duplicated)' : null}{' '}
                            {currentOffer?.status === OfferLotStatus.CONFIRMED
                                ? `(${t('common.confirmed')})`
                                : null}
                        </strong>
                    </p>
                </div>
                <div className="w-4/12 border-slate-300 px-4">
                    <p>{t('common.description')}</p>
                    <p>
                        <strong>{lot.descriptionEn}</strong>
                    </p>
                </div>

                <div className="w-2/12 border-l border-r border-slate-300 px-4">
                    <p>{t('lots.lotType')}</p>
                    <p>
                        <strong>{lot.type?.name}</strong>
                    </p>
                </div>
                <div className="w-2/12 px-4 border-r border-slate-300">
                    <p>{t('lots.lotMetric')}</p>
                    <p>
                        <strong>{lot.metric?.nameEn}</strong>
                    </p>
                </div>
                <div className="w-2/12 px-4">
                    <p>{t('lots.lotVolume')}</p>
                    <p>
                        <strong>
                            {volumes.reduce(
                                (acc, object) => acc + Number(object.value),
                                0
                            )}
                        </strong>
                    </p>
                </div>
            </header>

            <button
                type="button"
                onClick={() => setShowDetails(!showDetails)}
                className="mt-4 mb-2 text-center w-full bg-slate-50 flex items-center justify-center"
            >
                {showDetails ? (
                    <>
                        <span className="mr-2">{t('common.hideDetails')}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 11l7-7 7 7M5 19l7-7 7 7"
                            />
                        </svg>
                    </>
                ) : (
                    <>
                        <span className="mr-2">{t('common.showDetails')}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                            />
                        </svg>
                    </>
                )}
            </button>

            {showDetails && (
                <div className="flex mb-2 pt-4 space-x-4">
                    {generalSpec() && (
                        <div className="w-1/3 bg-slate-100 p-4 rounded-md">
                            <h3 className="font-bold py-2">
                                {t('lots.generalSpecifications')}
                            </h3>
                            <hr className="border border-slate-500 mb-2" />
                            {/* <div dangerouslySetInnerHTML={generalSpec()} /> */}
                            <div>{lot.generalSpecsPlain}</div>
                        </div>
                    )}
                    <div className="w-1/3 bg-slate-100 p-4 rounded-md">
                        <h3 className="font-bold py-2">
                            {t('common.observations')}
                        </h3>
                        <hr className="border border-slate-500 mb-2" />
                        <div
                            role="textbox"
                            className={`bg-white h-52 overflow-y-auto border border-slate-200 p-2 rounded-lg my-4 ${
                                editorFocus && 'ring-2 ring-indigo-700'
                            }`}
                            onClick={focusEditor}
                            onKeyDown={focusEditor}
                        >
                            <Editor
                                ref={editor}
                                readOnly={
                                    currentOffer?.status ===
                                    OfferLotStatus.CONFIRMED
                                }
                                editorState={editorState}
                                onChange={setEditorState}
                                placeholder=""
                                onFocus={() => setEditorFocus(true)}
                                onBlur={() => setEditorFocus(false)}
                            />
                        </div>
                    </div>
                    <div className="w-1/3 bg-slate-100 p-4 rounded-md">
                        <h3 className="font-bold py-2">
                            {t('common.details')}
                        </h3>
                        <hr className="border border-slate-500 mb-2" />
                        <div>
                            <div>
                                <label
                                    htmlFor="mill"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('lots.mill')}*
                                    <div className="mt-1">
                                        <input
                                            id="mill"
                                            type="text"
                                            disabled={
                                                currentOffer?.status ===
                                                OfferLotStatus.CONFIRMED
                                            }
                                            className={`${
                                                errors.mill
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('mill', {
                                                required: true,
                                            })}
                                        />
                                        {errors.mill && (
                                            <p className="text-red-600 ml-2">
                                                {errors.mill.type ===
                                                    'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <div className="mt-2">
                                <label
                                    htmlFor="shipmentDate"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    <span>{t('lots.shipmentDate')}*</span>
                                    <div className="mt-1">
                                        <DatePicker
                                            disabled={
                                                currentOffer?.status ===
                                                OfferLotStatus.CONFIRMED
                                            }
                                            selected={shipmentDate}
                                            onChange={(date: Date) =>
                                                setValue('shipmentDate', date)
                                            }
                                            minDate={initialShipmentDate}
                                            selectsStart
                                            startDate={
                                                shipmentDate ??
                                                initialShipmentDate
                                            }
                                            dateFormat="P"
                                        />
                                        {errors.shipmentDate && (
                                            <p className="text-red-600 ml-2">
                                                {errors.shipmentDate.type ===
                                                    'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            {/* START PaymentTerms */}
                            <div className="w-full mt-4 mb-4">
                                <label
                                    htmlFor="paymentTermsId"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('lots.paymentTerms')}*
                                    <div className="mt-1">
                                        <select
                                            disabled={
                                                currentOffer?.status ===
                                                OfferLotStatus.CONFIRMED
                                            }
                                            id="paymentTermsId"
                                            className={`${
                                                errors.paymentTermsId
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('paymentTermsId', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        >
                                            <option value="">
                                                {paymentTerms.status ===
                                                'loading'
                                                    ? t('common.loading')
                                                    : t(
                                                          'lots.offer.selectPaymentTerm'
                                                      )}
                                            </option>
                                            {paymentTerms.status ===
                                                'success' &&
                                                paymentTerms.data.map(
                                                    (paymentTerm) => (
                                                        <option
                                                            key={paymentTerm.id}
                                                            value={
                                                                paymentTerm.id
                                                            }
                                                        >
                                                            {paymentTerm.name}
                                                        </option>
                                                    )
                                                )}
                                        </select>
                                    </div>
                                </label>
                                {errors.paymentTermsId && (
                                    <p className="text-red-600 ml-2">
                                        {t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                            {/* END PaymentTerm */}
                            {/* START Days */}
                            <div>
                                <label
                                    htmlFor="days"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('common.days')}*
                                    <div className="mt-1">
                                        <input
                                            id="days"
                                            disabled={
                                                currentOffer?.status ===
                                                    OfferLotStatus.CONFIRMED ||
                                                disabledDays
                                            }
                                            type="number"
                                            step={1}
                                            className={`${
                                                errors.days
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            } ${
                                                disabledDays
                                                    ? 'bg-gray-200'
                                                    : ''
                                            }`}
                                            {...register('days', {
                                                required: true,
                                                min: 0,
                                            })}
                                        />
                                        {errors.days && (
                                            <p className="text-red-600 ml-2">
                                                {errors.days.type ===
                                                    'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                                {errors.days.type === 'min' && (
                                                    <span>
                                                        {t(
                                                            'common.errors.equalGreaterThan'
                                                        )}{' '}
                                                        0
                                                    </span>
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            {/* END Days */}
                            {/* START PaymentForm */}
                            <div className="w-full mt-4 mb-4">
                                <label
                                    htmlFor="paymentFormId"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('lots.paymentForms')}*
                                    <div className="mt-1">
                                        <select
                                            disabled={
                                                currentOffer?.status ===
                                                OfferLotStatus.CONFIRMED
                                            }
                                            id="paymentFormId"
                                            className={`${
                                                errors.paymentFormId
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('paymentFormId', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        >
                                            <option value="">
                                                {paymentForm.status ===
                                                'loading'
                                                    ? t('common.loading')
                                                    : t(
                                                          'lots.offer.selectPaymentForm'
                                                      )}
                                            </option>
                                            {paymentForm.status === 'success' &&
                                                paymentForm.data.map(
                                                    (paymentTerm) => (
                                                        <option
                                                            key={paymentTerm.id}
                                                            value={
                                                                paymentTerm.id
                                                            }
                                                        >
                                                            {paymentTerm.name}
                                                        </option>
                                                    )
                                                )}
                                        </select>
                                    </div>
                                </label>
                                {errors.paymentFormId && (
                                    <p className="text-red-600 ml-2">
                                        {t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                            {/* END PaymentForm */}
                        </div>
                    </div>
                </div>
            )}

            {showDetails && lot.positions && lot.positions?.length > 0 && (
                <div className="mt-10">
                    <BuyLotOfferPositionList
                        buyLotId={id}
                        lotPositions={newPositions ?? []}
                        type={lot.type.name}
                        volumes={volumes}
                        setValue={updateValue}
                        confirmed={
                            currentOffer?.status === OfferLotStatus.CONFIRMED
                        }
                    />
                </div>
            )}
            {offerState === 'success' && (
                <div className="mt-4 flex justify-end">
                    <p className="text-green-900 mb-2  font-bold">
                        {t('bids.positions.updated')}
                    </p>
                </div>
            )}
            <div className="mt-4 flex justify-end space-x-4">
                {buyLot.offers &&
                    buyLot.offers.length === 0 &&
                    offerState !== 'success' && (
                        <div className="w-1/3">
                            <Button
                                disabled={offerState === 'loading'}
                                type="button"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                onClick={handleSubmit(submit)}
                                label={
                                    offerState === 'loading'
                                        ? t('wait.message')
                                        : t('common.save')
                                }
                            />
                        </div>
                    )}
                {((buyLot.offers && buyLot.offers.length > 0) ||
                    offerState === 'success') &&
                    currentOffer?.status !== OfferLotStatus.CONFIRMED && (
                        <>
                            <div className="w-1/5">
                                {!cloned && (
                                    <Button
                                        disabled={cloneOfferState === 'loading'}
                                        type="button"
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                                />
                                            </svg>
                                        }
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium"
                                        variant="outline"
                                        onClick={clone}
                                        label={
                                            cloneOfferState === 'loading'
                                                ? t('wait.message')
                                                : t('common.duplicate')
                                        }
                                    />
                                )}
                                {cloned && (
                                    <Button
                                        disabled={cloneOfferState === 'loading'}
                                        type="button"
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                className="w-5 h-5"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium"
                                        variant="danger"
                                        onClick={deleteOffer}
                                        label={
                                            cloneOfferState === 'loading'
                                                ? t('wait.message')
                                                : t('common.delete')
                                        }
                                    />
                                )}
                            </div>
                            <div className="w-1/3">
                                <Button
                                    disabled={offerState === 'loading'}
                                    variant="success"
                                    type="button"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    onClick={handleSubmit(update)}
                                    label={
                                        offerState === 'loading'
                                            ? t('wait.message')
                                            : t('common.update')
                                    }
                                />
                            </div>
                        </>
                    )}
            </div>
            <div className="mt-2 text-right">
                {invalidPositions && (
                    <p className="text-red-500">
                        {t('bids.positions.invalid')}
                    </p>
                )}
            </div>
        </div>
    );
}

OfferLotItem.defaultProps = {
    className: '',
    cloned: false,
};
