/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { useMemo, useRef, useState } from 'react';
import { createAutocomplete } from '@algolia/autocomplete-core';

export default function AutoComplete({
    getItems,
    sourceId,
    Item,
    onClick,
    placeholder = 'Search',
}) {
    const [autocompleteState, setAutocompleteState] = useState({
        collections: [],
        isOpen: false,
    });

    const autocomplete = useMemo(
        () =>
            createAutocomplete({
                placeholder,
                onStateChange: ({ state }) => setAutocompleteState(state),
                getSources: () => [
                    {
                        sourceId,
                        getItems,
                        getItemInputValue: (item) => item.query,
                    },
                ],
                openOnFocus: true,
            }),
        [getItems, sourceId, placeholder]
    );

    const formRef = useRef(null);
    const inputRef = useRef(null);
    const panelRef = useRef(null);

    const formProps = autocomplete.getFormProps({
        inputElement: inputRef.current,
    });
    const inputProps = autocomplete.getInputProps({
        inputElement: inputRef.current,
    });

    return (
        <form ref={formRef} className="flex justify-start mb-10" {...formProps}>
            <div className="flex relative mt-4 rounded-md w-full">
                <input
                    ref={inputRef}
                    className="flex-1 p-2 pl-4 rounded-md w-full"
                    {...inputProps}
                />

                {autocompleteState.isOpen && (
                    <div
                        className="absolute w-full mt-12 top-0 left-0 border border-gray-100 bg-white overflow-hidden rounded-lg shadow-lg z-10 max-h-96 overflow-y-auto"
                        ref={panelRef}
                        {...autocomplete.getPanelProps()}
                    >
                        {autocompleteState.collections.map((collection) => {
                            const { items } = collection;

                            return (
                                <section key={collection.items[0]?.id ?? 0}>
                                    {items.length > 0 && (
                                        <ul
                                            className="divide-y-2 mb-40"
                                            {...autocomplete.getListProps()}
                                        >
                                            {items.map((item, index) => (
                                                <Item
                                                    key={item.id}
                                                    item={item}
                                                    onClick={() => {
                                                        onClick(item.id);
                                                        autocomplete.setIsOpen(
                                                            false
                                                        );
                                                        autocomplete.setActiveItemId(
                                                            index
                                                        );
                                                    }}
                                                />
                                            ))}
                                        </ul>
                                    )}
                                </section>
                            );
                        })}
                    </div>
                )}
            </div>
        </form>
    );
}
