import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../../../store';

import {
    BidStages,
    BidStatus,
    BuyLotStatus,
    OfferLotStatus,
} from '../../../modules/bids/enums';
import { UserRoles } from '../../../modules/users/enums';
import { IBuyLot, ILot } from '../../../modules/bids/interfaces';

import { IQualyOffer, IReviewOffer } from './components/Single/interfaces';

import { fetchLotsByBranch } from '../../../modules/bids/features/lotsSlice';
import { fetchPaymentForm } from '../../../modules/bids/features/paymentFormSlice';
import { fetchPaymentTerms } from '../../../modules/bids/features/paymentTermsSlice';
import { fetchProviders } from '../../../modules/providers/features/providersSlice';

import {
    fetchSingleBid,
    updateBid,
    updateBidStage,
} from '../../../modules/bids/features/singleBidSlice';

import BuyLotItem from './components/Single/Definition/BuyLotItem';
import BuyLotItemOffer from './components/OfferLotItem';
import { Button } from '../../../components/elements/Buttons';
import { ButtonConfirmDefinition } from './components/Single/Definition';
import ContractTable from './components/Single/ContractTable';
import LoadingAnimated from '../../../components/elements/Loading';
import Modal from '../../../components/Modal';
import NegotiationTable from './components/Single/NegotiationTable';
import NegotiationTableAdmin from './components/Single/NegotiationTableAdmin';
import NewLot from './components/Single/NewLot';
import PageHeader from '../../../components/PageHeader';
import QualyTable from './components/Single/QualyTable';
import ReviewTable from './components/Single/ReviewTable';
import RoleCheck from '../../../components/RoleCheck';
import SingleHeader from './components/Single/SingleHeader';
import SingleStages from './components/Single/SingleStages';
import useAuth from '../../../context/useAuth';
import { getStages } from './helpers';
import Review from './components/Single/Review';
import { resetBidStatus } from '../../../modules/bids/features/bidsSlice';

export default function SingleBid() {
    const { user } = useAuth();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const params = useParams();
    const { id } = params;

    const singleBid = useSelector((state: RootState) => state.singleBid);
    const lotsByBranch = useSelector((state: RootState) => state.lots);

    const paymentForm = useSelector((state: RootState) => state.paymentForm);
    const paymentTerms = useSelector((state: RootState) => state.paymentTerms);
    const providers = useSelector((state: RootState) => state.providers);
    const [showNewLot, setShowNewLot] = useState(false);
    const [updateState, setUpdateState] = useState('idle');
    const [reviewState, setReviewState] = useState('idle');
    const [openModal, setOpenModal] = useState(false);
    const [openUserModal, setOpenUserModal] = useState(false);
    const [openReturnDefinitionModal, setOpenReturnDefinitionModal] =
        useState(false);
    const [openBackModal, setOpenBackModal] = useState(false);
    const [reviewOfferLots, setReviewOfferLots] = useState<IReviewOffer[]>([]);
    const [reviewCompleted, setReviewCompleted] = useState(false);
    const [qualyOfferLots, setQualyOfferLots] = useState<IQualyOffer[]>([]);
    const [filteredQualyOfferLots, setFilteredQualyOfferLots] = useState<
        IQualyOffer[]
    >([]);
    const [filtered, setFiltered] = useState(false);
    const [qualyOffersCalled, setQualyOffersCalled] = useState(false);

    const [offerLots, setOfferLots] = useState<
        { lot: ILot; buyLot: IBuyLot; cloned: boolean }[]
    >([]);

    const switchBid = useCallback(async () => {
        if (!id) {
            return;
        }
        await dispatch(fetchSingleBid(id)).unwrap();
        setOfferLots([]);
        setShowNewLot(false);
        setUpdateState('idle');
        setReviewOfferLots([]);
        setReviewCompleted(false);
        setQualyOffersCalled(false);
        setQualyOfferLots([]);

        setFiltered(false);
    }, [dispatch, id]);

    useEffect(() => {
        if ((singleBid.status === 'idle' || singleBid.data?.id !== id) && id) {
            switchBid();
        }
    }, [dispatch, id, singleBid, switchBid]);

    useEffect(() => {
        if (providers.status === 'idle') {
            dispatch(fetchProviders());
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if (paymentForm.status === 'idle') {
            dispatch(fetchPaymentForm());
        }
    }, [paymentForm, dispatch]);
    useEffect(() => {
        if (paymentTerms.status === 'idle') {
            dispatch(fetchPaymentTerms());
        }
    }, [paymentTerms, dispatch]);

    useEffect(() => {
        if (
            singleBid.status === 'success' &&
            singleBid.data?.branch.id &&
            lotsByBranch.status === 'idle'
        ) {
            dispatch(fetchLotsByBranch(singleBid.data?.branch.id));
        } else if (
            singleBid.status === 'success' &&
            // Ya entramos a un detalle anteriormente queremos chequear si es una nueva sucursal para actualizar los lotes
            singleBid.data?.branch.id &&
            lotsByBranch.data?.length > 0 &&
            lotsByBranch.data[0].branchId !== singleBid.data?.branch.id
        ) {
            dispatch(fetchLotsByBranch(singleBid.data.branch.id));
        }
    }, [dispatch, singleBid.data?.branch.id, lotsByBranch, singleBid.status]);

    useEffect(() => {
        if (
            singleBid.status === 'success' &&
            (singleBid.data?.currentStage === BidStages.REVIEW ||
                singleBid.data?.currentStage === BidStages.PREQUALIFICATION ||
                singleBid.data?.currentStage === BidStages.NEGOTIATION ||
                singleBid.data?.currentStage === BidStages.CONTRACTS) &&
            reviewOfferLots.length === 0 &&
            lotsByBranch.status === 'success' &&
            paymentForm.status === 'success' &&
            paymentTerms.status === 'success' &&
            providers.status === 'success' &&
            !reviewCompleted
        ) {
            const reviewLots: IReviewOffer[] = [];
            let completed = true;
            singleBid.data.buyLots?.forEach((bl) => {
                const lot = lotsByBranch.data?.find((l) => l.id === bl.lotId);

                if (!lot) {
                    return;
                }

                bl.offers?.forEach((o) => {
                    if (
                        o.status !== OfferLotStatus.REJECTED &&
                        o.status !== OfferLotStatus.APPROVED &&
                        o.status !== OfferLotStatus.PENDING
                    ) {
                        completed = false;
                    }
                    const newBl = { ...bl };
                    const pf = paymentForm.data.find(
                        (pfo) => pfo.id === o.paymentFormId
                    );
                    const pt = paymentTerms.data.find(
                        (pto) => pto.id === o.paymentTermsId
                    );
                    const prov = providers.data.find(
                        (pvo) => pvo.id === o.providerId
                    );

                    newBl.offers = [o];
                    // calculate total volume
                    const totalVolume = o.positions?.reduce(
                        (acc, p) => acc + parseFloat(p.volume.toString()),
                        0
                    );
                    // calculate ponderation of price
                    let totalPrice = 0;
                    const sumPrice = o.positions
                        ?.map((p) => p.price * p.volume)
                        .reduce((a, b) => a + b, 0);

                    if (totalVolume && sumPrice) {
                        totalPrice = sumPrice / totalVolume;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const pos: any = {
                        lotId: lot?.id,
                        lotName: lot?.code,
                        providerId: o.providerId,
                        provider: prov?.name,
                        offers: o.positions?.length ?? 0,
                        price: totalPrice,
                        volume: totalVolume,
                        shipmentDate: o.shipmentDate,
                        paymentTermsId: o.paymentTermsId,
                        paymentFormId: o.paymentFormId,
                        paymentForm: pf?.name,
                        paymentTerms: pt?.name,
                        port: singleBid.data?.port?.name,
                        comments: !!o.observationsPlain,
                        lot,
                        buyLot: newBl,
                        offerLotId: o.id,
                        offerStatus: o.status,
                        offer: o,
                    };
                    reviewLots.push(pos);
                });
            });

            setReviewOfferLots(reviewLots.flat());
            setReviewCompleted(completed);
        }
    }, [
        lotsByBranch,
        reviewOfferLots,
        singleBid,
        paymentForm,
        paymentTerms,
        providers,
        reviewCompleted,
    ]);

    useEffect(() => {
        if (
            singleBid.status === 'success' &&
            (singleBid.data?.currentStage === BidStages.PREQUALIFICATION ||
                singleBid.data?.currentStage === BidStages.NEGOTIATION ||
                singleBid.data?.currentStage === BidStages.CONTRACTS ||
                singleBid.data?.currentStage === BidStages.RECEPTION ||
                singleBid.data?.currentStage === BidStages.CLOSED) &&
            qualyOfferLots.length === 0 &&
            lotsByBranch.status === 'success' &&
            paymentForm.status === 'success' &&
            paymentTerms.status === 'success' &&
            providers.status === 'success' &&
            !qualyOffersCalled
        ) {
            const reviewLots: IQualyOffer[] = [];

            singleBid.data.buyLots?.forEach((bl) => {
                const lot = lotsByBranch.data?.find((l) => l.id === bl.lotId);
                if (!lot) {
                    return;
                }

                bl.offers?.forEach((o) => {
                    if (
                        o.status === OfferLotStatus.APPROVED ||
                        o.status === OfferLotStatus.CONFIRMED ||
                        o.status === OfferLotStatus.PENDING
                    ) {
                        const newBl = { ...bl };
                        const pf = paymentForm.data.find(
                            (pfo) => pfo.id === o.paymentFormId
                        );
                        const pt = paymentTerms.data.find(
                            (pto) => pto.id === o.paymentTermsId
                        );
                        const prov = providers.data.find(
                            (pvo) => pvo.id === o.providerId
                        );

                        newBl.offers = [o];

                        const totalPrice =
                            o.positions?.reduce(
                                (acc, p) =>
                                    acc +
                                    parseFloat(p.price.toString()) *
                                        parseFloat(p.volume.toString()),
                                0
                            ) ?? 0;

                        const totalVolume =
                            o.positions?.reduce(
                                (acc, p) =>
                                    acc + parseFloat(p.volume.toString()),
                                0
                            ) ?? 0;

                        const offeredPriceMap = o.positions?.map(
                            (p) => (p.price * p.volume) / totalVolume
                        );

                        const offeredPrice =
                            offeredPriceMap?.reduce((acc, p) => acc + p, 0) ??
                            0;

                        const equivalentPrice =
                            offeredPrice / (1 + ((3 / 100) * o.days) / 360);

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const pos: any = {
                            lotId: lot?.id,
                            lotName: lot?.code,
                            lotFamily: lot?.family?.name,
                            providerId: o.providerId,
                            provider: prov?.name,
                            offers: o.positions?.length ?? 0,
                            price: totalPrice,
                            volume: totalVolume,
                            offeredPrice,
                            equivalentPrice,
                            shipmentDate: o.shipmentDate,
                            paymentTermsId: o.paymentTermsId,
                            paymentFormId: o.paymentFormId,
                            paymentForm: pf?.name,
                            paymentTerms: pt?.name,
                            paymentDays: o.days,
                            port: singleBid.data?.port?.name,
                            comments: !!o.observationsPlain,
                            lot,
                            buyLot: newBl,
                            offerLotId: o.id,
                            offerStatus: o.status,
                            offer: o,
                            days: o.days,
                        };
                        reviewLots.push(pos);
                    }
                });
            });
            setQualyOfferLots(reviewLots.flat());
            setQualyOffersCalled(true);
        }
    }, [
        lotsByBranch,
        qualyOfferLots,
        singleBid,
        paymentForm,
        paymentTerms,
        providers,
        qualyOffersCalled,
    ]);

    const [allOfferConfirmed, setAllOfferConfirmed] = useState(false);

    useEffect(() => {
        if (
            singleBid.status === 'success' &&
            singleBid.data?.currentStage === BidStages.BID &&
            user?.role === UserRoles.PROVIDER &&
            !allOfferConfirmed
        ) {
            let allConfirmed = true;
            singleBid.data?.buyLots?.forEach((bl) => {
                if (bl && bl?.offers?.length === 0) {
                    allConfirmed = false;
                }
                if (bl && bl?.offers?.length && bl?.offers?.length > 0) {
                    bl?.offers?.forEach((o) => {
                        if (o && o?.status !== OfferLotStatus.CONFIRMED) {
                            allConfirmed = false;
                        }
                    });
                }
            });
            setAllOfferConfirmed(allConfirmed);
        }
    }, [singleBid, user, allOfferConfirmed]);

    useEffect(() => {
        if (
            offerLots.length === 0 &&
            lotsByBranch.status === 'success' &&
            singleBid.data?.buyLots &&
            singleBid.data.currentStage === BidStages.BID
        ) {
            singleBid.data.buyLots.forEach((buyLot) => {
                const lot = lotsByBranch.data.find(
                    (l) => l.id === buyLot.lotId
                );
                if (!lot) {
                    return;
                }
                if (buyLot.offers && buyLot.offers.length > 1) {
                    // Esto es necesario para manejar los clones
                    const newBuyLots = buyLot.offers.map((offer, index) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const off: any = {
                            lot,
                            buyLot: {
                                ...buyLot,
                                offers: [offer],
                            },
                            cloned: index > 0,
                        };
                        return off;
                    }) as { lot: ILot; buyLot: IBuyLot; cloned: boolean }[];

                    setOfferLots((prev) => [...prev, ...newBuyLots]);
                } else {
                    setOfferLots((prev) => [
                        ...prev,
                        { lot, buyLot, cloned: false },
                    ]);
                }
            });
        }
    }, [singleBid, lotsByBranch, offerLots]);

    const toggleNewLot = () => {
        setShowNewLot(!showNewLot);
    };

    const confirmBid = async (back?: boolean) => {
        if (!id) {
            return;
        }
        setShowNewLot(false);
        try {
            setUpdateState('loading');

            await dispatch(
                updateBidStage({ id, back: back ?? false })
            ).unwrap();
            await dispatch(
                updateBid({ data: { status: BidStatus.ACTIVE }, id })
            ).unwrap();
            await dispatch(fetchSingleBid(id)).unwrap();
            dispatch(resetBidStatus());
            dispatch(resetBidStatus());
            setUpdateState('success');
        } catch (e) {
            setUpdateState('error');
        }
    };

    const reload = async () => {
        if (!id) {
            return;
        }
        await dispatch(fetchSingleBid(id)).unwrap();
        setReviewCompleted(false);
        setReviewOfferLots([]);
    };

    const filterOrders = (
        familyFilter: string,
        providerFilter: string,
        paymentTermFilter?: string
    ) => {
        if (
            familyFilter === '' &&
            providerFilter === '' &&
            (!paymentTermFilter || paymentTermFilter === '')
        ) {
            setFilteredQualyOfferLots([]);
            setFiltered(false);
            return;
        }

        const filteredOffers = qualyOfferLots
            .filter((r) => {
                if (familyFilter !== '') {
                    return r.lotFamily === familyFilter;
                }
                return r;
            })
            .filter((rp) => {
                if (providerFilter !== '') {
                    return rp.provider === providerFilter;
                }
                return rp;
            })
            .filter((rt) => {
                if (paymentTermFilter !== '') {
                    return rt.paymentTerms === paymentTermFilter;
                }
                return rt;
            });

        setFilteredQualyOfferLots(filteredOffers);
        setFiltered(true);
    };

    const sendLotsToReview = async (status: BidStatus) => {
        if (!id) {
            return;
        }
        try {
            setReviewState('loading');
            await dispatch(updateBid({ data: { status }, id })).unwrap();
            dispatch(resetBidStatus());
            setReviewState('success');
            await dispatch(fetchSingleBid(id)).unwrap();
        } catch (e) {
            setReviewState('error');
        }
    };

    const goBacktoReview = async () => {
        if (!id) {
            return;
        }
        try {
            setReviewState('loading');
            await dispatch(
                updateBidStage({
                    id,
                    back: true,
                })
            ).unwrap();
            dispatch(resetBidStatus());
            setReviewState('success');
            await dispatch(fetchSingleBid(id)).unwrap();
        } catch (e) {
            setReviewState('error');
        }
    };

    if (id && singleBid.status === 'success' && singleBid.data) {
        return (
            <div className="flex flex-col justify-center text-sym-loader-1 text-left pb-48">
                <PageHeader title={t('bids.singleTitle')} />
                <hr className="my-4" />
                {/* 1. SHOW BID STAGES ICONS */}
                {singleBid.data.stages && (
                    <>
                        <SingleHeader bid={singleBid.data} />
                        <SingleStages
                            stages={getStages(singleBid.data.stages, [
                                {
                                    stage: BidStages.DEFINITION,
                                    action:
                                        (user?.role === UserRoles.USER ||
                                            user?.role === UserRoles.ADMIN) &&
                                        new Date(
                                            singleBid.data.startDate
                                        ).getTime() <= new Date().getTime() &&
                                        singleBid.data.status !==
                                            BidStatus.REVIEW
                                            ? [
                                                  {
                                                      label: 'bids.actions.addLot.title',
                                                      icon: null,
                                                      action: () => {
                                                          toggleNewLot();
                                                      },
                                                  },
                                              ]
                                            : [],
                                },
                            ])}
                            currentStage={
                                singleBid.data.currentStage ===
                                    BidStages.PREQUALIFICATION &&
                                user?.role === UserRoles.PROVIDER
                                    ? BidStages.REVIEW
                                    : singleBid.data.currentStage
                            }
                        />
                    </>
                )}
                {/* END SHOW BID STAGES ICONS */}

                {lotsByBranch.status === 'loading' && (
                    <div className="flex justify-center">
                        <LoadingAnimated message={t('common.loading')} />
                    </div>
                )}

                {/* 2. DEFINITION STAGE */}
                {singleBid.data.currentStage === BidStages.DEFINITION &&
                    lotsByBranch.status === 'success' && (
                        <div className="w-full px-10 mx-auto">
                            {/* 2.1. START NEW LOT BOX */}
                            {showNewLot && (
                                <NewLot bidId={id} toggleShow={toggleNewLot} />
                            )}
                            {/* END NEW LOT BOX */}
                            {singleBid.data.buyLots &&
                                singleBid.data.buyLots.length > 0 && (
                                    <>
                                        {/* SHOW ON REVIEW MESSAGE */}
                                        {singleBid.data.status ===
                                            BidStatus.REVIEW && (
                                            <h3 className="text-2xl font-bold text-center text-green-900 uppercase border-t border-green-900 border-b py-2">
                                                {user?.role ===
                                                (UserRoles.ADMIN ||
                                                    UserRoles.SHOPPING_LEADER)
                                                    ? 'Listo para revisión'
                                                    : t('bids.review.title')}
                                            </h3>
                                        )}
                                        {/* END SHOW ON REVIEW MESSAGE */}

                                        {/* SHOW BRANCH USER SEND LOTS FOR REVIEW DEFINITION STAGE */}
                                        <RoleCheck
                                            allowedRoles={[
                                                UserRoles.USER,
                                                UserRoles.ADMIN,
                                            ]}
                                            currentRole={user?.role}
                                        >
                                            {singleBid.data.totalVolume > 0 &&
                                                singleBid.data.status !==
                                                    BidStatus.REVIEW && (
                                                    <ButtonConfirmDefinition
                                                        reviewState={
                                                            reviewState
                                                        }
                                                        confirmAction={() =>
                                                            setOpenUserModal(
                                                                true
                                                            )
                                                        }
                                                        labels={{
                                                            wait: t(
                                                                'wait.message'
                                                            ),
                                                            action: t(
                                                                'bids.actions.reviewLots.send'
                                                            ),
                                                        }}
                                                        messages={{
                                                            success: t(
                                                                'bids.actions.reviewLots.success'
                                                            ),
                                                            error: t(
                                                                'errors.unknown'
                                                            ),
                                                        }}
                                                    />
                                                )}
                                        </RoleCheck>
                                        {/* END SHOW BRANCH USER SEND LOTS FOR REVIEW DEFINITION STAGE */}

                                        {/* 2.2. SHOW CONFIRM BUTTON TO NEXT STAGE [BID] */}
                                        <RoleCheck
                                            allowedRoles={[
                                                UserRoles.ADMIN,
                                                UserRoles.SHOPPING_LEADER,
                                            ]}
                                            currentRole={user?.role}
                                        >
                                            {/* RETURN TO DEFINITION */}

                                            {singleBid.data.status ===
                                                BidStatus.REVIEW && (
                                                <div className="my-4 w-full flex justify-end">
                                                    <Button
                                                        onClick={() =>
                                                            setOpenReturnDefinitionModal(
                                                                true
                                                            )
                                                        }
                                                        label={
                                                            reviewState ===
                                                            'loading'
                                                                ? t(
                                                                      'wait.message'
                                                                  )
                                                                : t(
                                                                      'bids.actions.returnButton'
                                                                  )
                                                            // action: t(
                                                            //     'bids.actions.reviewLots.send'
                                                            // ),
                                                        }
                                                        type="button"
                                                        variant="danger"
                                                    />
                                                </div>
                                            )}
                                            {singleBid.data.providerGroups
                                                ?.length > 0 &&
                                                singleBid.data.totalVolume >
                                                    0 &&
                                                singleBid.data.status ===
                                                    BidStatus.REVIEW && (
                                                    <div className="my-4 w-full flex justify-end">
                                                        <Button
                                                            disabled={
                                                                updateState ===
                                                                'loading'
                                                            }
                                                            type="button"
                                                            variant="success"
                                                            className="flex justify-center py-1 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                                            onClick={() =>
                                                                setOpenModal(
                                                                    true
                                                                )
                                                            }
                                                            label={
                                                                updateState ===
                                                                'loading'
                                                                    ? t(
                                                                          'wait.message'
                                                                      )
                                                                    : t(
                                                                          'bids.actions.confirmLots'
                                                                      )
                                                            }
                                                            icon={
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth={
                                                                            2
                                                                        }
                                                                        d="M5 13l4 4L19 7"
                                                                    />
                                                                </svg>
                                                            }
                                                        />
                                                    </div>
                                                )}
                                        </RoleCheck>
                                        {/* END 2.2. SHOW CONFIRM BUTTON TO NEXT STAGE [BID] */}
                                    </>
                                )}
                            <RoleCheck
                                allowedRoles={[
                                    UserRoles.ADMIN,
                                    UserRoles.SHOPPING_LEADER,
                                    UserRoles.USER,
                                ]}
                                currentRole={user?.role}
                            >
                                {/* 2.3 LIST BUY LOTS INTERNAL */}
                                {lotsByBranch.status === 'success' &&
                                    singleBid.data.buyLots && (
                                        <div>
                                            {singleBid.data.buyLots.map(
                                                (buyLot) => {
                                                    const lot =
                                                        lotsByBranch.data.find(
                                                            (l) =>
                                                                l.id ===
                                                                buyLot.lotId
                                                        );
                                                    if (lot && buyLot.id) {
                                                        return (
                                                            <BuyLotItem
                                                                key={buyLot.id}
                                                                className="odd:bg-slate-300 even:bg-slate-100"
                                                                lot={lot}
                                                                id={buyLot.id}
                                                                status={
                                                                    buyLot.status
                                                                }
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                }
                                            )}
                                        </div>
                                    )}
                                {/* END 2.3 LIST BUY LOTS INTERNAL */}
                            </RoleCheck>
                        </div>
                    )}
                {/* 2. END DEFINITION STAGE */}
                {/* ********************** */}

                {allOfferConfirmed && (
                    <RoleCheck
                        allowedRoles={[UserRoles.PROVIDER]}
                        currentRole={user?.role}
                    >
                        <h3 className="text-2xl font-bold text-center text-green-900 uppercase border-t border-green-900 border-b py-2 mb-2">
                            {t('bids.review.offerInReview')}
                        </h3>
                        <p className="text-xl text-center text-black">
                            {t('bids.review.youWillBeNotified')}
                        </p>
                    </RoleCheck>
                )}

                {/* 3. START BID STAGE */}
                {singleBid.data.buyLots &&
                    singleBid.data.buyLots.length > 0 &&
                    singleBid.data.currentStage === BidStages.BID && (
                        <div className="w-full px-10 mx-auto">
                            <RoleCheck
                                allowedRoles={[
                                    UserRoles.ADMIN,
                                    UserRoles.SHOPPING_LEADER,
                                    UserRoles.USER,
                                ]}
                                currentRole={user?.role}
                            >
                                {/* SHOW CONFIRMED LOTS MESSAGE */}

                                <div className="my-4 text-center">
                                    <p className="text-2xl font-bold text-center text-green-900 uppercase border-t border-green-900 border-b py-2 mb-2">
                                        {t('bids.actions.lotsConfirmed')}
                                    </p>
                                    <p className="text-xl">
                                        {t('bids.actions.lotsConfirmedMessage')}
                                    </p>
                                </div>
                            </RoleCheck>

                            {/* SHOW SEND OFFERS BUTTON */}
                            <RoleCheck
                                allowedRoles={[UserRoles.PROVIDER]}
                                currentRole={user?.role}
                            >
                                {singleBid.data.providerGroups?.length > 0 &&
                                    singleBid.data.totalVolume > 0 &&
                                    !allOfferConfirmed && (
                                        <div className="my-4 w-full flex justify-end">
                                            <Button
                                                variant="success"
                                                disabled={
                                                    updateState === 'loading'
                                                }
                                                type="button"
                                                className="flex justify-center py-1 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                                onClick={() =>
                                                    setOpenModal(true)
                                                }
                                                label={
                                                    updateState === 'loading'
                                                        ? t('wait.message')
                                                        : t(
                                                              'bids.actions.confirmAllOrders'
                                                          )
                                                }
                                                icon={
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M5 13l4 4L19 7"
                                                        />
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    )}
                            </RoleCheck>
                            {lotsByBranch.status === 'success' &&
                                singleBid.data?.buyLots &&
                                offerLots.length > 0 && (
                                    <RoleCheck
                                        allowedRoles={[UserRoles.PROVIDER]}
                                        currentRole={user?.role}
                                    >
                                        {/* END SEND OFFERS BUTTON */}
                                        {offerLots.map((offer, index) => (
                                            <BuyLotItemOffer
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`${offer.buyLot.id}-${index}`}
                                                className="odd:bg-slate-200 even:bg-slate-100"
                                                lot={offer.lot}
                                                id={offer.buyLot.id || '33'}
                                                status={
                                                    offer.buyLot.status ??
                                                    BuyLotStatus.PENDING
                                                }
                                                buyLot={offer.buyLot}
                                                cloned={offer.cloned}
                                            />
                                        ))}
                                    </RoleCheck>
                                )}
                        </div>
                    )}

                {singleBid.data.buyLots && singleBid.data.buyLots.length > 0 && (
                    <div className="w-full px-10 mx-auto">
                        <RoleCheck
                            allowedRoles={[
                                UserRoles.ADMIN,
                                UserRoles.SHOPPING_LEADER,
                            ]}
                            currentRole={user?.role}
                        >
                            <div className="my-4 w-full flex justify-end space-x-6">
                                {singleBid.data.currentStage ===
                                    BidStages.REVIEW ||
                                    (singleBid.data.currentStage ===
                                        BidStages.NEGOTIATION && (
                                        <Button
                                            disabled={updateState === 'loading'}
                                            type="button"
                                            variant="outline"
                                            onClick={() => {
                                                setOpenBackModal(true);
                                            }}
                                            icon={
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
                                                    />
                                                </svg>
                                            }
                                            label={
                                                singleBid.data.currentStage ===
                                                BidStages.NEGOTIATION
                                                    ? t(
                                                          'bids.actions.returnButtonPreQuali'
                                                      )
                                                    : t(
                                                          'bids.actions.returnButton'
                                                      )
                                            }
                                        />
                                    ))}
                                {singleBid.data.currentStage ===
                                    BidStages.REVIEW &&
                                    reviewCompleted && (
                                        <Button
                                            disabled={updateState === 'loading'}
                                            type="button"
                                            variant="success"
                                            className="flex justify-center py-1 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                            onClick={() => setOpenModal(true)}
                                            label={
                                                updateState === 'loading'
                                                    ? t('wait.message')
                                                    : t(
                                                          'bids.actions.reviewAllOrders'
                                                      )
                                            }
                                            icon={
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M5 13l4 4L19 7"
                                                    />
                                                </svg>
                                            }
                                        />
                                    )}
                            </div>
                        </RoleCheck>
                        {/* END SHOW CONFIRMED LOTS BUTTON */}

                        {/* SHOW REVIEW */}
                        {singleBid.data.currentStage === BidStages.REVIEW && (
                            <RoleCheck
                                allowedRoles={[
                                    UserRoles.ADMIN,
                                    UserRoles.SHOPPING_LEADER,
                                ]}
                                currentRole={user?.role}
                            >
                                <ReviewTable
                                    reviewOfferLots={reviewOfferLots}
                                    reload={reload}
                                />
                            </RoleCheck>
                        )}
                        {/* END REVIEW */}
                        {/* SHOW PRE-QUALIFICATION */}
                        {singleBid.data.currentStage ===
                            BidStages.PREQUALIFICATION && (
                            <RoleCheck
                                allowedRoles={[
                                    UserRoles.ADMIN,
                                    UserRoles.SHOPPING_LEADER,
                                    UserRoles.USER,
                                ]}
                                currentRole={user?.role}
                            >
                                <QualyTable
                                    reviewOfferLots={
                                        filtered
                                            ? filteredQualyOfferLots
                                            : qualyOfferLots
                                    }
                                    filter={filterOrders}
                                />
                            </RoleCheck>
                        )}
                        {/* END PRE-QUALIFICATION */}
                        {/* SHOW NEGOTIATION */}
                        {singleBid.data.currentStage ===
                            BidStages.NEGOTIATION && (
                            <>
                                <RoleCheck
                                    allowedRoles={[UserRoles.PROVIDER]}
                                    currentRole={user?.role}
                                >
                                    <NegotiationTable
                                        reviewOfferLots={
                                            filtered
                                                ? filteredQualyOfferLots
                                                : qualyOfferLots
                                        }
                                    />
                                </RoleCheck>
                                <RoleCheck
                                    allowedRoles={[
                                        UserRoles.ADMIN,
                                        UserRoles.SHOPPING_LEADER,
                                    ]}
                                    currentRole={user?.role}
                                >
                                    <NegotiationTableAdmin
                                        reviewOfferLots={
                                            filtered
                                                ? filteredQualyOfferLots
                                                : qualyOfferLots
                                        }
                                        filter={filterOrders}
                                    />
                                </RoleCheck>
                            </>
                        )}
                        {/* END NEGOTIATION */}
                        {/* SHOW CONTRACTS */}
                        {singleBid.data.currentStage ===
                            BidStages.CONTRACTS && (
                            <RoleCheck
                                allowedRoles={[
                                    UserRoles.ADMIN,
                                    UserRoles.SHOPPING_LEADER,
                                    UserRoles.USER,
                                    UserRoles.PROVIDER,
                                ]}
                                currentRole={user?.role}
                            >
                                <ContractTable
                                    reviewOfferLots={
                                        filtered
                                            ? filteredQualyOfferLots
                                            : qualyOfferLots
                                    }
                                    filter={filterOrders}
                                />
                            </RoleCheck>
                        )}
                        {/* END CONTRACTS */}

                        {/* SHOW NEGOTIATION */}
                        {(singleBid.data.currentStage === BidStages.RECEPTION ||
                            singleBid.data.currentStage ===
                                BidStages.CLOSED) && (
                            <>
                                <Review />
                                <ContractTable
                                    reviewOfferLots={
                                        filtered
                                            ? filteredQualyOfferLots
                                            : qualyOfferLots
                                    }
                                    filter={filterOrders}
                                />
                            </>
                        )}

                        <RoleCheck
                            allowedRoles={[UserRoles.PROVIDER]}
                            currentRole={user?.role}
                        >
                            {/* LIST BUY LOTS OFFER PROVIDER */}
                            {/* {lotsByBranch.status === 'success' &&
                            singleBid.data?.buyLots &&
                            singleBid.data.currentStage === BidStages.BID &&
                            singleBid.data.buyLots.map((buyLot) => {
                                const lot = lotsByBranch.data.find(
                                    (l) => l.id === buyLot.lotId
                                );
                                if (lot) {
                                    return (
                                        <BuyLotItemOffer
                                            key={buyLot.id}
                                            className="odd:bg-slate-200 even:bg-slate-100"
                                            lot={lot}
                                            id={buyLot.id || ''}
                                            status={buyLot.status}
                                        />
                                    );
                                }
                                return null;
                            })} */}
                            {/* {lotsByBranch.status === 'success' &&
                                singleBid.data?.buyLots &&
                                singleBid.data.currentStage === BidStages.BID &&
                                offerLots.map((offer, index) => (
                                    <BuyLotItemOffer
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${offer.buyLot.id}-${index}`}
                                        className="odd:bg-slate-200 even:bg-slate-100"
                                        lot={offer.lot}
                                        id={offer.buyLot.id || ''}
                                        status={offer.buyLot.status}
                                        buyLot={offer.buyLot}
                                        cloned={offer.cloned}
                                    />
                                ))} */}
                            {/* END LIST BUY LOTS */}
                        </RoleCheck>
                    </div>
                )}

                {/* MODAL TO CONFIRM LOTS */}
                {openModal && (
                    <Modal setShowModal={setOpenModal}>
                        <div className="px-4">
                            <h3 className="text-center text-2xl font-bold mb-10">
                                {t('bids.actions.areYouSure')}
                            </h3>
                            <div>
                                {singleBid.data?.currentStage ===
                                    BidStages.DEFINITION && (
                                    <p>{t('bids.actions.confirmDialog')}</p>
                                )}
                                {singleBid.data?.currentStage ===
                                    BidStages.REVIEW && (
                                    <p>
                                        {t('bids.actions.reviewConfirmDialog')}
                                    </p>
                                )}
                            </div>
                            <div className="mt-10 flex justify-end space-x-4">
                                <Button
                                    type="button"
                                    variant="link"
                                    label={t('common.cancel')}
                                    onClick={() => setOpenModal(false)}
                                />
                                <Button
                                    type="button"
                                    variant="success"
                                    label={t('common.confirm')}
                                    onClick={() => {
                                        setOpenModal(false);
                                        confirmBid();
                                    }}
                                />
                            </div>
                        </div>
                    </Modal>
                )}

                {/* MODAL TO CONFIRM LOTS USER */}
                {openUserModal && (
                    <Modal setShowModal={setOpenUserModal}>
                        <div className="px-4">
                            <h3 className="text-center text-2xl font-bold mb-10">
                                {t('bids.actions.areYouSure')}
                            </h3>
                            <div>
                                <p>{t('bids.actions.confirmDialog')}</p>
                            </div>
                            <div className="mt-10 flex justify-end space-x-4">
                                <Button
                                    type="button"
                                    variant="link"
                                    label={t('common.cancel')}
                                    onClick={() => setOpenUserModal(false)}
                                />
                                <Button
                                    type="button"
                                    variant="success"
                                    label={t('common.confirm')}
                                    onClick={() => {
                                        setOpenUserModal(false);
                                        sendLotsToReview(BidStatus.REVIEW);
                                    }}
                                />
                            </div>
                        </div>
                    </Modal>
                )}

                {/* MODAL TO RETURN DEFINITION */}
                {openReturnDefinitionModal && (
                    <Modal setShowModal={setOpenReturnDefinitionModal}>
                        <div className="px-4">
                            <h3 className="text-center text-2xl font-bold mb-10">
                                {t('bids.actions.areYouSure')}
                            </h3>
                            <div>
                                <p>{t('bids.actions.returnConfirmDialog')}</p>
                            </div>
                            <div className="mt-10 flex justify-end space-x-4">
                                <Button
                                    type="button"
                                    variant="link"
                                    label={t('common.cancel')}
                                    onClick={() =>
                                        setOpenReturnDefinitionModal(false)
                                    }
                                />
                                <Button
                                    type="button"
                                    variant="success"
                                    label={t('common.confirm')}
                                    onClick={() => {
                                        setOpenReturnDefinitionModal(false);
                                        goBacktoReview();
                                    }}
                                />
                            </div>
                        </div>
                    </Modal>
                )}

                {openBackModal && (
                    <Modal setShowModal={setOpenBackModal}>
                        <div className="px-4">
                            <h3 className="text-center text-2xl font-bold mb-10">
                                {t('bids.actions.areYouSure')}
                            </h3>
                            <div>
                                <p>
                                    ¿Estás seguro que quieres retroceder de
                                    etapa?
                                </p>
                            </div>
                            <div className="mt-10 flex justify-end space-x-4">
                                <Button
                                    type="button"
                                    variant="link"
                                    label={t('common.cancel')}
                                    onClick={() => setOpenBackModal(false)}
                                />
                                <Button
                                    type="button"
                                    variant="success"
                                    label={t('common.confirm')}
                                    onClick={() => {
                                        setOpenBackModal(false);
                                        confirmBid(true);
                                    }}
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
    if (singleBid.status === 'loading') {
        return <LoadingAnimated message={t('common.loading')} />;
    }

    if (singleBid.status === 'error') {
        return (
            <h3 className="text-center text-2xl font-bold mb-10">
                {t('common.errors.unknown')}
            </h3>
        );
    }
    return <LoadingAnimated message={t('common.loading')} />;
}
