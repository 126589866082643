export enum BidStages {
    NOT_STARTED = 'NOT_STARTED',
    DEFINITION = 'DEFINITION',
    BID = 'BID',
    PREQUALIFICATION = 'PREQUALIFICATION',
    REVIEW = 'REVIEW',
    NEGOTIATION = 'NEGOTIATION',
    CONTRACTS = 'CONTRACTS',
    RECEPTION = 'RECEPTION',
    DELETED = 'DELETED',
    CLOSED = 'CLOSED',
}
