import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ILot, ILotCreateAttr } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface LotsState {
    data: ILot[];
    status: Status;
    error: string | null;
}

const initialState: LotsState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchLots = createAsyncThunk('lots/fetchLots', async () => {
    const response = await APIClient.getData('/lots');
    return response.data;
});

export const fetchLotsByBranch = createAsyncThunk(
    'lots/fetchLotsByBranch',
    async (branchId: string) => {
        const response = await APIClient.getData(`/lots/branch/${branchId}`);

        return response.data;
    }
);

export const addNewLot = createAsyncThunk(
    'lots/addNewLot',
    async (lot: ILotCreateAttr) => {
        const response = await APIClient.postData('/lots', lot);
        return response.data;
    }
);

export const editLot = createAsyncThunk(
    'lots/editLot',
    async ({ id, lot }: { id: string; lot: ILotCreateAttr }) => {
        const response = await APIClient.putData(`/lots/${id}`, lot);
        return response.data;
    }
);

export const lotsSlice = createSlice({
    name: 'lots',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchLots.pending, (state) => {
                state.status = Status.loading;
                state.data = [];
            })
            .addCase(fetchLots.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchLots.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(fetchLotsByBranch.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchLotsByBranch.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchLotsByBranch.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(addNewLot.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data.push(action.payload);
            });
    },
});
