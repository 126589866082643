import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPort } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface IPortsState {
    data: IPort[];
    status: Status;
    error: string | null;
}

const initialState: IPortsState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPorts = createAsyncThunk('ports/fetchPorts', async () => {
    const response = await APIClient.getData('/ports');
    return response.data;
});

export const portsSlice = createSlice({
    name: 'ports',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPorts.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchPorts.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                state.data = action.payload;
            })
            .addCase(fetchPorts.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
