/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useMemo, useState } from 'react';
import { Column, useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../../../../../../store';
import { updateBuyPositions } from '../../../../../../modules/bids/features/buyPositionsSlice';

import { ILotPosition } from '../../../../../../modules/bids/interfaces';
import { Button } from '../../../../../../components/elements/Buttons';
import Modal from '../../../../../../components/Modal';
import { deleteBuyLot } from '../../../../../../modules/bids/features/buyLotsSlice';
import {
    fetchSingleBid,
    updateBidPositions,
} from '../../../../../../modules/bids/features/singleBidSlice';
import useAuth from '../../../../../../context/useAuth';
import { UserRoles } from '../../../../../../modules/users/enums';
import RoleCheck from '../../../../../../components/RoleCheck';
import { resetBidStatus } from '../../../../../../modules/bids/features/bidsSlice';
import { BidStatus } from '../../../../../../modules/bids/enums';

interface IValues {
    id?: string;
    lotPositionId: string;
    volume: number;
    buyLotId: string;
}

export default function BuyLotPositionList({
    lotPositions,
    type,
    volumes,
    buyLotId,
    confirmed,
}: {
    lotPositions: ILotPosition[] | [];
    type: string;

    volumes: { id: string; lotPositionId: string; value: string }[];
    buyLotId: string;
    confirmed: boolean;
}) {
    const { user } = useAuth();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const singleBid = useSelector((state: RootState) => state.singleBid);
    const lots = useSelector((state: RootState) => state.lots);

    const [newValues, setNewValues] = useState<IValues[]>([]);
    const [updatedValues, setUpdatedValues] = useState<IValues[]>([]);
    const [updateState, setUpdateState] = useState('idle');
    const [deleteState, setDeleteState] = useState('idle');
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (updateState === 'success') {
            setUpdatedValues([]);
            setNewValues([]);
        }
    }, [updateState]);

    const newVolumes = (
        id: string | undefined,
        lotPositionId: string,
        value: string
    ) => {
        if (parseInt(value, 10) < 0) {
            return;
        }

        const oldValue = volumes.findIndex(
            (v) => v.lotPositionId === lotPositionId
        );

        if (oldValue !== -1) {
            const existUp = updatedValues.findIndex(
                (v) => v.lotPositionId === lotPositionId
            );

            if (existUp !== -1) {
                updatedValues[existUp].volume = parseInt(value, 10);
            } else {
                updatedValues.push({
                    id,
                    lotPositionId,
                    volume: parseInt(value, 10),
                    buyLotId,
                });
            }
            setUpdatedValues([...updatedValues]);
            return;
        }

        const exist = newValues.findIndex(
            (v) => v.lotPositionId === lotPositionId
        );

        if (exist !== -1) {
            newValues[exist].volume = parseInt(value, 10);
        } else {
            newValues.push({
                lotPositionId,
                volume: parseInt(value, 10),
                buyLotId,
            });
        }
        setNewValues([...newValues]);
    };

    const updatePositions = async () => {
        try {
            setUpdateState('loading');
            await dispatch(
                updateBuyPositions({
                    newPositions: newValues,
                    updatedPositions: updatedValues,
                })
            ).unwrap();

            dispatch(
                updateBidPositions({
                    newPositions: newValues,
                    updatedPositions: updatedValues,
                })
            );

            setNewValues([]);
            setUpdatedValues([]);
            dispatch(resetBidStatus());
            setUpdateState('success');
            await dispatch(fetchSingleBid(singleBid?.data?.id ?? '')).unwrap();
        } catch (e) {
            setUpdateState('error');
        }
    };

    const deleteLot = async () => {
        if (singleBid.data && singleBid.data?.id) {
            try {
                setDeleteState('loading');
                await dispatch(deleteBuyLot(buyLotId)).unwrap();
                setDeleteState('success');
                await dispatch(fetchSingleBid(singleBid.data.id)).unwrap();
            } catch (e) {
                setDeleteState('error');
            }
        }
    };

    const columnsRolledSteel = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.ssDesignationCoating').toString(),
                accessor: 'ssDesignationCoating',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => {
                    const findPosition = volumes.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const value = newValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    if (value?.volume && value.volume < 0) value.volume = 0;

                    const updatedValue = updatedValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    if (confirmed) {
                        return (
                            value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                        );
                    }

                    return singleBid?.data?.status === BidStatus.REVIEW ? (
                        value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                    ) : (
                        <input
                            name={`volume[${cell.row.original.id}]`}
                            type="number"
                            value={
                                value?.volume ??
                                updatedValue?.volume ??
                                findPosition?.value ??
                                0
                            }
                            onChange={(e) => {
                                newVolumes(
                                    value?.id ??
                                        updatedValue?.id ??
                                        findPosition?.id ??
                                        undefined,
                                    cell.row.original.id,
                                    e.target.value
                                );
                            }}
                            onFocus={(e) => {
                                if (e.target.value === '0') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        ''
                                    );
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value === '') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        '0'
                                    );
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: t('lots.unit').toString(),
                accessor: 'lotId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => {
                    const lot = lots.data.find((l) => l.id === value);
                    return lot?.metric?.nameEn || '-';
                },
                disableSortBy: true,
            },
        ],

        [t, volumes]
    );
    const columnsPaintedRolledSteel = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.color').toString(),
                accessor: 'colorId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) =>
                    cell.row.original?.lotColor?.code ?? '--',
            },
            {
                Header: t('lots.position.backSide').toString(),
                accessor: 'backSide',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) =>
                    cell.row.original?.backSideColor?.code ?? '--',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => {
                    const findPosition = volumes.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const value = newValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const updatedValue = updatedValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    if (confirmed) {
                        return (
                            value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                        );
                    }

                    return singleBid?.data?.status === BidStatus.REVIEW ? (
                        value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                    ) : (
                        <input
                            name={`volume[${cell.row.original.id}]`}
                            type="number"
                            value={
                                value?.volume ??
                                updatedValue?.volume ??
                                findPosition?.value ??
                                0
                            }
                            onChange={(e) => {
                                newVolumes(
                                    value?.id ??
                                        updatedValue?.id ??
                                        findPosition?.id ??
                                        undefined,
                                    cell.row.original.id,
                                    e.target.value
                                );
                            }}
                            onFocus={(e) => {
                                if (e.target.value === '0') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        ''
                                    );
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value === '') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        '0'
                                    );
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: t('lots.unit').toString(),
                accessor: 'lotId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => {
                    const lot = lots.data.find((l) => l.id === value);
                    return lot?.metric?.nameEn || '-';
                },
                disableSortBy: true,
            },
        ],

        [t, volumes]
    );

    const columnsPlates = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => {
                    const findPosition = volumes.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const value = newValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const updatedValue = updatedValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    if (confirmed) {
                        return (
                            value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                        );
                    }

                    return singleBid?.data?.status === BidStatus.REVIEW ? (
                        value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                    ) : (
                        <input
                            name={`volume[${cell.row.original.id}]`}
                            type="number"
                            value={
                                value?.volume ??
                                updatedValue?.volume ??
                                findPosition?.value ??
                                0
                            }
                            onChange={(e) => {
                                newVolumes(
                                    value?.id ??
                                        updatedValue?.id ??
                                        findPosition?.id ??
                                        undefined,
                                    cell.row.original.id,
                                    e.target.value
                                );
                            }}
                            onFocus={(e) => {
                                if (e.target.value === '0') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        ''
                                    );
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value === '') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        '0'
                                    );
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: t('lots.unit').toString(),
                accessor: 'lotId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => {
                    const lot = lots.data.find((l) => l.id === value);
                    return lot?.metric?.nameEn || '-';
                },
                disableSortBy: true,
            },
        ],

        [t, volumes]
    );

    const columnsPipe = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.diameter').toString(),
                accessor: 'diameter',
            },
            {
                Header: t('lots.position.diameterUnit').toString(),
                accessor: 'diameterUnit',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => {
                    const findPosition = volumes.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const value = newValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const updatedValue = updatedValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    if (confirmed) {
                        return (
                            value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                        );
                    }

                    return singleBid?.data?.status === BidStatus.REVIEW ? (
                        value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                    ) : (
                        <input
                            name={`volume[${cell.row.original.id}]`}
                            type="number"
                            value={
                                value?.volume ??
                                updatedValue?.volume ??
                                findPosition?.value ??
                                0
                            }
                            onChange={(e) => {
                                newVolumes(
                                    value?.id ??
                                        updatedValue?.id ??
                                        findPosition?.id ??
                                        undefined,
                                    cell.row.original.id,
                                    e.target.value
                                );
                            }}
                            onFocus={(e) => {
                                if (e.target.value === '0') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        ''
                                    );
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value === '') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        '0'
                                    );
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: t('lots.unit').toString(),
                accessor: 'lotId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => {
                    const lot = lots.data.find((l) => l.id === value);
                    return lot?.metric?.nameEn || '-';
                },
                disableSortBy: true,
            },
        ],

        [t, volumes]
    );

    const columnsBarAngle = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.height').toString(),
                accessor: 'height',
            },
            {
                Header: t('lots.position.diameter').toString(),
                accessor: 'diameter',
            },
            {
                Header: t('lots.position.diameterUnit').toString(),
                accessor: 'diameterUnit',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => {
                    const findPosition = volumes.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const value = newValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const updatedValue = updatedValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    if (confirmed) {
                        return (
                            value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                        );
                    }

                    return singleBid?.data?.status === BidStatus.REVIEW ? (
                        value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                    ) : (
                        <input
                            name={`volume[${cell.row.original.id}]`}
                            type="number"
                            value={
                                value?.volume ??
                                updatedValue?.volume ??
                                findPosition?.value ??
                                0
                            }
                            onChange={(e) => {
                                newVolumes(
                                    value?.id ??
                                        updatedValue?.id ??
                                        findPosition?.id ??
                                        undefined,
                                    cell.row.original.id,
                                    e.target.value
                                );
                            }}
                            onFocus={(e) => {
                                if (e.target.value === '0') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        ''
                                    );
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value === '') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        '0'
                                    );
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: t('lots.unit').toString(),
                accessor: 'lotId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => {
                    const lot = lots.data.find((l) => l.id === value);
                    return lot?.metric?.nameEn || '-';
                },
                disableSortBy: true,
            },
        ],

        [t, volumes]
    );

    const columnsTube = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.height').toString(),
                accessor: 'height',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => {
                    const findPosition = volumes.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const value = newValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    const updatedValue = updatedValues.find(
                        (v) => v.lotPositionId === cell.row.original.id
                    );

                    if (confirmed) {
                        return (
                            value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                        );
                    }

                    return singleBid?.data?.status === BidStatus.REVIEW ? (
                        value?.volume ??
                            updatedValue?.volume ??
                            findPosition?.value ??
                            0
                    ) : (
                        <input
                            name={`volume[${cell.row.original.id}]`}
                            type="number"
                            value={
                                value?.volume ??
                                updatedValue?.volume ??
                                findPosition?.value ??
                                0
                            }
                            onChange={(e) => {
                                newVolumes(
                                    value?.id ??
                                        updatedValue?.id ??
                                        findPosition?.id ??
                                        undefined,
                                    cell.row.original.id,
                                    e.target.value
                                );
                            }}
                            onFocus={(e) => {
                                if (e.target.value === '0') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        ''
                                    );
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value === '') {
                                    newVolumes(
                                        value?.id ??
                                            updatedValue?.id ??
                                            findPosition?.id ??
                                            undefined,
                                        cell.row.original.id,
                                        '0'
                                    );
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: t('lots.unit').toString(),
                accessor: 'lotId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => {
                    const lot = lots.data.find((l) => l.id === value);
                    return lot?.metric?.nameEn || '-';
                },
                disableSortBy: true,
            },
        ],

        [t, volumes]
    );

    const getColumns = (lotType: string): Column<ILotPosition>[] => {
        switch (lotType) {
            case 'Rolled Steel':
                return columnsRolledSteel;
            case 'Pipe':
                return columnsPipe;
            case 'Bar/Angle':
                return columnsBarAngle;
            case 'Tube':
                return columnsTube;
            case 'Plates':
                return columnsPlates;
            case 'Painted Rolled Steel':
                return columnsPaintedRolledSteel;
            default:
                return columnsRolledSteel;
        }
    };

    const tableInstance = useTable(
        {
            columns: getColumns(type),
            data: lotPositions,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative">
            {/* {status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )} */}
            {lotPositions.length > 0 && (
                <div className="overflow-x-auto border-b border-slate-500">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-slate-200 hover:bg-slate-300"
                                            {...row.getRowProps()}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2"
                                                        // eslint-disable-next-line react/prop-types
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            // eslint-disable-next-line react/prop-types
                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
            <div className="mt-4 text-right">
                {updateState === 'error' ||
                    (deleteState === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('common.errors.unknown')}
                        </p>
                    ))}
                {updateState === 'success' && (
                    <p className="text-green-900 mb-2  font-bold">
                        {t('bids.positions.updated')}
                    </p>
                )}
            </div>
            {!confirmed && singleBid?.data?.status === BidStatus.PENDING && (
                <RoleCheck
                    allowedRoles={[
                        UserRoles.USER,
                        UserRoles.ADMIN,
                        UserRoles.SHOPPING_LEADER,
                    ]}
                    currentRole={user?.role || 'PROVIDER'}
                >
                    <div className="w-full flex justify-end mt-10 space-x-4">
                        <Button
                            disabled={
                                updateState === 'loading' ||
                                deleteState === 'loading'
                            }
                            variant="link"
                            label={
                                deleteState === 'loading'
                                    ? t('wait.message')
                                    : t('common.delete')
                            }
                            onClick={() => setOpenModal(true)}
                        />
                        <div className="w-1/3">
                            <Button
                                disabled={
                                    updateState === 'loading' ||
                                    deleteState === 'loading' ||
                                    (updatedValues.length === 0 &&
                                        newValues.length === 0)
                                }
                                type="button"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                onClick={updatePositions}
                                label={
                                    updateState === 'loading'
                                        ? t('wait.message')
                                        : t('common.update')
                                }
                            />
                        </div>
                        {openModal && (
                            <Modal setShowModal={setOpenModal}>
                                <div className="px-4">
                                    <h3 className="text-center text-2xl font-bold mb-10">
                                        {t('bids.actions.areYouSure')}
                                    </h3>
                                    <div>
                                        <p>{t('bids.actions.deleteBuyLot')}</p>
                                    </div>
                                    <div className="mt-10 flex justify-end space-x-4">
                                        <Button
                                            type="button"
                                            variant="link"
                                            label={t('common.cancel')}
                                            onClick={() => setOpenModal(false)}
                                        />
                                        <Button
                                            type="button"
                                            variant="success"
                                            label={t('common.confirm')}
                                            onClick={() => {
                                                deleteLot();
                                                setOpenModal(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        )}
                    </div>
                </RoleCheck>
            )}
        </div>
    );
}
