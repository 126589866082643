import Header from '../../Header';
import { IActionButtonProps } from '../../Header/ActionButton';
import { IActionLinkProps } from '../../Header/ActionLink';
import { SideBar } from '../../Navigation';
import { IItems } from '../../Navigation/SideBar/Items';

import ErrorBoundary from '../../ErrorBoundary';
import Development from '../../Development';
import { User } from '../../../context/interfaces';

interface ILayout {
    children: React.ReactNode;
    navigation: (path: string) => void;
    sideBarMenu?: IItems[];
    title?: string;
    headerItems?: (IActionLinkProps | IActionButtonProps)[];
    headerMobileItems?: (IActionLinkProps | IActionButtonProps)[];
    currentPath: string | undefined;
    routeParams: string | undefined;
    showDevelopmentTools?: boolean;
    logo?: string;
    user: User | null | undefined;
}

function Layout({
    children,
    currentPath,
    headerItems,
    headerMobileItems,
    sideBarMenu,
    navigation,
    title = 'SymLab',
    routeParams = '',
    showDevelopmentTools,
    logo,
    user,
}: ILayout) {
    const goTo = (path: string) => {
        navigation(path);
    };

    return (
        <div className="relative flex-none md:flex h-screen antialiased bg-sym-background py-2 px-4">
            <aside className="flex-shrink-0 hidden w-64 md:block">
                {/* SideBar */}
                <ErrorBoundary>
                    <SideBar
                        goTo={goTo}
                        items={sideBarMenu}
                        currentPath={currentPath}
                        user={user}
                    />
                </ErrorBoundary>
            </aside>
            <div className="relative md:flex-1 h-auto md:h-full md:overflow-hidden">
                {/* Start Header */}
                <ErrorBoundary>
                    <Header
                        title={title}
                        menuItems={headerItems}
                        menuItemsPhone={headerMobileItems}
                        logo={logo}
                    />{' '}
                </ErrorBoundary>
                {/* End Header */}
                {/* Start Main */}
                <main className="relative mt-24 md:mt-0 flex-1 h-full p-5 md:overflow-x-hidden md:overflow-y-auto bg-white rounded-br-lg">
                    <ErrorBoundary>{children}</ErrorBoundary>
                </main>
                {/* End Main */}
            </div>
            {process.env.NODE_ENV === 'development' && showDevelopmentTools && (
                <Development pathname={currentPath} routeParams={routeParams} />
            )}
        </div>
    );
}

Layout.defaultProps = {
    title: 'SymLab',
    headerItems: [],
    headerMobileItems: [],
    sideBarMenu: [],
    showDevelopmentTools: true,
    logo: null,
};

export default Layout;
