/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column, useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../store';
import { fetchBids } from '../../../modules/bids/features/bidsSlice';
import { fetchPorts } from '../../../modules/ports/features';
import Create from './components/Create';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import { IBid } from '../../../modules/bids/interfaces';
import LoadingAnimated from '../../../components/elements/Loading';
import { BidStages } from '../../../modules/bids/enums';
import RoleCheck from '../../../components/RoleCheck';
import { UserRoles } from '../../../modules/users/enums';
import useAuth from '../../../context/useAuth';
import { dateOptions } from '../../../utils/helpers';

function Bagde({
    stage,
    userRol = UserRoles.USER,
}: {
    stage: string;
    userRol?: UserRoles;
}) {
    const { t } = useTranslation();
    const color = useMemo(() => {
        switch (stage) {
            case BidStages.NOT_STARTED:
                return 'bg-gray-500 text-white';
            case BidStages.DEFINITION:
                return 'bg-orange-100 text-black';
            case BidStages.BID:
                return 'bg-orange-200 text-black';
            case BidStages.REVIEW:
                return 'bg-orange-300 text-black';
            case BidStages.PREQUALIFICATION:
                return 'bg-orange-400 text-black';
            case BidStages.NEGOTIATION:
                return 'bg-orange-500 text-white';
            case BidStages.CONTRACTS:
                return 'bg-orange-600 text-white';
            case BidStages.RECEPTION:
                return 'bg-orange-700 text-white';
            case BidStages.DELETED:
                return 'bg-red-500 text-white';
            case BidStages.CLOSED:
                return 'bg-green-300 text-black';
            default:
                return 'bg-gray-500 text-white';
        }
    }, [stage]);

    const label = useMemo(() => {
        switch (stage) {
            case BidStages.NOT_STARTED:
                return t('bids.stages.notStarted');
            case BidStages.DEFINITION:
                return t('bids.stages.definition');
            case BidStages.BID:
                return t('bids.stages.bid');
            case BidStages.PREQUALIFICATION:
                return userRol === UserRoles.PROVIDER
                    ? t('bids.stages.review')
                    : t('bids.stages.prequalification');
            case BidStages.REVIEW:
                return t('bids.stages.review');
            case BidStages.NEGOTIATION:
                return t('bids.stages.negotiation');
            case BidStages.CONTRACTS:
                return t('bids.stages.contracts');
            case BidStages.RECEPTION:
                return t('bids.stages.reception');
            case BidStages.DELETED:
                return t('bids.stages.deleted');
            case BidStages.CLOSED:
                return t('bids.stages.closed');
            default:
                return t('common.unknown');
        }
    }, [stage, t, userRol]);

    return (
        <div
            className={`text-center text-sm w-36 px-2 py-1 rounded-full mx-auto ${color}`}
        >
            {label}
        </div>
    );
}

Bagde.defaultProps = {
    userRol: UserRoles.USER,
};

export default function HomeTable() {
    const { user } = useAuth();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const bids = useSelector((state: RootState) => state.bids);
    const ports = useSelector((state: RootState) => state.ports);
    const navigate = useNavigate();
    const [showCreate, setShowCreate] = useState(false);

    let locale = 'en-US';
    if (i18n.language === 'es') {
        locale = 'es-ES';
    }

    useEffect(() => {
        if (bids.status === 'idle' || (bids.status === 'error' && user?.role)) {
            dispatch(fetchBids());
        }
    }, [bids.status, dispatch, user]);

    useEffect(() => {
        if (ports.status === 'idle' && user?.role) {
            dispatch(fetchPorts());
        }
    }, [ports.status, dispatch, user]);

    const columns = useMemo<Column<IBid>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'identifier',
            },
            {
                Header: t('bids.title').toString(),
                accessor: 'name',
            },
            {
                Header: t('common.branch').toString(),
                accessor: 'branch',
                Cell: ({ cell: { value } }) => value?.name ?? '',
            },
            {
                Header: t('common.stage').toString(),
                accessor: 'currentStage',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => (
                    <Bagde stage={value} userRol={user?.role} />
                ),
            },
            {
                Header: t('lots.lotVolume').toString(),
                accessor: 'totalVolume',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => (
                    <span className="block text-right">{value}</span>
                ),
            },
            {
                Header: t('lots.title').toString(),
                accessor: 'totalLots',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => (
                    <span className="block text-right">{value}</span>
                ),
            },
            {
                Header: t('common.stageStartDate').toString(),
                id: 'startDateStage',
                // eslint-disable-next-line react/prop-types
                Cell: (data: any) => {
                    const { row } = data;
                    const { original } = row;

                    const result = original?.stages?.filter(
                        (s: any) => s.stage === original.currentStage
                    )[0];

                    if (result) {
                        return (
                            <span className="block text-right">
                                {new Date(result.startDate).toLocaleDateString(
                                    locale,
                                    dateOptions
                                )}
                            </span>
                        );
                    }

                    return '-';
                },
            },
            {
                Header: t('common.stageEndDate').toString(),
                id: 'endDateStage',
                // eslint-disable-next-line react/prop-types
                Cell: (data: any) => {
                    const { row } = data;
                    const { original } = row;

                    const result = original?.stages?.filter(
                        (s: any) => s.stage === original.currentStage
                    )[0];

                    if (result && result.endDate) {
                        return (
                            <span className="block text-right">
                                {new Date(result.endDate).toLocaleDateString(
                                    locale,
                                    dateOptions
                                )}
                            </span>
                        );
                    }

                    return '-';
                },
            },
            {
                Header: t('common.start').toString(),
                accessor: 'startDate',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => (
                    <span className="block text-right">
                        {new Date(value).toLocaleDateString(
                            locale,
                            dateOptions
                        )}
                    </span>
                ),
            },
            {
                Header: t('common.end').toString(),
                accessor: 'endDate',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => {
                    if (value) {
                        return (
                            <span className="block text-right">
                                {new Date(value).toLocaleDateString(
                                    locale,
                                    dateOptions
                                )}
                            </span>
                        );
                    }
                    return <span className="block text-center">--</span>;
                },
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t, locale]
    );

    const tableInstance = useTable(
        {
            columns,
            data: bids.data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative mb-20">
            <RoleCheck
                allowedRoles={[UserRoles.ADMIN, UserRoles.SHOPPING_LEADER]}
                currentRole={user?.role || 'USER'}
            >
                <Create show={showCreate} toggle={() => setShowCreate(false)} />
                <Button
                    className="absolute right-0 top-0 z-10"
                    label={t('bids.create.buttonTitle')}
                    type="button"
                    onClick={() => setShowCreate(!showCreate)}
                />
            </RoleCheck>
            <PageHeader title={t('bids.title')} />

            {bids.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {bids.status === 'success' && bids.data.length === 0 && (
                <div className="text-center text-gray-500">
                    <h2 className="text-2xl">{t('common.listEmpty')}</h2>
                    <RoleCheck
                        allowedRoles={[
                            UserRoles.ADMIN,
                            UserRoles.SHOPPING_LEADER,
                        ]}
                        currentRole={user?.role || 'USER'}
                    >
                        <Button
                            className="mt-6"
                            label={t('bids.create.buttonTitle')}
                            type="button"
                            onClick={() => setShowCreate(!showCreate)}
                        />
                    </RoleCheck>
                </div>
            )}

            {bids.status === 'success' && bids.data.length > 0 && (
                <div className="overflow-x-auto shadow-md">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-slate-200 hover:bg-slate-300"
                                            {...row.getRowProps()}
                                            onClick={() => {
                                                navigate(
                                                    `/bids/${row.original.id}`
                                                );
                                            }}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2 cursor-pointer"
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
