/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState } from 'react';
import { t } from 'i18next';
import draftToHtml from 'draftjs-to-html';
import { useSelector } from 'react-redux';

import { ILot, ILotPosition } from '../../../../../../modules/bids/interfaces';

import BuyLotPositionList from './BuyLotPositionList';
import { BidStages, BuyLotStatus } from '../../../../../../modules/bids/enums';
import { RootState } from '../../../../../../store';

export default function BuyLotItem({
    lot,
    className,
    id,
    status,
}: {
    lot: ILot;
    className?: string;
    id: string;
    status: BuyLotStatus;
}) {
    const [showDetails, setShowDetails] = useState(false);

    const [newPositions, setNewPositions] = useState<ILotPosition[]>();
    const buyPositions = useSelector((state: RootState) => {
        if (state.singleBid.data && state.singleBid.data.buyLots) {
            return state.singleBid.data.buyLots.find((l) => l.id === id)
                ?.positions;
        }
        return [];
    });

    const singleBidStage = useSelector(
        (state: RootState) => state.singleBid?.data?.currentStage
    );

    const [volumes, setVolumes] = useState<
        { id: string; lotPositionId: string; value: string }[]
    >([]);

    useEffect(() => {
        if (buyPositions && buyPositions.length > 0) {
            const lotVolumes = buyPositions.map((p) => ({
                id: p.id ?? '',
                lotPositionId: p.lotPositionId,
                value: p.volume?.toString() ?? '',
            }));

            if (status === BuyLotStatus.CONFIRMED) {
                // SHOW ONLY ACTIVE POSITIONS
                const activeLotsPositions = buyPositions
                    // eslint-disable-next-line array-callback-return
                    .map((bp) => {
                        const getPositon = lot.positions?.find(
                            (lp) => bp.lotPositionId === lp.id
                        );

                        if (getPositon) {
                            return getPositon;
                        }
                    })
                    .filter((p) => p !== undefined) as ILotPosition[];

                setNewPositions(activeLotsPositions);
            }

            setVolumes(lotVolumes);
        }
    }, [buyPositions, lot, status]);

    const generalSpec = () => {
        if (lot.generalSpecsRaw) {
            return { __html: draftToHtml(JSON.parse(lot.generalSpecsRaw)) };
        }
        if (lot.generalSpecsPlain) {
            return { __html: lot.generalSpecsPlain };
        }
        return { __html: '' };
    };

    return (
        <div
            className={`mt-4  last:mb-20 px-10 py-4 text-slate-800 ${className}`}
        >
            <header className="flex rounded-md py-2 items-strech bg-slate-700 text-white text-left h-full">
                <div className="w-2/12 border-r border-slate-300 px-4">
                    <p>{t('common.code')}</p>
                    <p>
                        <strong>{lot.code}</strong>
                    </p>
                </div>
                <div className="w-4/12 border-slate-300 px-4">
                    <p>{t('common.description')}</p>
                    <p>
                        <strong>{lot.descriptionEn}</strong>
                    </p>
                </div>

                <div className="w-2/12 border-l border-r border-slate-300 px-4">
                    <p>{t('lots.lotType')}</p>
                    <p>
                        <strong>{lot.type?.name}</strong>
                    </p>
                </div>
                <div className="w-2/12 px-4 border-r border-slate-300">
                    <p>{t('lots.lotMetric')}</p>
                    <p>
                        <strong>{lot.metric?.nameEn}</strong>
                    </p>
                </div>
                <div className="w-2/12 px-4">
                    <p>{t('lots.lotVolume')}</p>
                    <p>
                        <strong>
                            {volumes.reduce(
                                (acc, object) => acc + Number(object.value),
                                0
                            )}
                        </strong>
                    </p>
                </div>
            </header>
            {status === BuyLotStatus.CONFIRMED &&
                singleBidStage === BidStages.DEFINITION && (
                    <div className="my-4 text-center">
                        <span className="bg-green-900 text-white p-2">
                            Lote confirmado
                        </span>
                    </div>
                )}

            <button
                type="button"
                onClick={() => setShowDetails(!showDetails)}
                className="mt-4 mb-2 text-center w-full bg-slate-50 flex items-center justify-center"
            >
                {showDetails ? (
                    <>
                        <span className="mr-2">{t('common.hideDetails')}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 11l7-7 7 7M5 19l7-7 7 7"
                            />
                        </svg>
                    </>
                ) : (
                    <>
                        <span className="mr-2">{t('common.showDetails')}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                            />
                        </svg>
                    </>
                )}
            </button>

            {showDetails && (
                <div className="mb-2">
                    {generalSpec() && (
                        <div className="mt-4 w-full bg-slate-100 p-4 rounded-md">
                            <h3 className="font-bold py-2">
                                {t('lots.generalSpecifications')}
                            </h3>
                            <hr className="border border-slate-500 mb-2" />
                            {/* <div dangerouslySetInnerHTML={generalSpec()} /> */}
                            <div>{lot.generalSpecsPlain}</div>
                        </div>
                    )}
                </div>
            )}

            {showDetails && lot.positions && lot.positions?.length > 0 && (
                <div className="mt-10">
                    <BuyLotPositionList
                        buyLotId={id}
                        lotPositions={newPositions ?? lot.positions ?? []}
                        type={lot.type.name}
                        volumes={volumes}
                        confirmed={status === BuyLotStatus.CONFIRMED}
                    />
                </div>
            )}
        </div>
    );
}

BuyLotItem.defaultProps = {
    className: '',
};
