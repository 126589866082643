/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useMemo, useState } from 'react';

import { Column, useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';

import { ILotPosition } from '../../../../modules/bids/interfaces';

import { useAppDispatch } from '../../../../store';
import {
    deleteLotPosition,
    removeLotPosition,
} from '../../../../modules/bids/features/lotPositionsSlice';

export default function LotPositionList({
    lotPositions,
    type,
}: {
    lotPositions: ILotPosition[] | [];
    type: string;
}) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [deleteState, setDeleteState] = useState('idle');

    const deletePosition = async (id: string) => {
        try {
            setDeleteState('loading');
            await dispatch(deleteLotPosition(id)).unwrap();
            dispatch(removeLotPosition(id));
            setDeleteState('idle');
        } catch (err) {
            setDeleteState('error');
        }
    };

    const columnsRolledSteel = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.ssDesignationCoating').toString(),
                accessor: 'ssDesignationCoating',
            },
            {
                Header: t('common.delete').toString(),
                accessor: 'id',
                Cell: ({ value }) => (
                    <div className="w-full text-center">
                        <button
                            disabled={deleteState === 'loading'}
                            type="button"
                            onClick={() => deletePosition(value as string)}
                        >
                            {deleteState === 'loading' ? (
                                t('wait.message')
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                ),
            },
        ],

        [t]
    );
    const columnsPaintedRolledSteel = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.color').toString(),
                accessor: 'colorId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) =>
                    cell.row.original?.lotColor?.code ?? '',
            },
            {
                Header: t('lots.position.backSide').toString(),
                accessor: 'backSide',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) =>
                    cell.row.original?.backSideColor?.code ?? '',
            },
            {
                Header: t('common.delete').toString(),
                accessor: 'id',
                Cell: ({ value }) => (
                    <div className="w-full text-center">
                        <button
                            disabled={deleteState === 'loading'}
                            type="button"
                            onClick={() => deletePosition(value as string)}
                        >
                            {deleteState === 'loading' ? (
                                t('wait.message')
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                ),
            },
        ],

        [t]
    );

    const columnsPlates = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('common.delete').toString(),
                accessor: 'id',
                Cell: ({ value }) => (
                    <div className="w-full text-center">
                        <button
                            disabled={deleteState === 'loading'}
                            type="button"
                            onClick={() => deletePosition(value as string)}
                        >
                            {deleteState === 'loading' ? (
                                t('wait.message')
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                ),
            },
        ],

        [t]
    );

    const columnsPipe = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.diameter').toString(),
                accessor: 'diameter',
            },
            {
                Header: t('lots.position.diameterUnit').toString(),
                accessor: 'diameterUnit',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('common.delete').toString(),
                accessor: 'id',
                Cell: ({ value }) => (
                    <div className="w-full text-center">
                        <button
                            disabled={deleteState === 'loading'}
                            type="button"
                            onClick={() => deletePosition(value as string)}
                        >
                            {deleteState === 'loading' ? (
                                t('wait.message')
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                ),
            },
        ],

        [t]
    );

    const columnsBarAngle = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.height').toString(),
                accessor: 'height',
            },
            {
                Header: t('lots.position.diameter').toString(),
                accessor: 'diameter',
            },
            {
                Header: t('lots.position.diameterUnit').toString(),
                accessor: 'diameterUnit',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('common.delete').toString(),
                accessor: 'id',
                Cell: ({ value }) => (
                    <div className="w-full text-center">
                        <button
                            disabled={deleteState === 'loading'}
                            type="button"
                            onClick={() => deletePosition(value as string)}
                        >
                            {deleteState === 'loading' ? (
                                t('wait.message')
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                ),
            },
        ],

        [t]
    );

    const columnsTube = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.height').toString(),
                accessor: 'height',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('common.delete').toString(),
                accessor: 'id',
                Cell: ({ value }) => (
                    <div className="w-full text-center">
                        <button
                            disabled={deleteState === 'loading'}
                            type="button"
                            onClick={() => deletePosition(value as string)}
                        >
                            {deleteState === 'loading' ? (
                                t('wait.message')
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                ),
            },
        ],

        [t]
    );

    const getColumns = (lotType: string): Column<ILotPosition>[] => {
        switch (lotType) {
            case 'Rolled Steel':
                return columnsRolledSteel;
            case 'Pipe':
                return columnsPipe;
            case 'Bar/Angle':
                return columnsBarAngle;
            case 'Tube':
                return columnsTube;
            case 'Plates':
                return columnsPlates;
            case 'Painted Rolled Steel':
                return columnsPaintedRolledSteel;
            default:
                return columnsRolledSteel;
        }
    };

    const tableInstance = useTable(
        {
            columns: getColumns(type),
            data: lotPositions,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative">
            {/* {status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )} */}
            {lotPositions.length > 0 && (
                <div className="overflow-x-auto border-b border-slate-500">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-slate-200 hover:bg-slate-300"
                                            {...row.getRowProps()}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2"
                                                        // eslint-disable-next-line react/prop-types
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            // eslint-disable-next-line react/prop-types
                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
