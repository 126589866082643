/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, useTable, useSortBy } from 'react-table';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { IQualyOffer } from './interfaces';
import { Button } from '../../../../../components/elements/Buttons';

import { IBuyLot, ILot } from '../../../../../modules/bids/interfaces';
import { RootState, useAppDispatch } from '../../../../../store';

import QualifyOfferLotItem from '../QualifyOfferLotItem';
import Modal from '../../../../../components/Modal';
import {
    fetchSingleBid,
    updateBidStage,
} from '../../../../../modules/bids/features/singleBidSlice';
import RoleCheck from '../../../../../components/RoleCheck';
import useAuth from '../../../../../context/useAuth';
import { UserRoles } from '../../../../../modules/users/enums';
import { resetBidStatus } from '../../../../../modules/bids/features/bidsSlice';

import PriceBlock from './PriceBlock';
import Filter from './Filter';

interface ISelectedOffer {
    lot: ILot;
    buyLot: IBuyLot;
}

type FormData = {
    interestRate: number;
    selected: string[];
};

const equivalentPrice = (
    offeredPrice: number,
    intRate: number,
    days: number
) => {
    if (intRate === 0) {
        return offeredPrice;
    }

    return offeredPrice / (1 + ((intRate / 100) * days) / 360);
};

function getButtonText(length: number) {
    if (length > 0) {
        return 'Guardar y enviar a proveedores seleccionados';
    }
    return 'Guardar y enviar a todos los proveedores';
}

export default function QualyTable({
    reviewOfferLots,
    filter,
}: {
    reviewOfferLots: IQualyOffer[];
    filter: (family: string, provider: string, paymentTerm?: string) => void;
}) {
    const { user } = useAuth();
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [confirmOfferState, setConfirmOfferState] = useState('idle');

    const singleBid = useSelector((state: RootState) => state.singleBid);

    const { register, watch } = useForm<FormData>({
        defaultValues: {
            interestRate: 3,
            selected: [],
        },
    });

    const selectedOffers = watch('selected');

    useEffect(() => {
        register('selected');
    }, [register]);

    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [selectedOffer, setSelectedOffer] = useState<ISelectedOffer | null>(
        null
    );

    const intRate = watch('interestRate');

    let locale = 'en-US';
    if (i18n.language === 'es') {
        locale = 'es-ES';
    }

    // const updateSelected = (id: string) => {
    //     const exist = selectedOffers.findIndex((s) => s === id);
    //     if (exist !== -1) {
    //         selectedOffers.splice(exist, 1);
    //     } else {
    //         selectedOffers.push(id);
    //     }
    //     setValue('selected', selectedOffers);
    // };

    const nextStage = async () => {
        if (!singleBid.data || !singleBid.data.id) {
            return;
        }
        try {
            setConfirmOfferState('loading');
            await dispatch(
                updateBidStage({ id: singleBid.data.id, back: false })
            ).unwrap();
            await dispatch(fetchSingleBid(singleBid.data.id)).unwrap();
            dispatch(resetBidStatus());
            setConfirmOfferState('success');
        } catch (e) {
            setConfirmOfferState('error');
        }
    };

    const columns = useMemo<Column<IQualyOffer>[]>(
        () => [
            {
                Header: t('lots.family').toString(),
                accessor: 'lotFamily',
            },
            {
                Header: t('lots.titleSingular').toString(),
                accessor: 'lotName',
            },
            {
                Header: t('providers.singleTitle').toString(),
                accessor: 'provider',
            },
            {
                Header: t('lots.offer.skuOffered').toString(),
                accessor: 'offers',
            },
            {
                Header: t('lots.lotVolume').toString(),
                accessor: 'volume',
            },
            {
                Header: t('lots.offer.paymentTerm').toString(),
                accessor: 'paymentTerms',
            },
            {
                Header: t('lots.offer.paymentForm').toString(),
                accessor: 'paymentForm',
            },
            {
                Header: t('lots.shipmentDate').toString(),
                accessor: 'shipmentDate',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => {
                    if (value) {
                        return (
                            <span className="block text-right">
                                {new Date(value).toLocaleDateString(locale)}
                            </span>
                        );
                    }
                    return <span className="block text-center">--</span>;
                },
            },
            {
                Header: 'Days',
                accessor: 'days',
            },
            {
                Header: 'Offered',
                accessor: 'offeredPrice',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => <PriceBlock value={value} />,
            },
            {
                Header: 'Equivalent',
                accessor: 'equivalentPrice',
                Cell: (cell) => {
                    const { original } = cell.row;
                    const eqPrice = equivalentPrice(
                        Number(original.offeredPrice) ?? 0,
                        Number(intRate),
                        Number(original.days)
                    );
                    return (
                        <div className="w-full text-right">
                            <PriceBlock value={eqPrice} />
                        </div>
                    );
                },
            },
            {
                Header: t('bids.totalValueUSD').toString(),
                accessor: 'price',
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ cell: { value } }: any) => (
                    <PriceBlock value={value} />
                ),
            },
            {
                Header: t('common.comments').toString(),
                accessor: 'comments',
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ cell: { row } }: any) => {
                    if (
                        row.original.offer.observationsPlain !== '' ||
                        !row.original.offer.positions.every(
                            (p: any) =>
                                p.observationsPlain === '' ||
                                p.observationsPlain === null
                        )
                    ) {
                        return (
                            <div className="flex space-x-2 text-orange-700">
                                <div>{t('common.yes').toString()} </div>
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        );
                    }
                    return t('common.no').toString();
                },
            },
            {
                Header: 'Det.',
                accessor: 'lot',
                disableSortBy: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: (cell) => {
                    const { row } = cell;
                    const { lot, buyLot } = row.original;

                    return (
                        <button
                            type="button"
                            className="w-full"
                            onClick={() => {
                                setSelectedOffer({ lot, buyLot });
                                setOpenDetailModal(true);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mx-auto h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </button>
                    );
                },
            },
            // {
            //     Header: 'Enviar',
            //     id: 'send',
            //     Cell: () => {
            //         if (
            //             user?.role === UserRoles.ADMIN ||
            //             user?.role === UserRoles.SHOPPING_LEADER
            //         ) {
            //             return (
            //                 <div className="w-full text-center">
            //                     <button
            //                         type="button"
            //                         className="mx-auto"
            //                         onClick={() => setOpenModal(true)}
            //                     >
            //                         <svg
            //                             xmlns="http://www.w3.org/2000/svg"
            //                             className="h-6 w-6"
            //                             fill="none"
            //                             viewBox="0 0 24 24"
            //                             stroke="currentColor"
            //                             strokeWidth={2}
            //                         >
            //                             <path
            //                                 strokeLinecap="round"
            //                                 strokeLinejoin="round"
            //                                 d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            //                             />
            //                         </svg>
            //                     </button>
            //                 </div>
            //             );
            //         }
            //         return <div className="w-full text-center">-</div>;
            //     },
            // },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [intRate, locale, t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: reviewOfferLots,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative mb-32">
            <div className="w-full flex justify-between items-center">
                <Filter filter={filter} reviewOfferLots={reviewOfferLots} />
                {/* START INTEREST RATE */}
                <div className="w-32 my-10 bg-slate-700 p-2 rounded-md">
                    <label
                        htmlFor="interestRate"
                        className="block text-sm font-medium text-white"
                    >
                        Tasa int ( % )
                        <div className="mt-1">
                            <input
                                id="interestRate"
                                className="text-slate-700"
                                type="number"
                                step={0.1}
                                {...register('interestRate', {
                                    required: true,
                                    min: 0,
                                })}
                            />
                        </div>
                    </label>
                </div>
                {/* END INTEREST RATE */}
            </div>
            {reviewOfferLots.length === 0 && singleBid.status !== 'loading' && (
                <div className="text-xl text-center">
                    {t('common.noResults')}
                </div>
            )}
            {reviewOfferLots.length > 0 && (
                <div className="overflow-x-auto border-b border-slate-500">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className={`odd:bg-slate-200 hover:bg-slate-300 ${
                                                row.original.buyLot.id ===
                                                    selectedOffer?.buyLot.id &&
                                                'bg-green-300 odd:bg-green-300 hover:bg-green-500'
                                            }`}
                                            {...row.getRowProps()}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2"
                                                        // eslint-disable-next-line react/prop-types
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            // eslint-disable-next-line react/prop-types
                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}

            {selectedOffer && openDetailModal && (
                <div className="relative bg-slate-100 px-2 pt-6 pb-0 mb-10">
                    <h3 className="text-black text-xl underline">
                        Detalle oferta
                    </h3>

                    <Button
                        className="absolute right-4 top-4"
                        variant="outline"
                        onClick={() => {
                            setOpenDetailModal(false);
                            setSelectedOffer(null);
                        }}
                        label={t('common.close')}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        }
                    />
                    <QualifyOfferLotItem
                        id={selectedOffer.buyLot.id || ''}
                        status={selectedOffer.buyLot.status}
                        buyLot={selectedOffer.buyLot}
                        lot={selectedOffer.lot}
                        close={() => {
                            setOpenDetailModal(false);
                            setSelectedOffer(null);
                        }}
                    />
                </div>
            )}

            {/* MODAL TO CONFIRM LOTS */}
            {openModal && (
                <Modal setShowModal={setOpenModal}>
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('bids.actions.areYouSure')}
                        </h3>
                        <div>
                            <p>¿Estás seguro que quieres enviar esta oferta?</p>
                        </div>
                        <div className="mt-10 flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="link"
                                label={t('common.cancel')}
                                onClick={() => setOpenModal(false)}
                            />
                            <Button
                                type="button"
                                variant="success"
                                label={t('common.confirm')}
                                onClick={() => {
                                    nextStage();
                                    setOpenModal(false);
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            <RoleCheck
                allowedRoles={[UserRoles.ADMIN, UserRoles.SHOPPING_LEADER]}
                currentRole={user?.role || 'USER'}
            >
                <div className="w-full flex justify-end mt-4">
                    <Button
                        variant="success"
                        label={
                            // eslint-disable-next-line no-nested-ternary
                            confirmOfferState !== 'loading'
                                ? getButtonText(selectedOffers.length)
                                : t('wait.message')
                        }
                        onClick={() => {
                            setOpenModal(true);
                        }}
                    />
                </div>
            </RoleCheck>
        </div>
    );
}
