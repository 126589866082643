/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column, useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../store';
import LoadingAnimated from '../../../components/elements/Loading';

import PageHeader from '../../../components/PageHeader';
import { fetchProviders } from '../../../modules/providers/features/providersSlice';
import {
    IProvider,
    IProviderGroup,
} from '../../../modules/providers/interfaces';
// import { Button } from '../../../components/elements/Buttons';
import ListGroups from './components/ListGroups';
import { Button } from '../../../components/elements/Buttons';
import CreateProvider from './components/Create';
import EditProvider from './components/Edit';

export default function List() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const providers = useSelector((state: RootState) => state.providers);

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState<IProvider>();

    useEffect(() => {
        if (providers.status === 'idle') {
            dispatch(fetchProviders());
        }
    }, [providers, dispatch]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns = useMemo<Column<IProvider>[]>(
        () => [
            // {
            //     Header: t('common.id').toString(),
            //     accessor: 'id',

            // },
            {
                Header: t('common.name').toString(),
                accessor: 'name',
            },
            {
                Header: t('common.groups').toString(),
                accessor: 'groups',
                Cell: ({ cell: { value } }) =>
                    value.map((group: IProviderGroup) => group.name).join(', '),
            },
            {
                Header: t('common.description').toString(),
                accessor: 'description',
            },
        ],
        [t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: providers.data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative">
            <PageHeader title={t('providers.title')} />
            <Button
                className="absolute right-0 top-0 z-10"
                label={t('providers.create.buttonTitle')}
                type="button"
                onClick={() => setShowCreate(!showCreate)}
            />
            {showEdit && selectedProvider && (
                <EditProvider
                    show={showEdit}
                    toggle={() => {
                        setShowEdit(false);
                        setSelectedProvider(undefined);
                    }}
                    provider={selectedProvider}
                />
            )}
            {showCreate && (
                <CreateProvider
                    show={showCreate}
                    toggle={() => setShowCreate(false)}
                />
            )}

            {providers.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
            {providers.status === 'success' && (
                <div className="overflow-x-auto shadow-md">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-slate-200 hover:bg-slate-300"
                                            {...row.getRowProps()}
                                            onClick={() => {
                                                setShowEdit(true);
                                                setSelectedProvider(
                                                    row.original
                                                );
                                            }}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2 cursor-pointer"
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
            <ListGroups />
        </div>
    );
}
