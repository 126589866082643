/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

import { RootState, useAppDispatch } from '../../../../store';

import { Button } from '../../../../components/elements/Buttons';
import { ILot } from '../../../../modules/bids/interfaces';
import { fetchLotColors } from '../../../../modules/bids/features/lotColorsSlice';
import { ILotPosition } from '../../../../modules/bids/interfaces/ILotPosition';
import {
    addNewLotPosition,
    fetchLotPositions,
} from '../../../../modules/bids/features/lotPositionsSlice';

type FormData = {
    position: number;
    descriptionEs: string;
    descriptionEn: string;
    thickness: number;
    thicknessToleranceLess: number;
    thicknessToleranceOver: number;
    length: number;
    width: number;
    height: number;
    weight: number;
    diameter: number;
    kgMt: number;
    colorId: string;
    backSide: string;
    ssDesignationCoating: string;
};

export default function AddLotPos({
    lot,
    toggle,
}: {
    lot: ILot;
    toggle: () => void;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormData>();

    const { t, i18n } = useTranslation();

    const dispatch = useAppDispatch();
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const lotColors = useSelector((state: RootState) => state.lotColors);

    const rules = JSON.parse(lot.type.rules);

    useEffect(() => {
        if (i18n.language === 'es') {
            registerLocale('es', es);
        } else {
            registerLocale('en', enUS);
        }
        setDefaultLocale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        dispatch(fetchLotColors());
    }, [dispatch]);

    const onSubmit = async (data: FormData) => {
        try {
            if (!lot.id) {
                return;
            }
            // console.log(data);
            const attributes: ILotPosition = {
                thickness: +data.thickness,
                thicknessToleranceLess: +data.thicknessToleranceLess,
                thicknessToleranceOver: +data.thicknessToleranceOver,
                length: +data.length,
                width: +data.width,
                height: +data.height,
                weight: +data.weight,
                diameter: +data.diameter,
                kgMt: +data.kgMt,
                lotId: lot.id,
            };

            Object.keys(attributes).forEach((key) => {
                if (attributes[key] === 0) {
                    delete attributes[key];
                }
            });
            setAddRequestStatus('loading');
            await dispatch(addNewLotPosition(attributes)).unwrap();
            setAddRequestStatus('success');
            reset();
            dispatch(fetchLotPositions());
        } catch (error) {
            setAddRequestStatus('error');
            // console.log(error);
        }
    };
    interface IInputItems {
        name:
            | 'position'
            | 'descriptionEs'
            | 'descriptionEn'
            | 'thickness'
            | 'thicknessToleranceLess'
            | 'thicknessToleranceOver'
            | 'length'
            | 'width'
            | 'height'
            | 'weight'
            | 'diameter'
            | 'kgMt'
            | 'colorId'
            | 'backSide'
            | 'ssDesignationCoating';
        label: string;
        type: 'text' | 'number' | 'select' | 'textarea';
    }
    const inputs: IInputItems[] = [
        // {
        //     name: 'branchCode',
        //     label: t('lots.position.branchCode'),
        //     type: 'text',
        // },
        {
            name: 'thickness',
            label: t('lots.position.thickness'),
            type: 'number',
        },
        {
            name: 'thicknessToleranceOver',
            label: t('lots.position.thicknessToleranceOver'),
            type: 'number',
        },
        {
            name: 'thicknessToleranceLess',
            label: t('lots.position.thicknessToleranceLess'),
            type: 'number',
        },
        {
            name: 'length',
            label: t('lots.position.length'),
            type: 'number',
        },
        {
            name: 'width',
            label: t('lots.position.width'),
            type: 'number',
        },
        {
            name: 'height',
            label: t('lots.position.height'),
            type: 'number',
        },
        {
            name: 'diameter',
            label: t('lots.position.diameter'),
            type: 'text',
        },
        {
            name: 'kgMt',
            label: t('lots.position.kgMt'),
            type: 'number',
        },
        {
            name: 'kgMt',
            label: t('lots.position.kgMt'),
            type: 'number',
        },
        {
            name: 'colorId',
            label: t('lots.position.color'),
            type: 'select',
        },
        {
            name: 'backSide',
            label: t('lots.position.backSide'),
            type: 'select',
        },
        {
            name: 'ssDesignationCoating',
            label: t('lots.position.ssDesignationCoating'),
            type: 'text',
        },
    ];

    return (
        <div className="relative py-0 md:py-10 mb-20 mx-auto bg-gray-50 px-10 w-full">
            <h3 className="text-xl font-bold">Crear una posición</h3>
            <hr className="border border-slate-500 mb-4" />
            <button
                type="button"
                className="absolute top-2 right-2"
                onClick={toggle}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mb-4 space-x-6">
                    <div className="w-4/6">
                        <label
                            htmlFor="descriptionEn"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('common.description')}
                            <div className="mt-1">
                                <input
                                    id="descriptionEn"
                                    type="text"
                                    className={`text-center ${
                                        errors.descriptionEn
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('descriptionEn', {
                                        required: false,
                                    })}
                                />
                                {errors.descriptionEn && (
                                    <p className="text-red-600 ml-2">
                                        {errors.descriptionEn?.type ===
                                            'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="w-full flex-none md:flex items-start space-x-2">
                    {Object.keys(rules).map((key) => {
                        const rule = parseInt(rules[key], 10);

                        const input = inputs.find((i) => i.name === key);

                        if (input && rule !== 2) {
                            switch (input.type) {
                                case 'text':
                                case 'number':
                                    return (
                                        <div key={key}>
                                            <label
                                                htmlFor={input.name}
                                                className="block text-xs font-medium text-gray-700"
                                            >
                                                {t(input.label)}
                                                {rule === 1 && '*'}
                                                <div className="mt-1">
                                                    <input
                                                        id={input.name}
                                                        type={input.type}
                                                        step=".01"
                                                        className={`text-center ${
                                                            errors[input.name]
                                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                                : ''
                                                        }`}
                                                        {...register(
                                                            input.name,
                                                            {
                                                                required:
                                                                    rule === 1,
                                                            }
                                                        )}
                                                    />
                                                    {errors[input.name] && (
                                                        <p className="text-red-600 ml-2">
                                                            {errors[input.name]
                                                                ?.type ===
                                                                'required' &&
                                                                t(
                                                                    'common.errors.fieldRequired'
                                                                )}
                                                        </p>
                                                    )}
                                                </div>
                                            </label>
                                        </div>
                                    );
                                case 'select':
                                    return (
                                        <div key={key}>
                                            <label
                                                htmlFor={input.name}
                                                className="block text-xs font-medium text-gray-700"
                                            >
                                                {t(input.label)}
                                                {rule === 1 && '*'}
                                                <div className="mt-1">
                                                    <select
                                                        id={input.name}
                                                        className={`${
                                                            errors[input.name]
                                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                                : ''
                                                        }`}
                                                        {...register(
                                                            input.name,
                                                            {
                                                                required: true,
                                                                validate: (
                                                                    value
                                                                ) =>
                                                                    value !==
                                                                    '',
                                                            }
                                                        )}
                                                    >
                                                        <option value="">
                                                            {lotColors.status ===
                                                            'loading'
                                                                ? t(
                                                                      'common.loading'
                                                                  )
                                                                : t(
                                                                      'lots.position.selectAColor'
                                                                  )}
                                                        </option>
                                                        {lotColors.status ===
                                                            'success' &&
                                                            lotColors.data.map(
                                                                (
                                                                    color: any
                                                                ) => (
                                                                    <option
                                                                        key={
                                                                            color.id
                                                                        }
                                                                        value={
                                                                            color.id
                                                                        }
                                                                    >
                                                                        {
                                                                            color.nameEn
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                    </select>
                                                </div>
                                            </label>
                                            {errors[input.name] && (
                                                <p className="text-red-600 ml-2">
                                                    {t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    );
                                default:
                                    return null;
                            }
                        }
                        return null;
                    })}
                </div>
                <p className="mt-4 text-sm">* {t('common.requiredFields')}</p>
                <div className="mt-8 text-center">
                    {addRequestStatus === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('common.errors.unknown')}
                        </p>
                    )}
                    {addRequestStatus === 'success' && (
                        <p className="text-green-600 mb-2">
                            {t('lots.create.success')}
                        </p>
                    )}
                    <div className="flex space-x-4">
                        <button
                            disabled={addRequestStatus === 'loading'}
                            type="submit"
                            className="w-full flex justify-center items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-400 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                        >
                            {addRequestStatus === 'loading'
                                ? t('wait.message')
                                : t('common.save')}
                        </button>
                        <Button
                            className="bg-transparent w-full"
                            onClick={() => toggle()}
                            label={t('common.cancel')}
                            variant="link"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}
