/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */

import { useMemo, useState } from 'react';
import { t } from 'i18next';
import draftToHtml from 'draftjs-to-html';

import AddLotPos from './AddLotPos';

import { useAppDispatch } from '../../../../store';
import { addNewBuyLot } from '../../../../modules/bids/features/buyLotsSlice';
import {
    IBuyLotCreateAttr,
    ILot,
    IPositionCreate,
} from '../../../../modules/bids/interfaces';
import { Button } from '../../../../components/elements/Buttons';
import LotPositionList from './LotPositionList';
import { fetchSingleBid } from '../../../../modules/bids/features/singleBidSlice';
import { fetchBids } from '../../../../modules/bids/features/bidsSlice';

export default function LotItem({
    lot,
    className,
    cancel,
    bidId,
    setNewLotState,
}: {
    lot: ILot;
    className?: string;
    cancel: () => void;
    bidId: string;
    setNewLotState: (state: string) => void;
}) {
    const [showDetails, setShowDetails] = useState(false);
    const [showNewPosition, setShowNewPosition] = useState(false);
    const dispatch = useAppDispatch();

    const [volumes, setVolumes] = useState<{ id: string; value: string }[]>([]);
    const [addBuyLotState, setAddBuyLotState] = useState('idle');

    const onChange = useMemo(
        () => (id: string, value: string) => {
            const exist = volumes.findIndex((v) => v.id === id);

            if (exist !== -1) {
                volumes[exist].value = value;
            } else {
                volumes.push({ id, value });
            }
            setVolumes([...volumes]);
        },
        [volumes]
    );

    const generalSpec = () => {
        if (lot.generalSpecsRaw) {
            return { __html: draftToHtml(JSON.parse(lot.generalSpecsRaw)) };
        }
        if (lot.generalSpecsPlain) {
            return { __html: lot.generalSpecsPlain };
        }
        return { __html: '' };
    };

    const saveBuyLot = async () => {
        if (!lot.id) {
            return;
        }

        const buyLot = {
            lotId: lot.id,
            bidId,
        };

        const buyPositions: IPositionCreate[] = volumes
            .filter((v) => v.value !== '')
            .map((v) => ({
                lotPositionId: v.id,
                volume: v.value !== '' ? v.value : '0',
            }));

        const data: IBuyLotCreateAttr = {
            buyLot,
            buyPositions,
        };

        try {
            setAddBuyLotState('loading');
            await dispatch(addNewBuyLot(data)).unwrap();

            setAddBuyLotState('success');
            setNewLotState('success');
            await dispatch(fetchSingleBid(bidId)).unwrap();
            await dispatch(fetchBids()).unwrap();

            // setVolumes([]);
            cancel();
        } catch (e) {
            setAddBuyLotState('error');
        }
    };

    return (
        <div
            className={`mt-4  last:mb-20 px-10 py-4 text-slate-800 ${className}`}
        >
            <header className="flex rounded-md py-2 items-strech bg-slate-700 text-white text-left h-full">
                <div className="w-2/12 border-r border-slate-300 px-4">
                    <p>{t('common.code')}</p>
                    <p>
                        <strong>{lot.code}</strong>
                    </p>
                </div>
                <div className="w-4/12 border-slate-300 px-4">
                    <p>{t('common.description')}</p>
                    <p>
                        <strong>{lot.descriptionEn}</strong>
                    </p>
                </div>
                {/* <div className="w-2/12 border-l border-r border-slate-300 px-4">
                    <p>{t('lots.port')}</p>
                    <p>
                        <strong>Puerto 1</strong>
                    </p>
                </div> */}
                <div className="w-2/12 border-l border-r border-slate-300 px-4">
                    <p>{t('lots.lotType')}</p>
                    <p>
                        <strong>{lot.type?.name}</strong>
                    </p>
                </div>
                <div className="w-2/12 px-4">
                    <p>{t('lots.lotMetric')}</p>
                    <p>
                        <strong>{lot.metric?.nameEn}</strong>
                    </p>
                </div>
            </header>

            <button
                type="button"
                onClick={() => setShowDetails(!showDetails)}
                className="mt-4 mb-2 text-center w-full bg-slate-50 flex items-center justify-center"
            >
                {showDetails ? (
                    <>
                        <span className="mr-2">{t('common.hideDetails')}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 11l7-7 7 7M5 19l7-7 7 7"
                            />
                        </svg>
                    </>
                ) : (
                    <>
                        <span className="mr-2">{t('common.showDetails')}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                            />
                        </svg>
                    </>
                )}
            </button>

            {showDetails && (
                <div className="mb-2">
                    {generalSpec() && (
                        <div className="mt-4 w-full bg-slate-100 p-4 rounded-md">
                            <h3 className="font-bold py-2">
                                {t('lots.generalSpecifications')}
                            </h3>
                            <hr className="border border-slate-500 mb-2" />
                            {/* <div dangerouslySetInnerHTML={generalSpec()} /> */}
                            <div>{lot.generalSpecsPlain}</div>
                        </div>
                    )}
                </div>
            )}
            {/* <Button
                label={t('lots.newLotPosition')}
                type="button"
                onClick={() => {
                    setShowNewPosition(!showNewPosition);
                }}
                variant="outline"
                size="sm"
                className="mb-4"
            /> */}
            {showNewPosition && (
                <AddLotPos
                    toggle={() => {
                        setShowNewPosition(false);
                    }}
                    lot={lot}
                />
            )}
            {lot.positions && lot.positions.length > 0 && (
                <div className="mt-10">
                    <LotPositionList
                        lotPositions={lot.positions}
                        type={lot.type.name}
                        onChange={onChange}
                        volumes={volumes}
                    />
                </div>
            )}
            <div className="mt-4 text-right">
                {addBuyLotState === 'error' && (
                    <p className="text-red-600 mb-2">
                        {t('common.errors.unknown')}
                    </p>
                )}
                {addBuyLotState === 'success' && (
                    <p className="text-green-900 mb-2  font-bold">
                        {t('bids.create.success')}
                    </p>
                )}
            </div>
            <div className="w-full flex justify-end mt-10 space-x-4">
                <div className="w-1/3">
                    <Button
                        label={t('common.cancel')}
                        type="button"
                        variant="link"
                        className="w-full"
                        onClick={cancel}
                    />
                </div>
                <div className="w-1/3">
                    <button
                        disabled={addBuyLotState === 'loading'}
                        type="button"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                        onClick={saveBuyLot}
                    >
                        {addBuyLotState === 'loading'
                            ? t('wait.message')
                            : t('common.save')}
                    </button>
                </div>
            </div>
        </div>
    );
}

LotItem.defaultProps = {
    className: '',
};
