import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICreateAnswer, IReviewAnswer, IReviewQuestion } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ProviderState {
    data: IReviewQuestion[];
    status: Status;
    error: string | null;
}

const initialState: ProviderState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchQuestions = createAsyncThunk(
    'questions/fetchQuestions',
    async () => {
        const response = await APIClient.getData('/questions');
        return response.data;
    }
);

export const addQuestion = createAsyncThunk(
    'questions/addQuestion',
    async (data: { title: string }) => {
        const response = await APIClient.postData('/questions', data);
        return response.data;
    }
);

export const updateQuestion = createAsyncThunk(
    'providers/updateQuestion',
    async (question: IReviewQuestion) => {
        const response = await APIClient.putData(
            `/questions/${question.id}`,
            question
        );
        return response.data;
    }
);

export const updateAnswer = createAsyncThunk(
    'providers/updateAnswer',
    async (answer: IReviewAnswer) => {
        const response = await APIClient.putData(
            `/answers/${answer.id}`,
            answer
        );
        return response.data;
    }
);

export const createAnswer = createAsyncThunk(
    'providers/createAnswer',
    async (answer: ICreateAnswer) => {
        const response = await APIClient.postData(`/answers`, answer);
        return response.data;
    }
);

export const questionsSlice = createSlice({
    name: 'providers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchQuestions.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchQuestions.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array

                state.data = action.payload;
            })
            .addCase(fetchQuestions.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addQuestion.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(addQuestion.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateQuestion.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any updated data to the array
                const current = state.data.findIndex(
                    (question) => question.id === action.payload.id
                );
                state.data[current] = action.payload;
            })
            .addCase(updateQuestion.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
