import { Button } from '../../../../../../components/elements/Buttons';

export default function ButtonConfirmDefinition({
    reviewState,
    confirmAction,
    labels,
    messages,
}: {
    reviewState: string;
    confirmAction: () => void;
    labels: { action: string; wait: string };
    messages: { success: string; error: string };
}) {
    return (
        <div className="my-4 w-full flex justify-end">
            {reviewState !== 'success' && (
                <Button
                    variant="success"
                    type="button"
                    icon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    }
                    label={
                        reviewState === 'loading' ? labels.wait : labels.action
                    }
                    onClick={() => {
                        confirmAction();
                    }}
                />
            )}
            {reviewState === 'success' && (
                <p className="bg-green-700 text-white p-2">
                    {messages.success}
                </p>
            )}
        </div>
    );
}
