import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { INewOfferPositions } from '../../../pages/Dashboard/Bids/components/OfferLotItem';
import APIClient from '../../../services/api/client';
import { OfferLotStatus } from '../enums';
import { IOfferLot } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface IOfferLotState {
    data: IOfferLot[];
    status: Status;
    error: string | null;
}

const initialState: IOfferLotState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const createOfferLots = createAsyncThunk(
    'offerLot/createOfferLots',
    async (body: {
        offerLot: {
            observationsRaw?: string;
            observationsPlain?: string;
            mill: string;
            shipmentDate: Date;
            days: number;
            paymentFormId: string;
            paymentTermsId: string;
        };
        offerPositions: {
            buyPositionId: string;
            volume?: number;
            price?: number;
            observationsPlain?: string;
            observationsRaw?: string;
        }[];
    }) => {
        const response = await APIClient.postData('/buyOffers', body);

        return response.data;
    }
);

export const updateOfferLot = createAsyncThunk(
    'offerLot/updateOfferLot',
    async (data: {
        id: string;
        body: {
            offerLot: {
                observationsRaw?: string;
                observationsPlain?: string;
                mill?: string;
                shipmentDate?: Date;
                status?: OfferLotStatus;
            };
            updatedPositions?: INewOfferPositions[];
            newPositions?: INewOfferPositions[];
        };
    }) => {
        const response = await APIClient.putData(
            `/buyOffers/${data.id}`,
            data.body
        );

        return response.data;
    }
);

export const duplicateOfferLot = createAsyncThunk(
    'offerLot/duplicateOfferLot',
    async (id: string) => {
        const response = await APIClient.postData(`/buyOffers/${id}/clone`, {});

        return response.data;
    }
);

export const deleteOfferLot = createAsyncThunk(
    'offerLot/deleteOfferLot',
    async (id: string) => {
        const response = await APIClient.deleteData(`/buyOffers/${id}`, {});

        return response.data;
    }
);

export const offerLotsSlice = createSlice({
    name: 'offerLot',
    initialState,
    reducers: {
        setAskedValues: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createOfferLots.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(createOfferLots.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(createOfferLots.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(updateOfferLot.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(updateOfferLot.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(updateOfferLot.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(duplicateOfferLot.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(duplicateOfferLot.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                // state.data = state.data.concat(action.payload);
            })
            .addCase(duplicateOfferLot.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(deleteOfferLot.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteOfferLot.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                // state.data = state.data.concat(action.payload);
            })
            .addCase(deleteOfferLot.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            });
    },
});
