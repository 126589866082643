import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IProviderGroup } from '../interfaces';
import { IProviderGroupCreateAttr } from '../interfaces/IProvider';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ProviderGroupState {
    data: IProviderGroup[];
    status: Status;
    error: string | null;
}

const initialState: ProviderGroupState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchProviderGroups = createAsyncThunk(
    'providerGroups/fetchProviderGroups',
    async () => {
        const response = await APIClient.getData('/providers/groups');
        return response.data;
    }
);

export const addProviderGroup = createAsyncThunk(
    'providerGroups/addProviderGroup',
    async (data: IProviderGroupCreateAttr) => {
        const response = await APIClient.postData('/providers/groups', data);
        return response.data;
    }
);

export const providerGroupsSlice = createSlice({
    name: 'providerGroups',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchProviderGroups.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchProviderGroups.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchProviderGroups.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addProviderGroup.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(addProviderGroup.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
