import { t } from 'i18next';
import { User } from '../../../context/interfaces';
import Items, { IItems } from './Items';

interface ISideBarProps {
    className?: string;
    currentPath: string | undefined;
    items?: IItems[];
    goTo: (path: string) => void;
    user: User | null | undefined;
}

export default function SideBar({
    className,
    currentPath,
    items,
    goTo,
    user,
}: ISideBarProps) {
    return (
        <div className="relative flex flex-col h-full z-50">
            <nav
                className={`space-y-2 pt-2 pb-4 text-sym-sidebar-700 symlab-header bg-sym-sidebar-300 h-full shadow-md  shadow-sym-sidebar-600 rounded-lg rounded-tr-none rounded-br-none border border-gray-400 overflow-x-hidden overflow-y-hidden hover:overflow-y-auto ${className}`}
            >
                {user && (
                    <div className="text-center pb-2 border-b border-gray-700 mb-10">
                        <p>
                            {user.firstName} {user.lastName}
                        </p>
                        <p>{t(`users.roles.${user.role}`)}</p>
                    </div>
                )}
                <ul role="menubar" aria-label="main menu">
                    {items?.map((item) => {
                        if (item.type === 'title') {
                            return (
                                <li
                                    key={item.label}
                                    className={`symlab-sidebar__title ml-6 my-2 border-b border-b-slate-400 ${item.className}`}
                                >
                                    {item.label}
                                </li>
                            );
                        }
                        return (
                            <Items
                                key={item.label}
                                label={item.label}
                                icon={item.icon}
                                className={item.className}
                                labelClassName={item.labelClassName}
                                currentPath={currentPath}
                                disabled={item.disabled}
                                subItems={item.subItems}
                                route={item.route}
                                goTo={goTo}
                            />
                        );
                    })}
                </ul>
            </nav>
            <div className="absolute bottom-2 left-0 text-center w-full justify-center">
                <p className="text-sm text-sym-sidebar-600">
                    Made by{' '}
                    <a
                        className="text-indigo-900"
                        href="https://symlab.io"
                        title="SymLab"
                    >
                        @symlab
                    </a>
                </p>
            </div>
        </div>
    );
}

SideBar.defaultProps = {
    className: '',
    items: [],
};
