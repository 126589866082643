import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from '../../../../../../store';

import AutoComplete from '../../../../../../components/Autocomplete';
import { Button } from '../../../../../../components/elements/Buttons';
import { ILot } from '../../../../../../modules/bids/interfaces';
import LotItem from '../../LotItem';
import AutoCompleteItem from './AutoCompleteItem';
import ProcessFile from './ProcessFile';
import { fetchLotColors } from '../../../../../../modules/bids/features/lotColorsSlice';
import { createFile } from '../../../helpers';
import { fetchLotsByBranch } from '../../../../../../modules/bids/features/lotsSlice';

export default function NewLot({
    bidId,

    toggleShow,
}: {
    bidId: string;

    toggleShow: () => void;
}) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const lotColors = useSelector((state: RootState) => state.lotColors);
    const singleBid = useSelector((state: RootState) => state.singleBid);
    const lotsByBranch = useSelector((state: RootState) => state.lots);
    const [selectedLot, setSelectedLot] = useState<ILot>();
    const [newLotState, setNewLotState] = useState('idle');

    useEffect(() => {
        if (lotColors.status === 'idle') {
            dispatch(fetchLotColors());
        }
    }, [dispatch, lotColors]);

    useEffect(() => {
        if (newLotState === 'success') {
            // Remove selected lot from autocomplete
            setSelectedLot(undefined);
        }
    }, [newLotState]);

    useEffect(() => {
        if (singleBid.data?.branch.id && lotsByBranch.status === 'idle') {
            dispatch(fetchLotsByBranch(singleBid.data.branch.id));
        } else if (
            // Ya entramos a un detalle anteriormente queremos chequear si es una nueva sucursal para actualizar los lotes
            singleBid.data?.branch.id &&
            lotsByBranch.data?.length > 0 &&
            lotsByBranch.data[0].branchId !== singleBid.data?.branch.id
        ) {
            dispatch(fetchLotsByBranch(singleBid.data.branch.id));
        }
    }, [dispatch, singleBid?.data?.branch.id, lotsByBranch]);

    const clickLotSearchItem = (itemId: string) => {
        if (singleBid.data?.buyLots && singleBid.data.buyLots.length > 0) {
            const alreadyExists = singleBid.data.buyLots.find(
                (bl) => bl.lotId === itemId
            );
            if (alreadyExists) {
                setNewLotState('error');
                setSelectedLot(undefined);
                return;
            }
        }
        setSelectedLot(lotsByBranch.data?.find((lot) => lot.id === itemId));
        setNewLotState('idle');
    };

    const toggle = () => {
        setSelectedLot(undefined);
        toggleShow();
    };

    return (
        <div className="relative w-full px-10 py-4 mx-auto bg-slate-300 rounded-md">
            <button
                type="button"
                className="absolute top-2 right-2"
                onClick={() => {
                    toggle();
                }}
                aria-label={t('common.close')}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>
            <h3 className="text-slate-800 text-xl">
                {t('bids.actions.addLot.title')}
            </h3>
            <hr className="my-2" />
            <div className="ml-10 flex items-baseline space-x-4">
                <div className="w-2/3">
                    <AutoComplete
                        getItems={({ query }: { query: string }) => {
                            const items = lotsByBranch.data.filter((lot) =>
                                lot.code
                                    .toLowerCase()
                                    .includes(query.toLowerCase())
                            );
                            if (!items) {
                                return [];
                            }
                            return items;
                        }}
                        sourceId="lots"
                        onClick={clickLotSearchItem}
                        Item={AutoCompleteItem}
                    />
                </div>
                <div className="flex space-x-6">
                    <div className="w-44">
                        <p className="text-black text-lg italic">
                            ó sube un archivo
                        </p>
                    </div>
                    <div>
                        <ProcessFile bidId={bidId} closeDialog={toggle} />
                        <p className="mt-1 text-black text-sm">
                            {t('bids.actions.addLot.fileWarning')}
                        </p>
                        {lotColors.data && (
                            <p className="mt-1 text-black">
                                {t('bids.actions.addLot.fileFormat')}{' '}
                                <button
                                    type="button"
                                    onClick={() => {
                                        createFile(
                                            lotsByBranch.data,
                                            lotColors.data
                                        );
                                    }}
                                >
                                    <div className="flex bg-green-700 font-bold text-white px-2 py-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 1a.75.75 0 01.75.75V6h-1.5V1.75A.75.75 0 018 1zm-.75 5v3.296l-.943-1.048a.75.75 0 10-1.114 1.004l2.25 2.5a.75.75 0 001.114 0l2.25-2.5a.75.75 0 00-1.114-1.004L8.75 9.296V6h2A2.25 2.25 0 0113 8.25v4.5A2.25 2.25 0 0110.75 15h-5.5A2.25 2.25 0 013 12.75v-4.5A2.25 2.25 0 015.25 6h2zM7 16.75v-.25h3.75a3.75 3.75 0 003.75-3.75V10h.25A2.25 2.25 0 0117 12.25v4.5A2.25 2.25 0 0114.75 19h-5.5A2.25 2.25 0 017 16.75z"
                                                clipRule="evenodd"
                                            />
                                        </svg>

                                        <span className="ml-1">
                                            {t('common.here').toUpperCase()}
                                        </span>
                                    </div>
                                </button>
                            </p>
                        )}
                    </div>
                </div>
            </div>

            {newLotState === 'error' && (
                <div className="ml-10 w-56 text-center py-2 bg-red-900 text-red-100 rounded-md">
                    <p className="font-bold">
                        {t('bids.positions.errors.positionExist')}
                    </p>
                </div>
            )}

            {newLotState === 'success' && (
                <div className="flex space-x-4 items-baseline justify-center">
                    <p className="text-green-900 mb-2  font-bold">
                        {t('bids.positions.success')}
                    </p>
                    <Button
                        label={t('common.close')}
                        onClick={() => {
                            setNewLotState('idle');
                            toggle();
                        }}
                        variant="link"
                        size="sm"
                    />
                </div>
            )}
            {selectedLot && bidId && (
                <LotItem
                    lot={selectedLot}
                    cancel={toggle}
                    bidId={bidId}
                    setNewLotState={setNewLotState}
                />
            )}
        </div>
    );
}
