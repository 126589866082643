import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { RootState } from '../../../store';
import { IBid, IBidCreateAttr } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

interface IFile {
    name: string;
    fileType: string;
}

export interface BidsState {
    data: IBid[];
    status: Status;
    error: string | null;
}

const initialState: BidsState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchBids = createAsyncThunk('bids/fetchBids', async () => {
    const response = await APIClient.getData('/bids');
    return response.data;
});

export const addNewBid = createAsyncThunk<
    IBid,
    IBidCreateAttr,
    { state: RootState }
>('bids/addNewBid', async (bid: IBidCreateAttr, thunkApi) => {
    const response = await APIClient.postData('/bids', bid);
    const { data } = response;
    const state = thunkApi.getState();
    const branch = state.branches.branches.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (b: { id: any }) => b.id === data.branchId
    );

    return {
        branch: {
            name: branch?.name || '',
        },
        buyLots: [],
        totalVolume: 0,
        totalLots: 0,
        ...data,
    };
});

export const bidUploadUrl = createAsyncThunk(
    'bids/uploadUrl',
    async (data: IFile) => {
        const response = await APIClient.postData('/users/upload', data);

        return response.data;
    }
);

export const bidsSlice = createSlice({
    name: 'bids',
    initialState,
    reducers: {
        resetBidStatus: (state) => {
            state.status = Status.idle;
            state.error = null;
            state.data = [];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBids.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchBids.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                state.data = action.payload;
            })
            .addCase(fetchBids.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(addNewBid.fulfilled, (state, action) => {
                state.data.unshift(action.payload);
            });
    },
});

export const { resetBidStatus } = bidsSlice.actions;
