/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../store';
import 'react-datepicker/dist/react-datepicker.css';

import SideCreate from '../../../../components/elements/SideCreate';
import { addQuestion } from '../../../../modules/questions/features/questionsSlice';

type FormData = {
    title: string;
};

export default function CreateQuestion({
    show = false,
    toggle,
}: {
    show: boolean;
    toggle: () => void;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormData>();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const onSubmit = async (data: FormData) => {
        try {
            setAddRequestStatus('loading');
            await dispatch(addQuestion(data)).unwrap();
            setAddRequestStatus('success');
            reset();
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };

    const onHide = () => {
        setAddRequestStatus('idle');
        reset();
        toggle();
    };

    return (
        <SideCreate show={show} title={t('questions.title')} toggle={onHide}>
            <div className="mb-20">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('questions.titleSingle')}
                            <div className="mt-1">
                                <input
                                    id="title"
                                    type="text"
                                    className={`${
                                        errors.title
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('title', {
                                        required: true,
                                    })}
                                />
                                {errors.title && (
                                    <p className="text-red-600 ml-2">
                                        {errors.title.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>

                    <div className="mt-4">
                        {/* {error && (
                                <p className="text-red-600 mb-2">
                                    {handleSignInError(error)}
                                </p>
                            )} */}
                        {addRequestStatus === 'success' && (
                            <p className="text-green-600 mb-2">
                                {t('questions.success')}
                            </p>
                        )}
                        <button
                            disabled={addRequestStatus === 'loading'}
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                        >
                            {addRequestStatus === 'loading'
                                ? t('wait.message')
                                : t('common.save')}
                        </button>
                    </div>
                </form>
            </div>
        </SideCreate>
    );
}
