import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../../../components/elements/Buttons';
import LoadingAnimated from '../../../../../../components/elements/Loading';
import Modal from '../../../../../../components/Modal';
import useAuth from '../../../../../../context/useAuth';
import { BidStages } from '../../../../../../modules/bids/enums';
import {
    fetchSingleBid,
    updateBidStage,
} from '../../../../../../modules/bids/features/singleBidSlice';
import {
    createAnswer,
    fetchQuestions,
    updateAnswer,
} from '../../../../../../modules/questions/features/questionsSlice';
import { IReviewQuestion } from '../../../../../../modules/questions/interfaces';
import { UserRoles } from '../../../../../../modules/users/enums';
import { RootState, useAppDispatch } from '../../../../../../store';

function StarIcon(props: { fill: string; color: string }) {
    const { fill = 'none', color = 'text-gray-400' } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 ${color}`}
            fill={fill}
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
            />
        </svg>
    );
}

function RatingIcon(props: {
    index: number;
    rating: number;
    hoverRating: number;
    onMouseEnter: (index: number) => void;
    onMouseLeave: () => void;
    onSaveRating: (index: number) => void;
    disabled: boolean;
}) {
    const {
        index,
        rating,
        hoverRating,
        onMouseEnter,
        onMouseLeave,
        onSaveRating,
        disabled,
    } = props;
    const fill = useMemo(() => {
        if (hoverRating >= index) {
            return 'currentColor';
        }

        if (!hoverRating && rating >= index) {
            return 'currentColor';
        }

        return 'none';
    }, [rating, hoverRating, index]);

    const color = useMemo(() => {
        if (hoverRating >= index) {
            return 'text-yellow-600';
        }

        if (!hoverRating && rating >= index) {
            return 'text-yellow-600';
        }

        return 'text-gray-400';
    }, [rating, hoverRating, index]);
    return (
        <button
            type="button"
            className="cursor-pointer"
            onMouseEnter={() => (disabled ? null : onMouseEnter(index))}
            onMouseLeave={() => (disabled ? null : onMouseLeave())}
            onClick={() => (disabled ? null : onSaveRating(index))}
        >
            <StarIcon fill={fill} color={color} />
        </button>
    );
}

function RatingStars({
    ratingSelected,
    value = 0,
    disabled,
}: {
    ratingSelected: (index: number) => void;
    value?: number;
    disabled: boolean;
}) {
    const [rating, setRating] = useState(value);
    const [hoverRating, setHoverRating] = useState(0);
    const onMouseEnter = (index: number) => {
        setHoverRating(index);
    };
    const onMouseLeave = () => {
        setHoverRating(0);
    };
    const onSaveRating = (index: number) => {
        setRating(index);
        ratingSelected(index);
    };

    return (
        <div className="flex justify-end">
            {[1, 2, 3, 4, 5].map((index) => (
                <RatingIcon
                    key={index}
                    index={index}
                    rating={rating}
                    hoverRating={hoverRating}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onSaveRating={onSaveRating}
                    disabled={disabled}
                />
            ))}
        </div>
    );
}

RatingStars.defaultProps = {
    value: 0,
};

export default function Review() {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const { id } = useParams();

    const questions = useSelector((state: RootState) => {
        const quest = { ...state.questions };
        quest.data = state.questions.data?.filter(
            (question) => question.active
        );
        return quest;
    });

    const singleBid = useSelector((state: RootState) => state.singleBid);

    const [ratings, setRatings] = useState<{ id: string; rating: number }[]>(
        []
    );

    const [updateState, setUpdateState] = useState('idle');
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (
            questions.status === 'idle' &&
            user &&
            user.role !== UserRoles.PROVIDER
        ) {
            dispatch(fetchQuestions());
        }
    }, [dispatch, questions.status, user]);

    useEffect(() => {
        if (singleBid.status === 'idle' && id) {
            dispatch(fetchSingleBid(id));
        }
    }, [dispatch, id, singleBid.status]);

    useEffect(() => {
        if (questions.status === 'success' && ratings.length === 0) {
            setRatings(
                questions.data.map((question) => ({
                    id: question.id,
                    rating: 0,
                }))
            );
        }
    }, [questions.data, questions.status, ratings.length, setRatings]);

    const ratingSelected = useCallback(
        async (questionId: string, rating: number) => {
            // set rating
            const ratingIndex = ratings?.findIndex((r) => r.id === questionId);

            if (ratingIndex >= 0) {
                const newRatings = [...ratings];
                newRatings[ratingIndex].rating = rating;
                setRatings(newRatings);
                const answer = singleBid.data?.reviewAnswers?.find(
                    (a) => a.questionId === questionId
                );

                if (answer) {
                    try {
                        const newAnswer = {
                            ...answer,
                            answer: rating.toString(),
                        };

                        await dispatch(updateAnswer(newAnswer)).unwrap();
                    } catch (error) {
                        // TODO: handle error
                    }
                } else {
                    try {
                        const newAnswer = {
                            bidId: id ?? '',
                            questionId,
                            rating: rating.toString(),
                        };

                        await dispatch(createAnswer(newAnswer)).unwrap();
                    } catch (error) {
                        // TODO: handle error
                    }
                }
            }
        },
        [ratings, dispatch, id, singleBid.data]
    );

    const getValue = (questionId: string) => {
        const value = singleBid.data?.reviewAnswers?.find(
            (a) => a.questionId === questionId
        )?.answer;
        return parseInt(value ?? '0', 10);
    };

    const finishBid = async () => {
        if (!id) {
            return;
        }

        try {
            setUpdateState('loading');

            await dispatch(updateBidStage({ id, back: false })).unwrap();
            await dispatch(fetchSingleBid(id)).unwrap();
            setUpdateState('success');
        } catch (e) {
            setUpdateState('error');
        }
    };

    if (!user) {
        return (
            <div className="flex flex-col items-center justify-center h-full">
                <h1 className="text-2xl font-bold text-gray-600">
                    You are not allowed to access this page
                </h1>
            </div>
        );
    }

    if (user?.role === UserRoles.PROVIDER) {
        return (
            <div className="flex flex-col items-center justify-center h-full">
                <h1 className="text-2xl font-bold text-gray-600">
                    Providers are not allowed to access this page.
                </h1>
            </div>
        );
    }

    if (questions.status === 'loading') {
        return <LoadingAnimated message={t('common.loading')} />;
    }
    if (questions.status === 'success' && singleBid.status === 'success') {
        return (
            <>
                <div className="px-24 mb-20">
                    {singleBid.data?.currentStage === BidStages.CLOSED && (
                        <h2 className="text-2xl font-bold text-center text-green-900 uppercase border-t border-green-900 border-b py-2 mb-2">
                            {t('bids.reception.bidFinished')}
                        </h2>
                    )}
                    <h3 className="text-lg uppercase font-bold">Proceso</h3>
                    <ul>
                        {questions.data
                            .filter((quest) => quest.category === 'process')
                            .map((q: IReviewQuestion) => (
                                <li
                                    className="flex border-b-2 border-gray-400 p-2 mb-2"
                                    key={q.id}
                                >
                                    <div className="w-4/5 flex-wrap">
                                        {q.title}
                                    </div>
                                    <div className="w-1/5">
                                        <RatingStars
                                            ratingSelected={(index: number) => {
                                                ratingSelected(q.id, index);
                                            }}
                                            value={getValue(q.id)}
                                            disabled={
                                                singleBid.data?.currentStage ===
                                                BidStages.CLOSED
                                            }
                                        />
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="px-24 mb-20">
                    <h3 className="text-lg uppercase font-bold">Recepción</h3>
                    <ul>
                        {questions.data
                            .filter((quest) => quest.category === 'reception')
                            .map((q: IReviewQuestion) => (
                                <li
                                    className="flex border-b-2 border-gray-400 p-2 mb-2"
                                    key={q.id}
                                >
                                    <div className="w-4/5 flex-wrap">
                                        {q.title}
                                    </div>
                                    <div className="w-1/5">
                                        <RatingStars
                                            ratingSelected={(index: number) =>
                                                ratingSelected(q.id, index)
                                            }
                                            value={getValue(q.id)}
                                            disabled={
                                                singleBid.data?.currentStage ===
                                                BidStages.CLOSED
                                            }
                                        />
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
                {singleBid.data?.currentStage === BidStages.RECEPTION && (
                    <Button
                        className="w-full"
                        variant="primary"
                        onClick={() => {
                            setOpenModal(true);
                        }}
                        type="button"
                        label={
                            updateState === 'loading'
                                ? t('wait.message')
                                : t('bids.reception.finishBid.title')
                        }
                    />
                )}
                {openModal && (
                    <Modal setShowModal={setOpenModal}>
                        <div className="px-4">
                            <h3 className="text-center text-2xl font-bold mb-10">
                                {t('bids.actions.areYouSure')}
                            </h3>
                            <div>
                                <p>
                                    {t('bids.reception.finishBid.description')}
                                </p>
                            </div>
                            <div className="mt-10 flex justify-end space-x-4">
                                <Button
                                    type="button"
                                    variant="link"
                                    label={t('common.cancel')}
                                    onClick={() => setOpenModal(false)}
                                />
                                <Button
                                    type="button"
                                    variant="success"
                                    label={t('common.confirm')}
                                    onClick={() => {
                                        finishBid();
                                        setOpenModal(false);
                                    }}
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            </>
        );
    }

    return null;
}
