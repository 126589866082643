/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';

import { useAppDispatch, RootState } from '../../../../store';
import 'react-datepicker/dist/react-datepicker.css';

import SideCreate from '../../../../components/elements/SideCreate';
import { fetchBranches } from '../../../../modules/branches/features/branchesSlice';
import { addNewBid } from '../../../../modules/bids/features/bidsSlice';

type FormData = {
    name: string;
    definitionDateFrom: Date;
    definitionDateTo: Date;
    bidDateFrom: Date;
    bidDateTo: Date;
    branchId: string;
    portId: string;
};

export default function CreateBid({
    show = false,
    toggle,
}: {
    show: boolean;
    toggle: () => void;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm<FormData>();

    const { t, i18n } = useTranslation();

    const dispatch = useAppDispatch();
    const branches = useSelector((state: RootState) => state.branches);
    const ports = useSelector((state: RootState) => state.ports);
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const definitionDateFrom = watch('definitionDateFrom');
    const definitionDateTo = watch('definitionDateTo');
    const bidDateFrom = watch('bidDateFrom');
    const bidDateTo = watch('bidDateTo');
    const branchesCheck = watch('branchId');
    const startDate = new Date();
    const endDate = new Date();

    useEffect(() => {
        if (
            definitionDateTo &&
            definitionDateFrom &&
            definitionDateFrom.getTime() > definitionDateTo.getTime()
        ) {
            setValue('definitionDateFrom', definitionDateTo);
        }
    }, [definitionDateFrom, definitionDateTo, setValue]);

    useEffect(() => {
        if (
            bidDateTo &&
            bidDateFrom &&
            bidDateFrom.getTime() > bidDateTo.getTime()
        ) {
            setValue('bidDateFrom', bidDateTo);
        }
    }, [bidDateFrom, bidDateTo, setValue]);

    useEffect(() => {
        if (branches.status === 'idle') {
            dispatch(fetchBranches());
        }
    }, [branches, dispatch]);

    useEffect(() => {
        register('definitionDateFrom', { required: true });
        register('definitionDateTo', { required: true });
        register('bidDateFrom', { required: true });
        register('bidDateTo', { required: true });
        register('branchId', { required: true });
    }, [register]);

    useEffect(() => {
        if (i18n.language === 'es') {
            registerLocale('es', es);
        } else {
            registerLocale('en', enUS);
        }
        setDefaultLocale(i18n.language);
    }, [i18n.language]);

    const onSubmit = async (data: FormData) => {
        try {
            setAddRequestStatus('loading');
            await dispatch(addNewBid(data)).unwrap();
            setAddRequestStatus('success');
            reset();
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };

    const onHide = () => {
        setAddRequestStatus('idle');
        reset();
        toggle();
    };

    return (
        <SideCreate show={show} title={t('bids.create.title')} toggle={onHide}>
            <div className="mb-20">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('common.name')}
                            <div className="mt-1">
                                <input
                                    id="name"
                                    type="text"
                                    className={`${
                                        errors.name
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('name', {
                                        required: true,
                                    })}
                                />
                                {errors.name && (
                                    <p className="text-red-600 ml-2">
                                        {errors.name.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>
                    <div className="mt-4">
                        <p className="font-bold">
                            {t('bids.create.deadlines')}
                        </p>
                    </div>
                    <div className="mt-2 flex space-x-2 items-center bg-gray-100 p-2 rounded-md">
                        <div className="w-1/3">
                            <p className="font-bold text-gray-600">
                                {t('bids.stages.definition')}
                            </p>
                        </div>
                        <div className="w-1/3">
                            <label
                                htmlFor="definitionDateFrom"
                                className="block text-sm font-medium text-gray-700"
                            >
                                <span>{t('common.from')}</span>
                                <div className="mt-1">
                                    <DatePicker
                                        selected={definitionDateFrom}
                                        onChange={(date: Date) =>
                                            setValue('definitionDateFrom', date)
                                        }
                                        minDate={startDate}
                                        selectsStart
                                        startDate={
                                            definitionDateFrom ?? startDate
                                        }
                                        endDate={definitionDateTo ?? endDate}
                                        dateFormat="P"
                                    />
                                    {errors.definitionDateFrom && (
                                        <p className="text-red-600 ml-2">
                                            {errors.definitionDateFrom.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                        <div className="w-1/3">
                            <label
                                htmlFor="definitionDateTo"
                                className="block text-sm font-medium text-gray-700"
                            >
                                <span>{t('common.to')}</span>
                                <div className="mt-1">
                                    <DatePicker
                                        selected={definitionDateTo}
                                        onChange={(date: Date) =>
                                            setValue('definitionDateTo', date)
                                        }
                                        minDate={
                                            definitionDateFrom ?? startDate
                                        }
                                        selectsEnd
                                        startDate={
                                            definitionDateFrom ?? startDate
                                        }
                                        endDate={definitionDateTo ?? endDate}
                                        dateFormat="P"
                                    />
                                    {errors.definitionDateTo && (
                                        <p className="text-red-600 ml-2">
                                            {errors.definitionDateTo.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="mt-2 flex space-x-2 items-center bg-gray-100 p-2 rounded-md">
                        <div className="w-1/3">
                            <p className="font-bold text-gray-600">
                                {t('bids.stages.bid')}
                            </p>
                        </div>
                        <div className="w-1/3">
                            <label
                                htmlFor="bidDateFrom"
                                className="block text-sm font-medium text-gray-700"
                            >
                                <span>{t('common.from')}</span>
                                <div className="mt-1">
                                    <DatePicker
                                        selected={bidDateFrom}
                                        onChange={(date: Date) =>
                                            setValue('bidDateFrom', date)
                                        }
                                        minDate={definitionDateTo ?? startDate}
                                        selectsStart
                                        startDate={
                                            bidDateFrom ?? definitionDateTo
                                        }
                                        endDate={bidDateTo ?? definitionDateTo}
                                        dateFormat="P"
                                    />
                                    {errors.bidDateFrom && (
                                        <p className="text-red-600 ml-2">
                                            {errors.bidDateFrom.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                        <div className="w-1/3">
                            <label
                                htmlFor="bidDateTo"
                                className="block text-sm font-medium text-gray-700"
                            >
                                <span>{t('common.to')}</span>
                                <div className="mt-1">
                                    <DatePicker
                                        selected={bidDateTo}
                                        onChange={(date: Date) =>
                                            setValue('bidDateTo', date)
                                        }
                                        minDate={
                                            bidDateFrom ?? definitionDateTo
                                        }
                                        selectsEnd
                                        startDate={
                                            bidDateFrom ?? definitionDateTo
                                        }
                                        endDate={bidDateTo ?? definitionDateTo}
                                        dateFormat="P"
                                    />
                                    {errors.bidDateTo && (
                                        <p className="text-red-600 ml-2">
                                            {errors.bidDateTo.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                    </div>
                    {/* START Ports */}
                    <div className="w-full mt-4 mb-4">
                        <label
                            htmlFor="portId"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('common.port')}*
                            <div className="mt-1">
                                <select
                                    id="portId"
                                    className={`${
                                        errors.portId
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('portId', {
                                        required: true,
                                        validate: (value) => value !== '',
                                    })}
                                >
                                    <option value="">
                                        {ports.status === 'loading'
                                            ? t('common.loading')
                                            : t('lots.create.selectAPort')}
                                    </option>
                                    {ports.status === 'success' &&
                                        ports.data.map((port) => (
                                            <option
                                                key={port.id}
                                                value={port.id}
                                            >
                                                {port.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
                        {errors.portId && (
                            <p className="text-red-600 ml-2">
                                {t('common.errors.fieldRequired')}
                            </p>
                        )}
                    </div>
                    {/* END Ports */}
                    {/* START Branches */}
                    <div className="mt-4">
                        <p className="font-bold">{t('common.branches')}</p>
                        {branches.status === 'loading' && (
                            <div className="mt-2">
                                <div className="w-full h-12 flex justify-center items-center">
                                    {t('common.loading')}
                                </div>
                            </div>
                        )}
                        {branches &&
                            branches.status === 'success' &&
                            branches.branches.map((branch) => (
                                <div key={branch.id} className="flex items-end">
                                    <div className="mt-1 mr-2">
                                        <input
                                            id="branchId"
                                            name="branchId"
                                            checked={
                                                branch.id === branchesCheck
                                            }
                                            onChange={() => {
                                                setValue('branchId', branch.id);
                                            }}
                                            type="radio"
                                            className={`${
                                                errors.branchId
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                        />
                                    </div>
                                    <label
                                        htmlFor="branchId"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {branch.name}
                                    </label>
                                </div>
                            ))}
                        {errors.branchId && (
                            <p className="text-red-600 ml-2">
                                {errors.branchId.type === 'required' &&
                                    t('common.errors.fieldRequired')}
                            </p>
                        )}
                    </div>

                    {/* END Branches */}

                    <div className="mt-4">
                        {/* {error && (
                                <p className="text-red-600 mb-2">
                                    {handleSignInError(error)}
                                </p>
                            )} */}
                        {addRequestStatus === 'success' && (
                            <p className="text-green-600 mb-2">
                                {t('bids.create.success')}
                            </p>
                        )}
                        <button
                            disabled={addRequestStatus === 'loading'}
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                        >
                            {addRequestStatus === 'loading'
                                ? t('wait.message')
                                : t('common.save')}
                        </button>
                    </div>
                </form>
            </div>
        </SideCreate>
    );
}
