import { TFunction, useTranslation } from 'react-i18next';
import { UserRoles } from '../modules/users/enums';

const bids = (t: TFunction<'translation', undefined>) => ({
    label: t('bids.title'),
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
        </svg>
    ),
    route: '/',
});

const providers = (t: TFunction<'translation', undefined>) => ({
    label: t('providers.title'),
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
        </svg>
    ),
    route: '/providers',
});

const users = (t: TFunction<'translation', undefined>) => ({
    label: t('users.title'),
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
        </svg>
    ),
    route: '/users',
});

const lots = (t: TFunction<'translation', undefined>) => ({
    label: t('lots.title'),
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
            />
        </svg>
    ),
    route: '/lots',
    subItems: [
        {
            label: 'Admin',
            route: '/lots/admin',
        },
    ],
});

const questions = (t: TFunction<'translation', undefined>) => ({
    label: t('questions.title'),
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
        </svg>
    ),
    route: '/questions',
});

function MenuItems(role: UserRoles) {
    const { t } = useTranslation();
    switch (role) {
        case UserRoles.ADMIN:
            return [
                bids(t),
                providers(t),
                users(t),
                {
                    label: t('common.administration'),
                    type: 'title',
                    route: '/',
                },
                lots(t),
                questions(t),
            ];
        case UserRoles.PROVIDER:
            return [bids(t)];
        case UserRoles.SHOPPING_LEADER:
            return [bids(t)];
        case UserRoles.USER:
            return [
                bids(t),
                {
                    label: t('common.administration'),
                    type: 'title',
                    route: '/',
                },
                lots(t),
            ];
        default:
            return [];
    }
}

export default MenuItems;
