/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, RootState } from '../../../../store';

import SideCreate from '../../../../components/elements/SideCreate';

import { updateProvider } from '../../../../modules/providers/features/providersSlice';
import { IProvider } from '../../../../modules/providers/interfaces';

type FormData = {
    name: string;
    description: string;
    groups: string[];
};

export default function EditProvider({
    show = false,
    toggle,
    provider,
}: {
    show: boolean;
    toggle: () => void;
    provider: IProvider;
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm<FormData>({
        defaultValues: {
            name: provider.name,
            description: provider.description,
            groups: provider.groups.map((group) => group.id),
        },
    });

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const providerGroups = useSelector(
        (state: RootState) => state.providerGroups
    );

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [newGroups, setNewGroups] = useState<string[]>([]);
    const [removeGroups, setRemoveGroups] = useState<string[]>([]);

    const groups = watch('groups');

    useEffect(() => {
        register('groups', { required: true });
    }, [register]);

    const onSubmit = async (data: FormData) => {
        try {
            setAddRequestStatus('loading');
            await dispatch(
                updateProvider({
                    id: provider.id,
                    data: {
                        name: data.name,
                        description: data.description,
                        newGroups,
                        removeGroups,
                    },
                })
            ).unwrap();
            setAddRequestStatus('success');
            reset();
            toggle();
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };

    const onHide = () => {
        setAddRequestStatus('idle');
        reset();
        toggle();
    };

    return (
        <SideCreate
            show={show}
            title={t('providers.edit.title')}
            toggle={onHide}
        >
            <div className="mb-20">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('common.name')}
                            <div className="mt-1">
                                <input
                                    disabled
                                    id="name"
                                    type="text"
                                    className={`${
                                        errors.name
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('name', {
                                        required: true,
                                    })}
                                />
                                {errors.name && (
                                    <p className="text-red-600 ml-2">
                                        {errors.name.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>
                    <div className="mt-4">
                        <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('common.description')}
                            <div className="mt-1">
                                <textarea
                                    id="description"
                                    className={`${
                                        errors.description
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('description')}
                                />
                            </div>
                        </label>
                    </div>
                    {/* START Groups */}
                    <div className="mt-4">
                        <p className="font-bold">{t('common.groups')}</p>
                        {providerGroups.status === 'loading' && (
                            <div className="mt-2">
                                <div className="w-full h-12 flex justify-center items-center">
                                    {t('common.loading')}
                                </div>
                            </div>
                        )}
                        {providerGroups &&
                            providerGroups.status === 'success' &&
                            providerGroups.data.map((group) => (
                                <div key={group.id} className="flex items-end">
                                    <div className="mt-1 mr-2">
                                        <input
                                            id={`groups[${group.id}]`}
                                            name={`groups[${group.id}]`}
                                            checked={groups.includes(group.id)}
                                            onChange={() => {
                                                const exist = groups.findIndex(
                                                    (g) => g === group.id
                                                );
                                                if (exist !== -1) {
                                                    const addGroups =
                                                        groups.filter(
                                                            (g) =>
                                                                g !== group.id
                                                        );
                                                    setValue('groups', [
                                                        ...addGroups,
                                                    ]);
                                                    setRemoveGroups([
                                                        ...removeGroups,
                                                        group.id,
                                                    ]);
                                                } else {
                                                    setValue('groups', [
                                                        ...(groups ?? []),
                                                        group.id,
                                                    ]);
                                                    setNewGroups([
                                                        ...newGroups,
                                                        group.id,
                                                    ]);
                                                }
                                            }}
                                            type="checkBox"
                                            className={`${
                                                errors.groups
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                        />
                                    </div>
                                    <label
                                        htmlFor={`groups[${group.id}]`}
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {group.name}
                                    </label>
                                </div>
                            ))}
                        {errors.groups && (
                            <p className="text-red-600 ml-2">
                                {t('common.errors.fieldRequired')}
                            </p>
                        )}
                    </div>

                    {/* END Groups */}

                    <div className="mt-4">
                        {/* {error && (
                                <p className="text-red-600 mb-2">
                                    {handleSignInError(error)}
                                </p>
                            )} */}
                        {addRequestStatus === 'success' && (
                            <p className="text-green-600 mb-2">
                                {t('providers.edit.success')}
                            </p>
                        )}
                        <button
                            disabled={addRequestStatus === 'loading'}
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                        >
                            {addRequestStatus === 'loading'
                                ? t('wait.message')
                                : t('common.save')}
                        </button>
                    </div>
                </form>
            </div>
        </SideCreate>
    );
}
