import axios from 'axios';
import { ChangeEvent, useRef, useState } from 'react';
import { t } from 'i18next';
import { useAppDispatch } from '../../../../../store';
import { createBidFiles } from '../../../../../modules/bids/features/singleBidSlice';
import { getSignedUrl } from '../../../../../services/api/upload';

const acceptedMimeTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv',
    'text/plain',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/x-tar',
    'application/x-gzip',
    'application/x-bzip2',
    'application/x-7z-compressed',
    'application/x-rar-compressed',
    'application/x-zip-compressed',
    'application/zip',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.graphics',
    'application/vnd.oasis.opendocument.chart',
    'application/vnd.oasis.opendocument.formula',
    'application/vnd.oasis.opendocument.database',
    'application/vnd.oasis.opendocument.image',
];

const s3Upload = async (file: File) => {
    try {
        const preSignedUrl = await getSignedUrl({
            file: {
                fileName: file.name,
                fileType: file.type,
            },
            type: 'upload',
        });
        const formData = new FormData();
        formData.append('Content-Type', file.type);
        formData.append('file', file);

        const response = await axios.put(preSignedUrl.signedUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });
        if (response.status === 200) {
            return {
                key: preSignedUrl.key,
                type: file.type,
                name: file.name,
            };
        }
        throw new Error('Upload failed');
    } catch (err) {
        throw new Error('Upload failed');
    }
};

export default function UploadFile({
    bidId,
    offerId,
}: {
    bidId: string;
    offerId: string;
}) {
    const dispatch = useAppDispatch();

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const [uploadFileState, setUploadFileState] = useState('idle');
    const [errorMessage, setErrorMessage] = useState('');

    const handleClick = () => {
        if (hiddenFileInput?.current) {
            hiddenFileInput.current.click();
            setUploadFileState('idle');
            setErrorMessage('');
        }
    };

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const { files } = e.target;
        const fileArr = Array.from(files);

        try {
            setUploadFileState('loading');
            const uploadPromises = fileArr.map((f) => s3Upload(f));

            const uploads = await Promise.all(uploadPromises);

            const upFiles = uploads.map((u) => ({
                key: u.key,
                type: u.type,
                name: u.name,
                bidId,
                offerId,
            }));

            await dispatch(createBidFiles(upFiles)).unwrap();
            setUploadFileState('success');
        } catch (err) {
            setUploadFileState('error');
        }

        // const reader = new FileReader();

        // reader.onload = (evt) => {
        //     if (!evt.target) {
        //         return;
        //     }

        //     const bstr = evt.target.result;
        // };
        // reader.readAsBinaryString(file);
    };
    return (
        <div className="text-black mt-10">
            <h3 className="mb-4 text-xl">
                {t('bids.contract.uploadFiles.title')}
            </h3>
            <button
                type="button"
                disabled={uploadFileState === 'loading'}
                className="flex w-full h-full items-center justify-center -mt-1"
                onClick={handleClick}
            >
                <label
                    htmlFor="file"
                    className={`w-full flex items-center px-4 py-1 space-x-4  rounded-lg tracking-wide uppercase border border-blue hover:bg-blue  ${
                        uploadFileState === 'loading'
                            ? 'bg-slate-500 text-white hover:text-gray-100 cursor-progress'
                            : 'bg-white text-sym-primary-200 hover:text-sym-primary-600'
                    }`}
                >
                    <svg
                        className="w-8 h-8"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="text-base leading-normal">
                        {uploadFileState === 'loading'
                            ? t('wait.message')
                            : t('common.selectFiles')}
                    </span>
                    <input
                        name="file"
                        type="file"
                        ref={hiddenFileInput}
                        className="hidden"
                        onChange={onChange}
                        multiple
                        accept={acceptedMimeTypes.concat().join(',')}
                    />
                </label>
            </button>
            {uploadFileState === 'loading' && (
                <div className="bg-orange-800 text-orange-100 font-bold text-center mt-2 p-1">
                    {t('bids.positions.fileUploading')}
                </div>
            )}
            {uploadFileState === 'success' && (
                <div className="bg-green-800 text-green-100 font-bold text-center mt-2 p-1">
                    {t('bids.positions.fileProcessed')}
                </div>
            )}
            {uploadFileState === 'error' && (
                <div className="bg-red-800 text-red-100 font-bold text-center mt-2 p-1">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}
