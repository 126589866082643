import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ILotPosition } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface LotPositionState {
    data: ILotPosition[];
    status: Status;
    error: string | null;
}

const initialState: LotPositionState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchLotPositions = createAsyncThunk(
    'lotPositions/fetchLotPositions',
    async () => {
        const response = await APIClient.getData('/lots/positions');
        return response.data;
    }
);

export const addNewLotPosition = createAsyncThunk(
    'lotPositions/addNewLot',
    async (data: ILotPosition) => {
        const response = await APIClient.postData('/lots/position', data);
        return response.data;
    }
);

export const deleteLotPosition = createAsyncThunk(
    'lotPositions/deleteLot',
    async (id: string) => {
        const response = await APIClient.deleteData(`/lots/position/${id}`);
        return response.data;
    }
);

export const lotPositionsSlice = createSlice({
    name: 'lotPositions',
    initialState,
    reducers: {
        removeLotPosition: (state, action) => {
            state.data = state.data.filter(
                (lotPosition) => lotPosition.id !== action.payload
            );
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLotPositions.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchLotPositions.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchLotPositions.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            });
        // .addCase(addNewLotPosition.fulfilled, (state, action) => {
        //     state.data.push(action.payload);
        // });
    },
});

export const { removeLotPosition } = lotPositionsSlice.actions;
