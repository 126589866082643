export function groupByCode(data) {
    const group = data.reduce((acc, value) => {
        // Group initialization
        if (!acc[value.code]) {
            // eslint-disable-next-line no-param-reassign
            acc[value.code] = [];
        }

        // Grouping
        acc[value.code].push(value);

        return acc;
    }, {});
    return group;
}
