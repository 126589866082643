import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../components/elements/Buttons';
import Modal from '../../../../../components/Modal';

import { RootState, useAppDispatch } from '../../../../../store';

import { IBid } from '../../../../../modules/bids/interfaces';
import { fetchProviderGroups } from '../../../../../modules/providers/features/providerGroupsSlice';
import AutoComplete from '../../../../../components/Autocomplete';
import { IProviderGroup } from '../../../../../modules/providers/interfaces';
import {
    deleteBidProvGroup,
    fetchSingleBid,
    updateBid,
    updateBidProvGroup,
    updateBidStage,
} from '../../../../../modules/bids/features/singleBidSlice';
import RoleCheck from '../../../../../components/RoleCheck';
import { UserRoles } from '../../../../../modules/users/enums';
import useAuth from '../../../../../context/useAuth';
import { BidStages, BidStatus } from '../../../../../modules/bids/enums';
import EditBid from './Edit';
import { dateOptions } from '../../../../../utils/helpers';
import { resetBidStatus } from '../../../../../modules/bids/features/bidsSlice';

function AutoCompleteItem({
    item,
    onClick,
}: {
    item: IProviderGroup;
    onClick: () => void;
}) {
    return (
        <button
            type="button"
            className="text-left hover:bg-slate-200 flex-col gap-4 p-4 w-full"
            onClick={onClick}
        >
            <p>{item.name}</p>
            <p className="text-xs">
                {item.providers
                    ?.map((provider) => provider.name)
                    .sort((a, b) => a.localeCompare(b))
                    .join(', ')}
            </p>
        </button>
    );
}

export default function SingleHeader({ bid }: { bid: IBid }) {
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const [selectedGroups, setSelectedGroups] = useState<IProviderGroup[]>([]);
    const [availableGroups, setAvailableGroups] = useState<IProviderGroup[]>(
        []
    );

    const [addGroupState, setAddGroupState] = useState('idle');
    const [deleteGroupState, setDeleteGroupState] = useState('loading');
    const [notMoreGroups, setNotMoreGroups] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openForceModal, setOpenForceModal] = useState(false);
    const [deleteBidState, setDeleteBidState] = useState('idle');

    const providerGroups = useSelector(
        (state: RootState) => state.providerGroups
    );

    const [updateState, setUpdateState] = useState('idle');
    const [reviewState, setReviewState] = useState('idle');
    const [openReturnDefinitionModal, setOpenReturnDefinitionModal] =
        useState(false);

    let locale = 'en-US';

    if (i18n.language === 'es') {
        locale = 'es-ES';
    }

    useEffect(() => {
        if (providerGroups.status === 'idle') {
            dispatch(fetchProviderGroups());
        }
    }, [providerGroups, dispatch]);

    useEffect(() => {
        if (
            providerGroups.status === 'success' &&
            providerGroups.data.length > 0 &&
            availableGroups.length === 0 &&
            !notMoreGroups
        ) {
            // Filter all providerGroups that are not in the bid providerGroups
            const newAvailableGroups = providerGroups.data.filter(
                (group) =>
                    !bid.providerGroups.some(
                        (bidGroup) => bidGroup.id === group.id
                    )
            );
            if (newAvailableGroups.length === 0) {
                setNotMoreGroups(true);
            }

            setAvailableGroups(newAvailableGroups);
        }
    }, [providerGroups, bid, availableGroups, notMoreGroups]);

    const onClose = () => {
        setOpenModal(false);
        setSelectedGroups([]);
    };

    const addGroup = async () => {
        if (selectedGroups.length === 0) {
            return;
        }
        try {
            setAddGroupState('loading');
            const groups = selectedGroups.map((g) => g.id);
            await dispatch(
                updateBidProvGroup({ id: bid.id || '', groups })
            ).unwrap();
            await dispatch(fetchSingleBid(bid.id || '')).unwrap();
            setAddGroupState('success');
        } catch (e) {
            setAddGroupState('error');
        }
    };

    const deleteGroup = async (id: string) => {
        if (!id) {
            return;
        }
        try {
            setDeleteGroupState(`loading-${id}`);
            await dispatch(
                deleteBidProvGroup({ id: bid.id || '', groups: [id] })
            ).unwrap();
            await dispatch(fetchSingleBid(bid.id || '')).unwrap();
            setDeleteGroupState(`success-${id}`);
        } catch (e) {
            setDeleteGroupState(`error-${id}`);
        }
    };

    const deleteBid = async () => {
        try {
            setDeleteBidState('loading');
            await dispatch(
                updateBid({
                    id: bid.id || '',
                    data: {
                        status: BidStatus.DELETED,
                        currentStage: BidStages.DELETED,
                    },
                })
            ).unwrap();
            await dispatch(fetchSingleBid(bid.id || '')).unwrap();
            dispatch(resetBidStatus());
            setDeleteBidState('success');
        } catch (e) {
            setDeleteBidState('error');
        }
    };

    const forceNextStage = async () => {
        if (!bid.id) {
            return;
        }

        try {
            setUpdateState('loading');

            await dispatch(
                updateBidStage({ id: bid.id, back: false })
            ).unwrap();
            await dispatch(
                updateBid({ data: { status: BidStatus.ACTIVE }, id: bid.id })
            ).unwrap();
            dispatch(resetBidStatus());
            await dispatch(fetchSingleBid(bid.id)).unwrap();
            setUpdateState('success');
        } catch (e) {
            setUpdateState('error');
        }
    };

    const goBacktoReview = async () => {
        if (!bid.id) {
            return;
        }
        try {
            setReviewState('loading');
            await dispatch(
                updateBidStage({
                    id: bid.id,
                    back: true,
                })
            ).unwrap();
            dispatch(resetBidStatus());
            setReviewState('success');
            await dispatch(fetchSingleBid(bid.id)).unwrap();
        } catch (e) {
            setReviewState('error');
        }
    };

    return (
        <>
            <EditBid show={showEdit} toggle={() => setShowEdit(!showEdit)} />
            <div
                className={`relative py-2 text-slate-800 rounded-md ${
                    bid.status === BidStatus.DELETED
                        ? 'bg-red-300'
                        : 'bg-slate-300'
                }`}
            >
                <h2 className="text-black font-bold text-xl ml-4">
                    {bid.name} - {t('common.branch')} {bid.branch.name}{' '}
                    {bid.status === BidStatus.DELETED && (
                        <span> ({t('bids.actions.deleteBid.eliminated')})</span>
                    )}
                </h2>
                <hr className="my-2" />
                <div className="px-4 flex space-x-4">
                    <p>
                        {t('lots.title')} : {bid.totalLots || 0}
                    </p>
                    <p>
                        {t('lots.lotVolume')}: {bid.totalVolume || 0} Ton
                    </p>
                    <p>
                        {t('common.startDate')} :{' '}
                        {new Date(bid.startDate).toLocaleDateString(
                            locale,
                            dateOptions
                        )}
                    </p>
                    <p>
                        {t('common.port')} : {bid.port?.name}
                    </p>
                </div>
                {bid.status !== BidStatus.DELETED && (
                    <RoleCheck
                        allowedRoles={[
                            UserRoles.ADMIN,
                            UserRoles.SHOPPING_LEADER,
                        ]}
                        currentRole={user?.role || 'USER'}
                    >
                        <div className="absolute top-2 right-4 flex space-x-4 items-center">
                            {(bid.currentStage === BidStages.DEFINITION ||
                                bid.currentStage ===
                                    BidStages.PREQUALIFICATION) && (
                                <Button
                                    type="button"
                                    variant="danger"
                                    size="sm"
                                    onClick={() => setOpenDeleteModal(true)}
                                    label={
                                        deleteBidState === 'loading'
                                            ? t('wait.message')
                                            : t('bids.actions.deleteBid.title')
                                    }
                                />
                            )}
                            {bid.currentStage === BidStages.BID && (
                                <RoleCheck
                                    allowedRoles={[
                                        UserRoles.ADMIN,
                                        UserRoles.SHOPPING_LEADER,
                                    ]}
                                    currentRole={user?.role}
                                >
                                    {/* SHOW CONFIRMED LOTS MESSAGE */}

                                    <div className="my-4 text-center">
                                        <Button
                                            size="sm"
                                            disabled={updateState === 'loading'}
                                            type="button"
                                            className="bg-red-500"
                                            label={
                                                updateState === 'loading'
                                                    ? t('wait.message')
                                                    : 'Forzar avance'
                                            }
                                            onClick={() =>
                                                setOpenForceModal(true)
                                            }
                                        />
                                    </div>
                                </RoleCheck>
                            )}
                            {bid.currentStage === BidStages.REVIEW && (
                                <RoleCheck
                                    allowedRoles={[
                                        UserRoles.ADMIN,
                                        UserRoles.SHOPPING_LEADER,
                                    ]}
                                    currentRole={user?.role}
                                >
                                    {/* SHOW CONFIRMED LOTS MESSAGE */}

                                    <div className="my-4 text-center">
                                        <Button
                                            onClick={() =>
                                                setOpenReturnDefinitionModal(
                                                    true
                                                )
                                            }
                                            label={
                                                reviewState === 'loading'
                                                    ? t('wait.message')
                                                    : t(
                                                          'bids.actions.returnButton'
                                                      )
                                                // action: t(
                                                //     'bids.actions.reviewLots.send'
                                                // ),
                                            }
                                            type="button"
                                            variant="danger"
                                        />
                                    </div>
                                </RoleCheck>
                            )}
                            {(bid.currentStage === BidStages.DEFINITION ||
                                bid.currentStage === BidStages.BID) && (
                                <Button
                                    type="button"
                                    variant="success"
                                    className="h-10"
                                    size="sm"
                                    onClick={() => setShowEdit(!showEdit)}
                                    label={t('common.edit')}
                                />
                            )}
                        </div>
                    </RoleCheck>
                )}
            </div>
            {bid.status !== BidStatus.DELETED && (
                <RoleCheck
                    allowedRoles={[UserRoles.ADMIN, UserRoles.SHOPPING_LEADER]}
                    currentRole={user?.role || 'USER'}
                >
                    <div className="bg-slate-300 py-2 text-slate-800 rounded-md mt-4 relative h-16">
                        <div className="px-4">
                            <div className="flex">
                                Grupos de Proveedores:{' '}
                                {bid.providerGroups.length === 0 && (
                                    <div className="text-red-500 ml-4">
                                        Debes asignar al menos un proveedor
                                    </div>
                                )}
                                {bid.providerGroups.length > 0 &&
                                    bid.providerGroups.map((pg) => (
                                        <div
                                            key={pg.id}
                                            className="cursor-default"
                                        >
                                            <div className="flex justify-between space-x-2  items-center text-sm bg-slate-100 rounded-md py-1 px-2 ml-2">
                                                <span>
                                                    {deleteGroupState ===
                                                    `loading-${pg.id}`
                                                        ? t('wait.message')
                                                        : pg.name}
                                                </span>
                                                {bid.currentStage ===
                                                    BidStages.DEFINITION && (
                                                    <button
                                                        disabled={
                                                            deleteGroupState ===
                                                            `loading-${pg.id}`
                                                        }
                                                        type="button"
                                                        onClick={() =>
                                                            deleteGroup(pg.id)
                                                        }
                                                    >
                                                        {deleteGroupState ===
                                                        `loading-${pg.id}` ? (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4 w-4"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-4 w-4"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                    d="M6 18L18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="flex flex-col absolute right-0 top-0 mt-2 mr-2 justify-end">
                            {availableGroups.length > 0 &&
                                bid.currentStage === BidStages.DEFINITION && (
                                    <Button
                                        size="sm"
                                        variant="success"
                                        label={t('common.add')}
                                        onClick={() => setOpenModal(true)}
                                    />
                                )}
                            {addGroupState === 'success' && (
                                <p className="font-bold text-green-900">
                                    {t('bids.actions.addGroup.success')}
                                </p>
                            )}
                            {addGroupState === 'error' && (
                                <p className="font-bold text-red-600">
                                    {t('bids.actions.addGroup.error')}
                                </p>
                            )}
                            {addGroupState === 'loading' && (
                                <p className="font-bold">{t('wait.message')}</p>
                            )}
                        </div>
                    </div>
                </RoleCheck>
            )}
            {openModal && (
                <Modal
                    className="w-[500px] h-[450px]"
                    setShowModal={setOpenModal}
                    onClose={onClose}
                >
                    <div className="w-full px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('bids.actions.addGroup.title')}
                        </h3>
                        {providerGroups.status === 'success' &&
                            availableGroups.length > 0 && (
                                <div>
                                    <AutoComplete
                                        placeholder={t('common.search')}
                                        getItems={({
                                            query,
                                        }: {
                                            query: string;
                                        }) => {
                                            const items = availableGroups
                                                .filter((group) =>
                                                    group.name
                                                        .toLowerCase()
                                                        .includes(
                                                            query.toLowerCase()
                                                        )
                                                )
                                                .sort((a, b) =>
                                                    a.name.localeCompare(b.name)
                                                );
                                            if (!items) {
                                                return [];
                                            }
                                            return items;
                                        }}
                                        sourceId="groups"
                                        onClick={(id: string) => {
                                            const exist = selectedGroups.find(
                                                (sg) => sg.id === id
                                            );
                                            if (exist) {
                                                return;
                                            }

                                            const newGroup =
                                                providerGroups.data.find(
                                                    (pg) => pg.id === id
                                                );

                                            if (!newGroup) {
                                                return;
                                            }

                                            setSelectedGroups([
                                                ...selectedGroups,
                                                newGroup,
                                            ]);
                                        }}
                                        Item={AutoCompleteItem}
                                    />
                                </div>
                            )}
                        {selectedGroups.length > 0 && (
                            <div className="overflow-y-auto h-40">
                                <h3>Grupos seleccionados:</h3>
                                <ul className="divide-y">
                                    {selectedGroups.map((sg) => (
                                        <li
                                            key={sg.id}
                                            className="flex flex-col py-2 relative"
                                        >
                                            {sg.name}
                                            <div className="text-xs">
                                                {sg.providers
                                                    ?.map(
                                                        (
                                                            provider: IProviderGroup
                                                        ) => provider.name
                                                    )
                                                    .join(', ')}
                                            </div>
                                            <button
                                                className="absolute top-1/2 right-0"
                                                type="button"
                                                onClick={() => {
                                                    const newGroup =
                                                        selectedGroups.filter(
                                                            (g) =>
                                                                g.id !== sg.id
                                                        );
                                                    setSelectedGroups([
                                                        ...newGroup,
                                                    ]);
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className="mt-10 flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="link"
                                label={t('common.cancel')}
                                onClick={onClose}
                            />
                            <Button
                                type="button"
                                variant="success"
                                label={t('common.add')}
                                onClick={() => {
                                    setOpenModal(false);
                                    addGroup();
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {/* MODAL TO DELETE BID */}
            {openDeleteModal && (
                <Modal setShowModal={setOpenDeleteModal}>
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('bids.actions.areYouSure')}
                        </h3>
                        <div>
                            <p>{t('bids.actions.deleteBid.message')}</p>
                        </div>
                        <div className="mt-10 flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="link"
                                label={t('common.cancel')}
                                onClick={() => setOpenDeleteModal(false)}
                            />
                            <Button
                                type="button"
                                variant="success"
                                label={t('common.confirm')}
                                onClick={() => {
                                    setOpenDeleteModal(false);
                                    deleteBid();
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {openForceModal && (
                <Modal setShowModal={setOpenForceModal}>
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('bids.actions.forceStage.title')}
                        </h3>
                        <div>
                            <p>{t('bids.actions.forceStage.message')}</p>
                        </div>
                        <div className="mt-10 flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="link"
                                label={t('common.cancel')}
                                onClick={() => setOpenForceModal(false)}
                            />
                            <Button
                                type="button"
                                variant="success"
                                label={t('common.confirm')}
                                onClick={() => {
                                    setOpenForceModal(false);
                                    forceNextStage();
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}

            {/* MODAL TO RETURN DEFINITION */}
            {openReturnDefinitionModal && (
                <Modal setShowModal={setOpenReturnDefinitionModal}>
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('bids.actions.areYouSure')}
                        </h3>
                        <div>
                            <p>{t('bids.actions.returnConfirmDialog')}</p>
                        </div>
                        <div className="mt-10 flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="link"
                                label={t('common.cancel')}
                                onClick={() =>
                                    setOpenReturnDefinitionModal(false)
                                }
                            />
                            <Button
                                type="button"
                                variant="success"
                                label={t('common.confirm')}
                                onClick={() => {
                                    setOpenReturnDefinitionModal(false);
                                    goBacktoReview();
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}
