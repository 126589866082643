/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useMemo } from 'react';

import { Column, useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store';

import { ILotOffer } from '../../../../modules/bids/interfaces';

export default function OfferLotPositionList({
    lotPositions,
    type,
    volumes,
}: {
    lotPositions: ILotOffer[] | [];
    type: string;

    volumes: { id: string; lotPositionId: string; value: string }[];
    buyLotId: string;
}) {
    const { t } = useTranslation();

    const lots = useSelector((state: RootState) => state.lots);

    const columnsRolledSteel = useMemo<Column<ILotOffer>[]>(
        () => [
            {
                Header: t('common.details').toString(),
                columns: [
                    {
                        Header: t('lots.position.thickness').toString(),
                        accessor: 'thickness',
                    },
                    {
                        Header: t(
                            'lots.position.thicknessToleranceLess'
                        ).toString(),
                        accessor: 'thicknessToleranceLess',
                    },
                    {
                        Header: t(
                            'lots.position.thicknessToleranceOver'
                        ).toString(),
                        accessor: 'thicknessToleranceOver',
                    },
                    {
                        Header: t('lots.position.width').toString(),
                        accessor: 'width',
                    },
                    {
                        Header: t(
                            'lots.position.ssDesignationCoating'
                        ).toString(),
                        accessor: 'ssDesignationCoating',
                    },
                ],
            },

            {
                Header: t('lots.position.volume').toString(),
                columns: [
                    {
                        Header: 'Required',
                        accessor: 'volume',
                        disableSortBy: true,
                    },
                    {
                        Header: 'Offered',
                        accessor: 'offeredVolume',
                        disableSortBy: true,
                    },
                    {
                        Header: t('lots.unit').toString(),
                        accessor: 'lotId',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ cell: { value } }: any) => {
                            const lot = lots.data.find((l) => l.id === value);
                            return lot?.metric?.nameEn || '-';
                        },
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.price').toString(),
                columns: [
                    {
                        Header: 'USD',
                        accessor: 'price',
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.observations').toString(),
                columns: [
                    {
                        Header: '',
                        accessor: 'comment',
                        disableSortBy: true,
                    },
                ],
            },
        ],

        [t, volumes]
    );
    const columnsPaintedRolledSteel = useMemo<Column<ILotOffer>[]>(
        () => [
            {
                Header: t('common.details').toString(),
                columns: [
                    {
                        Header: t('lots.position.branchCode').toString(),
                        accessor: 'branchCode',
                    },
                    {
                        Header: t('common.description').toString(),
                        accessor: 'descriptionEn',
                    },

                    {
                        Header: t('lots.position.thickness').toString(),
                        accessor: 'thickness',
                    },
                    {
                        Header: t(
                            'lots.position.thicknessToleranceLess'
                        ).toString(),
                        accessor: 'thicknessToleranceLess',
                    },
                    {
                        Header: t(
                            'lots.position.thicknessToleranceOver'
                        ).toString(),
                        accessor: 'thicknessToleranceOver',
                    },
                    {
                        Header: t('lots.position.width').toString(),
                        accessor: 'width',
                    },
                    {
                        Header: t('lots.position.color').toString(),
                        accessor: 'colorId',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ cell }: any) =>
                            cell.row.original?.lotColor?.code ?? '--',
                    },
                    {
                        Header: t('lots.position.backSide').toString(),
                        accessor: 'backSide',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ cell }: any) =>
                            cell.row.original?.backSideColor?.code ?? '--',
                    },
                ],
            },

            {
                Header: t('lots.position.volume').toString(),
                columns: [
                    {
                        Header: 'Required',
                        accessor: 'volume',
                        disableSortBy: true,
                    },
                    {
                        Header: 'Offered',
                        accessor: 'offeredVolume',
                        disableSortBy: true,
                    },
                    {
                        Header: t('lots.unit').toString(),
                        accessor: 'lotId',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ cell: { value } }: any) => {
                            const lot = lots.data.find((l) => l.id === value);
                            return lot?.metric?.nameEn || '-';
                        },
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.price').toString(),
                columns: [
                    {
                        Header: 'USD',
                        accessor: 'price',
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.observations').toString(),
                columns: [
                    {
                        Header: '',
                        accessor: 'comment',
                        disableSortBy: true,
                    },
                ],
            },
        ],

        [t, volumes]
    );

    const columnsPlates = useMemo<Column<ILotOffer>[]>(
        () => [
            {
                Header: t('common.details').toString(),
                columns: [
                    {
                        Header: t('lots.position.branchCode').toString(),
                        accessor: 'branchCode',
                    },
                    {
                        Header: t('common.description').toString(),
                        accessor: 'descriptionEn',
                    },

                    {
                        Header: t('lots.position.thickness').toString(),
                        accessor: 'thickness',
                    },
                    {
                        Header: t(
                            'lots.position.thicknessToleranceLess'
                        ).toString(),
                        accessor: 'thicknessToleranceLess',
                    },
                    {
                        Header: t(
                            'lots.position.thicknessToleranceOver'
                        ).toString(),
                        accessor: 'thicknessToleranceOver',
                    },
                    {
                        Header: t('lots.position.length').toString(),
                        accessor: 'length',
                    },
                    {
                        Header: t('lots.position.width').toString(),
                        accessor: 'width',
                    },
                ],
            },

            {
                Header: t('lots.position.volume').toString(),
                columns: [
                    {
                        Header: 'Required',
                        accessor: 'volume',
                        disableSortBy: true,
                    },
                    {
                        Header: 'Offered',
                        accessor: 'offeredVolume',
                        disableSortBy: true,
                    },
                    {
                        Header: t('lots.unit').toString(),
                        accessor: 'lotId',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ cell: { value } }: any) => {
                            const lot = lots.data.find((l) => l.id === value);
                            return lot?.metric?.nameEn || '-';
                        },
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.price').toString(),
                columns: [
                    {
                        Header: 'USD',
                        accessor: 'price',
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.observations').toString(),
                columns: [
                    {
                        Header: '',
                        accessor: 'comment',
                        disableSortBy: true,
                    },
                ],
            },
        ],

        [t, volumes]
    );

    const columnsPipe = useMemo<Column<ILotOffer>[]>(
        () => [
            {
                Header: t('common.details').toString(),
                columns: [
                    {
                        Header: t('lots.position.branchCode').toString(),
                        accessor: 'branchCode',
                    },
                    {
                        Header: t('common.description').toString(),
                        accessor: 'descriptionEn',
                    },

                    {
                        Header: t('lots.position.thickness').toString(),
                        accessor: 'thickness',
                    },
                    {
                        Header: t('lots.position.length').toString(),
                        accessor: 'length',
                    },
                    {
                        Header: t('lots.position.diameter').toString(),
                        accessor: 'diameter',
                    },
                    {
                        Header: t('lots.position.diameterUnit').toString(),
                        accessor: 'diameterUnit',
                    },
                    {
                        Header: t('lots.position.kgMt').toString(),
                        accessor: 'kgMt',
                    },
                ],
            },

            {
                Header: t('lots.position.volume').toString(),
                columns: [
                    {
                        Header: 'Required',
                        accessor: 'volume',
                        disableSortBy: true,
                    },
                    {
                        Header: 'Offered',
                        accessor: 'offeredVolume',
                        disableSortBy: true,
                    },
                    {
                        Header: t('lots.unit').toString(),
                        accessor: 'lotId',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ cell: { value } }: any) => {
                            const lot = lots.data.find((l) => l.id === value);
                            return lot?.metric?.nameEn || '-';
                        },
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.price').toString(),
                columns: [
                    {
                        Header: 'USD',
                        accessor: 'price',
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.observations').toString(),
                columns: [
                    {
                        Header: '',
                        accessor: 'comment',
                        disableSortBy: true,
                    },
                ],
            },
        ],

        [t, volumes]
    );

    const columnsBarAngle = useMemo<Column<ILotOffer>[]>(
        () => [
            {
                Header: t('common.details').toString(),
                columns: [
                    {
                        Header: t('lots.position.branchCode').toString(),
                        accessor: 'branchCode',
                    },
                    {
                        Header: t('common.description').toString(),
                        accessor: 'descriptionEn',
                    },

                    {
                        Header: t('lots.position.thickness').toString(),
                        accessor: 'thickness',
                    },
                    {
                        Header: t('lots.position.length').toString(),
                        accessor: 'length',
                    },
                    {
                        Header: t('lots.position.width').toString(),
                        accessor: 'width',
                    },
                    {
                        Header: t('lots.position.height').toString(),
                        accessor: 'height',
                    },
                    {
                        Header: t('lots.position.diameter').toString(),
                        accessor: 'diameter',
                    },
                    {
                        Header: t('lots.position.diameterUnit').toString(),
                        accessor: 'diameterUnit',
                    },
                    {
                        Header: t('lots.position.kgMt').toString(),
                        accessor: 'kgMt',
                    },
                ],
            },

            {
                Header: t('lots.position.volume').toString(),
                columns: [
                    {
                        Header: 'Required',
                        accessor: 'volume',
                        disableSortBy: true,
                    },
                    {
                        Header: 'Offered',
                        accessor: 'offeredVolume',
                        disableSortBy: true,
                    },
                    {
                        Header: t('lots.unit').toString(),
                        accessor: 'lotId',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ cell: { value } }: any) => {
                            const lot = lots.data.find((l) => l.id === value);
                            return lot?.metric?.nameEn || '-';
                        },
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.price').toString(),
                columns: [
                    {
                        Header: 'USD',
                        accessor: 'price',
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.observations').toString(),
                columns: [
                    {
                        Header: '',
                        accessor: 'comment',
                        disableSortBy: true,
                    },
                ],
            },
        ],

        [t, volumes]
    );

    const columnsTube = useMemo<Column<ILotOffer>[]>(
        () => [
            {
                Header: t('common.details').toString(),
                columns: [
                    {
                        Header: t('lots.position.branchCode').toString(),
                        accessor: 'branchCode',
                    },
                    {
                        Header: t('common.description').toString(),
                        accessor: 'descriptionEn',
                    },

                    {
                        Header: t('lots.position.thickness').toString(),
                        accessor: 'thickness',
                    },
                    {
                        Header: t('lots.position.length').toString(),
                        accessor: 'length',
                    },
                    {
                        Header: t('lots.position.width').toString(),
                        accessor: 'width',
                    },
                    {
                        Header: t('lots.position.height').toString(),
                        accessor: 'height',
                    },
                    {
                        Header: t('lots.position.kgMt').toString(),
                        accessor: 'kgMt',
                    },
                ],
            },

            {
                Header: t('lots.position.volume').toString(),
                columns: [
                    {
                        Header: 'Required',
                        accessor: 'volume',
                        disableSortBy: true,
                    },
                    {
                        Header: 'Offered',
                        accessor: 'offeredVolume',
                        disableSortBy: true,
                    },
                    {
                        Header: t('lots.unit').toString(),
                        accessor: 'lotId',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ cell: { value } }: any) => {
                            const lot = lots.data.find((l) => l.id === value);
                            return lot?.metric?.nameEn || '-';
                        },
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.price').toString(),
                columns: [
                    {
                        Header: 'USD',
                        accessor: 'price',
                        disableSortBy: true,
                    },
                ],
            },
            {
                Header: t('common.observations').toString(),
                columns: [
                    {
                        Header: '',
                        accessor: 'comment',
                        disableSortBy: true,
                    },
                ],
            },
        ],

        [t, volumes]
    );

    const getColumns = (lotType: string): Column<ILotOffer>[] => {
        switch (lotType) {
            case 'Rolled Steel':
                return columnsRolledSteel;
            case 'Pipe':
                return columnsPipe;
            case 'Bar/Angle':
                return columnsBarAngle;
            case 'Tube':
                return columnsTube;
            case 'Plates':
                return columnsPlates;
            case 'Painted Rolled Steel':
                return columnsPaintedRolledSteel;
            default:
                return columnsRolledSteel;
        }
    };

    const tableInstance = useTable(
        {
            columns: getColumns(type),
            data: lotPositions,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            // updateMyData,
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative">
            {lotPositions.length > 0 && (
                <div className="overflow-x-auto border-b border-slate-500">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className={`${
                                                            column.depth === 0
                                                                ? 'bg-slate-500 border-2 border-white rounded-lg'
                                                                : 'bg-white text-slate-500 border-r border-r-slate-500 last:border-r-0'
                                                        } px-2 py-2 my-2`}
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div
                                                            className={`flex ${
                                                                column.parent
                                                                    ? 'justify-between'
                                                                    : 'justify-center'
                                                            }`}
                                                        >
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-slate-200 hover:bg-slate-300"
                                            {...row.getRowProps()}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="text-center py-4 px-2 w-auto border-r border-r-slate-500 last:border-r-0"
                                                        // eslint-disable-next-line react/prop-types
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            // eslint-disable-next-line react/prop-types
                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
