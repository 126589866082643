/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, useTable, useSortBy } from 'react-table';
import { IReviewOffer } from './interfaces';
import { Button } from '../../../../../components/elements/Buttons';
import Modal from '../../../../../components/Modal';
import {
    IBuyLot,
    ILot,
    IOfferLot,
} from '../../../../../modules/bids/interfaces';

import { useAppDispatch } from '../../../../../store';

import ReviewOfferLotItem from '../ReviewOfferLotItem';
import { OfferLotStatus } from '../../../../../modules/bids/enums';
import { updateOfferLot } from '../../../../../modules/bids/features/offerLotsSlice';

import PriceBlock from './PriceBlock';

interface ISelectedOffer {
    lot: ILot;
    buyLot: IBuyLot;
}

export default function ReviewTable({
    reviewOfferLots,
    reload,
}: {
    reviewOfferLots: IReviewOffer[];
    reload: () => Promise<void>;
}) {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);

    const [selectedOffer, setSelectedOffer] = useState<ISelectedOffer | null>(
        null
    );

    const [updatedOffer, setUpdatedOffer] = useState<string | null>(null);
    const [updateOfferState, setUpdateOfferState] = useState('idle');
    const [undoOfferState, setUndoOfferState] = useState('idle');

    let locale = 'en-US';
    if (i18n.language === 'es') {
        locale = 'es-ES';
    }

    const changeStatusOffer = useCallback(
        async (
            offerId: string,
            status:
                | OfferLotStatus.APPROVED
                | OfferLotStatus.REJECTED
                | OfferLotStatus.CONFIRMED
        ) => {
            try {
                setUpdateOfferState('loading');
                await dispatch(
                    updateOfferLot({
                        id: offerId,
                        body: { offerLot: { status } },
                    })
                ).unwrap();

                await reload();
            } catch (e) {
                setUpdateOfferState('error');
            }
        },
        [dispatch, reload]
    );

    const columns = useMemo<Column<IReviewOffer>[]>(
        () => [
            {
                Header: t('lots.titleSingular').toString(),
                accessor: 'lotName',
            },
            {
                Header: t('providers.singleTitle').toString(),
                accessor: 'provider',
            },
            {
                Header: t('lots.offer.skuOffered').toString(),
                accessor: 'offers',
            },
            {
                Header: t('lots.offer.priceUSD').toString(),
                accessor: 'price',
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ cell: { value } }: any) => (
                    <PriceBlock value={value} />
                ),
            },
            {
                Header: t('lots.offer.paymentTerm').toString(),
                accessor: 'paymentTerms',
            },
            {
                Header: t('lots.offer.paymentForm').toString(),
                accessor: 'paymentForm',
            },
            {
                Header: t('lots.shipmentDate').toString(),
                accessor: 'shipmentDate',
                // eslint-disable-next-line react/prop-types
                Cell: ({ cell: { value } }) => {
                    if (value) {
                        return (
                            <span className="block text-right">
                                {new Date(value).toLocaleDateString(locale)}
                            </span>
                        );
                    }
                    return <span className="block text-center">--</span>;
                },
            },
            {
                Header: t('common.comments').toString(),
                accessor: 'comments',
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ cell: { row } }: any) => {
                    if (
                        row.original.offer.observationsPlain !== '' ||
                        !row.original.offer.positions.every(
                            (p: any) =>
                                p.observationsPlain === '' ||
                                p.observationsPlain === null
                        )
                    ) {
                        return (
                            <div className="flex space-x-2 text-orange-700 items-center">
                                <div>{t('common.yes').toString()} </div>
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        );
                    }
                    return t('common.no').toString();
                },
            },
            {
                Header: t('common.approve').toString(),
                id: 'actions',
                accessor: 'offer',
                disableSortBy: true,
                // eslint-disable-next-line react/no-unstable-nested-components

                Cell: ({
                    cell: { value },
                }: {
                    cell: { value: IOfferLot };
                }): JSX.Element => {
                    if (
                        updateOfferState === 'loading' &&
                        updatedOffer === value.id
                    ) {
                        return <div>{t('wait.message')}</div>;
                    }

                    if (value.status === OfferLotStatus.CONFIRMED) {
                        return (
                            <div className="flex space-x-6">
                                <div className="text-red-500">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setOpenRejectModal(true);
                                            // eslint-disable-next-line react/prop-types
                                            if (value.id) {
                                                setUpdatedOffer(value.id);
                                            }
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="text-green-700">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setOpenApproveModal(true);
                                            if (value.id) {
                                                setUpdatedOffer(value.id);
                                            }
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        );
                    }
                    if (value.status === OfferLotStatus.APPROVED) {
                        return (
                            <div className="flex space-x-6 items-center">
                                <div className="text-green-500">
                                    {t('common.approved')}
                                </div>
                                <div className="text-red-500">
                                    <Button
                                        type="button"
                                        variant="outline"
                                        size="sm"
                                        className="text-xs text-gray-500"
                                        onClick={async () => {
                                            if (value.id) {
                                                setUndoOfferState('loading');
                                                await changeStatusOffer(
                                                    value.id,
                                                    OfferLotStatus.CONFIRMED
                                                );
                                                setUndoOfferState('idle');
                                            }
                                        }}
                                        label={
                                            undoOfferState === 'loading'
                                                ? t('wait.message')
                                                : 'Deshacer aprobación'
                                        }
                                    />
                                </div>
                            </div>
                        );
                    }
                    if (value.status === OfferLotStatus.REJECTED) {
                        return (
                            <div className="flex space-x-6 items-center">
                                <div className="text-red-500">
                                    {t('common.rejected')}
                                </div>

                                <div className="text-red-500">
                                    <Button
                                        type="button"
                                        variant="outline"
                                        size="sm"
                                        className="text-xs text-gray-500"
                                        onClick={async () => {
                                            // eslint-disable-next-line react/prop-types
                                            if (value.id) {
                                                setUndoOfferState('loading');
                                                await changeStatusOffer(
                                                    value.id,
                                                    OfferLotStatus.CONFIRMED
                                                );
                                                setUndoOfferState('idle');
                                            }
                                        }}
                                        label={
                                            undoOfferState === 'loading'
                                                ? t('wait.message')
                                                : 'Deshacer rechazo'
                                        }
                                    />
                                </div>
                            </div>
                        );
                    }

                    return <div>Error</div>;
                },
            },
            {
                Header: '',
                accessor: 'lot',
                disableSortBy: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: (cell) => {
                    const { row } = cell;
                    const { lot, buyLot } = row.original;

                    return (
                        <button
                            type="button"
                            className="w-full"
                            onClick={() => {
                                setSelectedOffer({ lot, buyLot });
                                setOpenDetailModal(true);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mx-auto h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </button>
                    );
                },
            },
        ],
        [
            changeStatusOffer,
            locale,
            t,
            undoOfferState,
            updateOfferState,
            updatedOffer,
        ]
    );

    const tableInstance = useTable(
        {
            columns,
            data: reviewOfferLots,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative mb-32">
            {reviewOfferLots.length > 0 && (
                <div className="overflow-x-auto border-b border-slate-500">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className={`odd:bg-slate-200 hover:bg-slate-300 ${
                                                row.original.buyLot.id ===
                                                    selectedOffer?.buyLot.id &&
                                                'bg-green-300 odd:bg-green-300 hover:bg-green-500'
                                            }`}
                                            {...row.getRowProps()}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2"
                                                        // eslint-disable-next-line react/prop-types
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            // eslint-disable-next-line react/prop-types
                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
            {selectedOffer && openDetailModal && (
                <div className="relative bg-slate-100 px-6 pt-6 pb-16 my-10">
                    <h3 className="text-black text-xl underline">
                        Detalle oferta
                    </h3>

                    <Button
                        className="absolute right-4 top-4"
                        variant="outline"
                        onClick={() => {
                            setOpenDetailModal(false);
                            setSelectedOffer(null);
                        }}
                        label={t('common.close')}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        }
                    />
                    <ReviewOfferLotItem
                        id={selectedOffer.buyLot.id || ''}
                        status={selectedOffer.buyLot.status}
                        buyLot={selectedOffer.buyLot}
                        lot={selectedOffer.lot}
                    />
                    <Button
                        className="absolute right-4 bottom-4"
                        variant="outline"
                        onClick={() => {
                            setOpenDetailModal(false);
                            setSelectedOffer(null);
                        }}
                        label={t('common.close')}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        }
                    />
                </div>
            )}
            {/* MODAL TO APPROVE OFFER */}
            {openApproveModal && (
                <Modal setShowModal={setOpenApproveModal}>
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('bids.actions.areYouSure')}
                        </h3>
                        <div className="text-center">
                            <p>{t('lots.offer.approveConfirm')}</p>
                        </div>
                        <div className="mt-10 flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="link"
                                label={t('common.cancel')}
                                onClick={() => setOpenApproveModal(false)}
                            />
                            <Button
                                type="button"
                                variant="success"
                                label={t('common.confirm')}
                                onClick={() => {
                                    if (updatedOffer) {
                                        changeStatusOffer(
                                            updatedOffer,
                                            OfferLotStatus.APPROVED
                                        );
                                    }
                                    setOpenApproveModal(false);
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {/* MODAL OPEN REJECTED */}
            {openRejectModal && (
                <Modal setShowModal={setOpenRejectModal}>
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('bids.actions.areYouSure')}
                        </h3>
                        <div className="text-center">
                            <p>{t('lots.offer.rejectConfirm')}</p>
                        </div>
                        <div className="mt-10 flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="link"
                                label={t('common.cancel')}
                                onClick={() => setOpenRejectModal(false)}
                            />
                            <Button
                                type="button"
                                variant="success"
                                label={t('common.confirm')}
                                onClick={() => {
                                    if (updatedOffer) {
                                        changeStatusOffer(
                                            updatedOffer,
                                            OfferLotStatus.REJECTED
                                        );
                                    }
                                    setOpenRejectModal(false);
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
