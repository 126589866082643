import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ILotType } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ILotTypeState {
    types: ILotType[];
    status: Status;
    error: string | null;
}

const initialState: ILotTypeState = {
    types: [],
    status: Status.idle,
    error: null,
};

export const fetchLotTypes = createAsyncThunk(
    'lotTypes/fetchLotTypes',
    async () => {
        const response = await APIClient.getData('/lots/types');
        return response.data;
    }
);

export const lotTypesSlice = createSlice({
    name: 'lotTypes',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchLotTypes.pending, (state) => {
                state.types = [];
                state.status = Status.loading;
            })
            .addCase(fetchLotTypes.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                state.types = state.types.concat(action.payload);
            })
            .addCase(fetchLotTypes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            });
    },
});
