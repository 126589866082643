import { ChangeEvent, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { groupByCode } from '../../../helpers/groupBy';
import { RootState, useAppDispatch } from '../../../../../../store';
import {
    IBuyLotCreateAttr,
    ILot,
} from '../../../../../../modules/bids/interfaces';
import {
    addNewBuyLotBulk,
    setBuyLots,
} from '../../../../../../modules/bids/features/buyLotsSlice';
import { fetchSingleBid } from '../../../../../../modules/bids/features/singleBidSlice';

interface IFileType {
    code: string;
    lotPosition: number;
    volume: number;
}

const getPositions = (
    positions: IFileType[],
    lots: ILot[],
    bidId: string,
    count?: number
) => {
    let lot: ILot | undefined;

    const data = positions
        .map((d) => {
            lot = lots.find((l) => l.code === d.code);

            if (!lot) {
                return null;
            }

            // Find lot position
            const position = lot.positions?.find(
                (p) => p.position === d.lotPosition
            );

            if (!position) {
                return null;
            }

            if (typeof d.volume === 'string') {
                throw new Error('Volume is not a number');
            }
            if (!position.id || !d.volume) {
                return null;
            }

            if (d.volume <= 0) {
                return null;
            }

            return {
                lotPositionId: position.id,
                volume: d.volume.toString(),
            };
        })
        .filter((d) => d !== null);

    return {
        buyLot: {
            lotId: lot?.id || '',
            bidId,
        },
        buyPositions: data ?? [],
        file: true,
        count: count ?? 0,
    };
};

export default function ProcessFile({
    bidId,
    closeDialog,
}: {
    bidId: string;
    closeDialog: () => void;
}) {
    const dispatch = useAppDispatch();

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const [addBuyLotState, setAddBuyLotState] = useState('idle');
    const [errorMessage, setErrorMessage] = useState('');

    const lots = useSelector((state: RootState) => state.lots);

    const handleClick = () => {
        if (hiddenFileInput?.current) {
            hiddenFileInput.current.click();
            setAddBuyLotState('idle');
            setErrorMessage('');
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        if (
            file.type !==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            setErrorMessage(t('bids.actions.addLot.fileFormatError'));
            setAddBuyLotState('error');
            return;
        }
        const reader = new FileReader();

        reader.onload = async (evt) => {
            setAddBuyLotState('loading');
            dispatch(setBuyLots([]));
            if (!evt.target) {
                return;
            }

            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const dataJson: IFileType[] = XLSX.utils.sheet_to_json(ws, {
                header: [
                    'code',
                    'type',
                    'description',
                    'lotPosition', // OJO se cambio position por lotPosition
                    'length',
                    'width',
                    'height',
                    'diameter',
                    'diameterUnit',
                    'kgMt',
                    'color',
                    'backside',
                    'ssDesignationCoating',
                    'volume',
                    'thickness',
                    'thicknessToleranceLess',
                    'thicknessToleranceOver',
                    'metric',
                ],
            });

            if (
                dataJson[0].code !== 'code' ||
                Number.isNaN(dataJson[1].volume) ||
                Number.isNaN(dataJson[1].lotPosition) ||
                Object.keys(dataJson[0]).length !== 18
            ) {
                setErrorMessage(t('bids.actions.addLot.invalidFile'));
                setAddBuyLotState('error');
                return;
            }

            // check if there is a volume that is not a number
            const invalid = dataJson
                .map((d, index) => {
                    if (!d.volume && d.volume !== 0) {
                        return `Fila ${index + 1}, lote: ${d.code}, posición: ${
                            d.lotPosition
                        } - El volumen no puede estar vacío`;
                    }
                    if (typeof d.volume !== 'number' && d.volume !== 'volume') {
                        return `Fila ${index + 1}, lote: ${d.code}, posición: ${
                            d.lotPosition
                        } - El volumen debe ser un número`;
                    }
                    return true;
                })
                .filter((d) => d !== true);

            if (invalid.length > 0) {
                const message = invalid.join('\r\n');
                setErrorMessage(
                    message ?? t('bids.actions.addLot.invalidFieldsFile')
                );
                setAddBuyLotState('error');
                return;
            }

            const group = groupByCode(dataJson.splice(1));

            let allInvalids = true;

            const buyLotsBulk: IBuyLotCreateAttr[] = [];

            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.values(group).forEach(async (value: any) => {
                    const positions = getPositions(value, lots.data, bidId);
                    if (
                        !positions.buyLot.lotId ||
                        !positions.buyPositions ||
                        positions.buyPositions.length === 0
                    ) {
                        return;
                    }
                    allInvalids = false;

                    buyLotsBulk.push(positions);
                });
                if (allInvalids) {
                    setAddBuyLotState('error');
                    setErrorMessage(t('bids.actions.addLot.invalidFile'));
                }
                await dispatch(addNewBuyLotBulk(buyLotsBulk)).unwrap();
                setAddBuyLotState('success');

                await dispatch(fetchSingleBid(bidId)).unwrap();
                closeDialog();
            } catch (error) {
                setAddBuyLotState('error');
            }
        };
        reader.readAsBinaryString(file);
    };
    return (
        <div className="text-black">
            <button
                type="button"
                disabled={addBuyLotState === 'loading'}
                className="flex w-full h-full items-center justify-center -mt-1"
                onClick={handleClick}
            >
                <label
                    htmlFor="file"
                    className={`w-full flex items-center px-4 py-1 space-x-4  rounded-lg tracking-wide uppercase border border-blue hover:bg-blue  ${
                        addBuyLotState === 'loading'
                            ? 'bg-slate-500 text-white hover:text-gray-100 cursor-progress'
                            : 'bg-white text-sym-primary-200 hover:text-sym-primary-600'
                    }`}
                >
                    <svg
                        className="w-8 h-8"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="text-base leading-normal">
                        {addBuyLotState === 'loading'
                            ? t('wait.message')
                            : t('common.selectFile')}
                    </span>
                    <input
                        name="file"
                        type="file"
                        ref={hiddenFileInput}
                        className="hidden"
                        onChange={onChange}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                </label>
            </button>
            {addBuyLotState === 'loading' && (
                <div className="bg-orange-800 text-orange-100 font-bold text-center mt-2 p-1">
                    {t('bids.positions.fileUploading')}
                </div>
            )}
            {addBuyLotState === 'success' && (
                <div className="bg-green-800 text-green-100 font-bold text-center mt-2 p-1">
                    {t('bids.positions.fileProcessed')}
                </div>
            )}
            {addBuyLotState === 'error' && (
                <p className="bg-red-800 text-red-100 font-bold mt-2 p-2 whitespace-pre">
                    {errorMessage}
                </p>
            )}
        </div>
    );
}
