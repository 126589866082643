import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import {
    IProvider,
    IProviderCreateAttr,
    IProviderUpdateAttr,
} from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ProviderState {
    data: IProvider[];
    status: Status;
    error: string | null;
}

const initialState: ProviderState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchProviders = createAsyncThunk(
    'providers/fetchProviders',
    async () => {
        const response = await APIClient.getData('/providers');
        return response.data;
    }
);

export const addProvider = createAsyncThunk(
    'providers/addProvider',
    async (provider: IProviderCreateAttr) => {
        const response = await APIClient.postData('/providers', provider);
        return response.data;
    }
);

export const updateProvider = createAsyncThunk(
    'providers/updateProvider',
    async (provider: { id: string; data: IProviderUpdateAttr }) => {
        const response = await APIClient.putData(
            `/providers/${provider.id}`,
            provider.data
        );
        return response.data;
    }
);

export const providersSlice = createSlice({
    name: 'providers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchProviders.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchProviders.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array

                state.data = action.payload.sort((a: IProvider, b: IProvider) =>
                    a.name.localeCompare(b.name)
                );
            })
            .addCase(fetchProviders.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addProvider.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(addProvider.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateProvider.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any updated data to the array
                const current = state.data.findIndex(
                    (provider) => provider.id === action.payload.id
                );
                state.data[current] = action.payload;
            })
            .addCase(updateProvider.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
