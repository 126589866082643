import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { branchesSlice } from './modules/branches/features';
import { usersSlice, inviteUserSlice } from './modules/users/features';
import {
    bidsSlice,
    buyLotsSlice,
    buyPositionsSlice,
    lotMetricsSlice,
    lotsSlice,
    lotTypesSlice,
    lotColorsSlice,
    lotFamiliesSlice,
    lotPositionsSlice,
    singleBidSlice,
    offerLotsSlice,
    offerPositionsSlice,
    paymentFormSlice,
    paymentTermsSlice,
} from './modules/bids/features';

import { portsSlice } from './modules/ports/features/portSlice';

import {
    providersSlice,
    providerGroupsSlice,
} from './modules/providers/features';

import { questionsSlice } from './modules/questions/features';

export const store = configureStore({
    reducer: {
        bids: bidsSlice.reducer,
        buyLots: buyLotsSlice.reducer,
        branches: branchesSlice.reducer,
        lotMetrics: lotMetricsSlice.reducer,
        lots: lotsSlice.reducer,
        lotTypes: lotTypesSlice.reducer,
        lotColors: lotColorsSlice.reducer,
        lotFamilies: lotFamiliesSlice.reducer,
        lotPositions: lotPositionsSlice.reducer,
        ports: portsSlice.reducer,
        singleBid: singleBidSlice.reducer,
        users: usersSlice.reducer,
        userInvite: inviteUserSlice.reducer,
        buyPositions: buyPositionsSlice.reducer,
        providers: providersSlice.reducer,
        providerGroups: providerGroupsSlice.reducer,
        offerLots: offerLotsSlice.reducer,
        paymentForm: paymentFormSlice.reducer,
        paymentTerms: paymentTermsSlice.reducer,
        offerPositions: offerPositionsSlice.reducer,
        questions: questionsSlice.reducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
