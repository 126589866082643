import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ILotMetric } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ILotMetricState {
    data: ILotMetric[];
    status: Status;
    error: string | null;
}

const initialState: ILotMetricState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchLotMetrics = createAsyncThunk(
    'lotMetrics/fetchLotMetrics',
    async () => {
        const response = await APIClient.getData('/lots/metrics');
        return response.data;
    }
);

export const lotMetricsSlice = createSlice({
    name: 'lotMetrics',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchLotMetrics.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchLotMetrics.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                state.data = action.payload;
            })
            .addCase(fetchLotMetrics.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            });
    },
});
