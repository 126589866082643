import { BidStages } from '../../../../modules/bids/enums';
import { IBidStage } from '../../../../modules/bids/interfaces';

export default (
    bidStages: IBidStage[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stageActions: { stage: BidStages; action: any }[]
) => [
    {
        id: '1',
        name: 'bids.stages.definition',
        slug: BidStages.DEFINITION,
        active: true,
        completed: false,
        subtext: '',
        icon: '/images/shopping.svg',
        startDate: bidStages.filter((s) => s.stage === BidStages.DEFINITION)[0]
            ?.startDate,
        endDate: bidStages.filter((s) => s.stage === BidStages.DEFINITION)[0]
            ?.endDate,
        actions: stageActions.filter((a) => a.stage === BidStages.DEFINITION)[0]
            .action,
    },
    {
        id: '2',
        name: 'bids.stages.bid',
        slug: BidStages.BID,
        active: false,
        completed: false,
        subtext: '',
        icon: '/images/proposal.svg',
        startDate: bidStages.filter((s) => s.stage === BidStages.BID)[0]
            ?.startDate,
        endDate: bidStages.filter((s) => s.stage === BidStages.BID)[0]?.endDate,
    },
    {
        id: '3',
        name: 'bids.stages.review',
        slug: BidStages.REVIEW,
        active: false,
        completed: false,
        subtext: '',
        icon: '/images/revision.svg',
        startDate: bidStages.filter((s) => s.stage === BidStages.REVIEW)[0]
            ?.startDate,
        endDate: bidStages.filter((s) => s.stage === BidStages.REVIEW)[0]
            ?.endDate,
    },
    {
        id: '4',
        name: 'bids.stages.prequalification',
        slug: BidStages.PREQUALIFICATION,
        active: false,
        completed: false,
        subtext: '',
        icon: '/images/pre-asign.svg',
        startDate: bidStages.filter(
            (s) => s.stage === BidStages.PREQUALIFICATION
        )[0]?.startDate,
        endDate: bidStages.filter(
            (s) => s.stage === BidStages.PREQUALIFICATION
        )[0]?.endDate,
    },
    {
        id: '5',
        name: 'bids.stages.negotiation',
        slug: BidStages.NEGOTIATION,
        active: false,
        completed: false,
        subtext: '',
        icon: '/images/negotiation.svg',
        startDate: bidStages.filter((s) => s.stage === BidStages.NEGOTIATION)[0]
            ?.startDate,
        endDate: bidStages.filter((s) => s.stage === BidStages.NEGOTIATION)[0]
            ?.endDate,
    },
    {
        id: '6',
        name: 'bids.stages.contracts',
        slug: BidStages.CONTRACTS,
        active: false,
        completed: false,
        subtext: '',
        icon: '/images/contracts.svg',
        startDate: bidStages.filter((s) => s.stage === BidStages.CONTRACTS)[0]
            ?.startDate,
        endDate: bidStages.filter((s) => s.stage === BidStages.CONTRACTS)[0]
            ?.endDate,
    },
    {
        id: '7',
        name: 'bids.stages.reception',
        slug: BidStages.RECEPTION,
        active: false,
        completed: false,
        subtext: '',
        icon: '/images/reception.svg',
        startDate: bidStages.filter((s) => s.stage === BidStages.RECEPTION)[0]
            ?.startDate,
        endDate: bidStages.filter((s) => s.stage === BidStages.RECEPTION)[0]
            ?.endDate,
    },
];
