/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';
import { Editor, EditorState, convertToRaw, ContentState } from 'draft-js';
import { useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import { RootState, useAppDispatch } from '../../../../store';

import {
    addNewLot,
    fetchLots,
} from '../../../../modules/bids/features/lotsSlice';
import { fetchLotTypes } from '../../../../modules/bids/features/lotTypesSlice';
import { fetchLotMetrics } from '../../../../modules/bids/features/lotMetricsSlice';
import { fetchBranches } from '../../../../modules/branches/features/branchesSlice';
import { fetchPorts } from '../../../../modules/ports/features';
import { ILotCreateAttr } from '../../../../modules/bids/interfaces';
import { fetchLotFamilies } from '../../../../modules/bids/features/lotFamiliesSlice';
import useAuth from '../../../../context/useAuth';
import { UserRoles } from '../../../../modules/users/enums';

type FormData = {
    code: string;
    descriptionEn: string;
    descriptionEs: string;
    generalSpecsRaw: string;
    generalSpecsPlain: string;
    typeId: string;
    metricId: string;
    branchId: string;
    familyId: string;
};

export default function AddLot() {
    const { user } = useAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm<FormData>();

    const { t, i18n } = useTranslation();

    const dispatch = useAppDispatch();
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const lotTypes = useSelector((state: RootState) => state.lotTypes);
    const lotMetrics = useSelector((state: RootState) => state.lotMetrics);
    const lotFamilies = useSelector((state: RootState) => state.lotFamilies);
    const branches = useSelector((state: RootState) => state.branches);
    const ports = useSelector((state: RootState) => state.ports);

    useEffect(() => {
        if (lotTypes.status === 'idle') {
            dispatch(fetchLotTypes());
        }
    }, [lotTypes.status, dispatch]);

    useEffect(() => {
        if (lotMetrics.status === 'idle') {
            dispatch(fetchLotMetrics());
        }
    }, [lotMetrics.status, dispatch]);

    useEffect(() => {
        if (branches.status === 'idle') {
            dispatch(fetchBranches());
        }
    }, [branches.status, dispatch]);

    useEffect(() => {
        if (ports.status === 'idle') {
            dispatch(fetchPorts());
        }
    }, [ports.status, dispatch]);

    useEffect(() => {
        if (lotFamilies.status === 'idle') {
            dispatch(fetchLotFamilies());
        }
    }, [lotFamilies.status, dispatch]);

    // const dispatch = useDispatch();
    // const branches = useSelector((state: RootState) => state.branches);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [generalSpecFocus, setGeneralSpecFocus] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editor = useRef<any>(null);
    function focusEditor() {
        if (editor && editor.current) {
            editor.current.focus();
        }
    }

    useEffect(() => {
        const raw = JSON.stringify(
            convertToRaw(editorState.getCurrentContent())
        );

        const plain = editorState.getCurrentContent().getPlainText();
        setValue('generalSpecsRaw', raw);
        setValue('generalSpecsPlain', plain);
    }, [editorState, setValue]);

    useEffect(() => {
        register('generalSpecsRaw');
        register('generalSpecsPlain');
    }, [register]);

    useEffect(() => {
        if (i18n.language === 'es') {
            registerLocale('es', es);
        } else {
            registerLocale('en', enUS);
        }
        setDefaultLocale(i18n.language);
    }, [i18n.language]);

    const onSubmit = async (data: FormData) => {
        try {
            const attributes: ILotCreateAttr = {
                ...data,
            };
            setAddRequestStatus('loading');
            await dispatch(addNewLot(attributes)).unwrap();
            setAddRequestStatus('success');
            const eState = EditorState.push(
                editorState,
                ContentState.createFromText(''),
                'remove-range'
            );
            setEditorState(eState);
            reset();
            dispatch(fetchLots());
        } catch (error) {
            setAddRequestStatus('error');
            // console.log(error);
        }
    };

    return (
        <div className="relative w-full  py-0 md:py-10 mb-20 mx-auto bg-gray-100 px-10">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex space-x-4 mb-4">
                    <div className="w-1/4">
                        <label
                            htmlFor="code"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('common.code')}*
                            <div className="mt-1">
                                <input
                                    id="code"
                                    type="text"
                                    className={`${
                                        errors.code
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('code', {
                                        required: true,
                                    })}
                                />
                                {errors.code && (
                                    <p className="text-red-600 ml-2">
                                        {errors.code.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>
                    {user?.role !== UserRoles.USER && (
                        <div className="w-1/4 ">
                            <label
                                htmlFor="branchId"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.branch')}*
                                <div className="mt-1">
                                    <select
                                        id="branchId"
                                        className={`${
                                            errors.branchId
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('branchId', {
                                            required: true,
                                            validate: (value) => value !== '',
                                        })}
                                    >
                                        <option value="">
                                            {branches.status === 'loading'
                                                ? t('common.loading')
                                                : t(
                                                      'lots.create.selectABranch'
                                                  )}
                                        </option>
                                        {branches.status === 'success' &&
                                            branches.branches.map((branch) => (
                                                <option
                                                    key={branch.id}
                                                    value={branch.id}
                                                >
                                                    {branch.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </label>
                            {errors.branchId && (
                                <p className="text-red-600 ml-2">
                                    {t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex-none md:flex space-x-4 justify-start items-center">
                    <div className="w-1/4">
                        <label
                            htmlFor="familyId"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('lots.family')}*
                            <div className="mt-1">
                                <select
                                    id="familyId"
                                    className={`${
                                        errors.familyId
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('familyId', {
                                        required: true,
                                        validate: (value) => value !== '',
                                    })}
                                >
                                    <option value="">
                                        {lotFamilies.status === 'loading'
                                            ? t('common.loading')
                                            : t('lots.create.selectAFamily')}
                                    </option>
                                    {lotFamilies.status === 'success' &&
                                        lotFamilies.data.map((family) => (
                                            <option
                                                key={family.id}
                                                value={family.id}
                                            >
                                                {family.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
                        {errors.familyId && (
                            <p className="text-red-600 ml-2">
                                {t('common.errors.fieldRequired')}
                            </p>
                        )}
                    </div>
                    <div className="w-1/4">
                        <label
                            htmlFor="typeId"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('lots.lotType')}*
                            <div className="mt-1">
                                <select
                                    id="typeId"
                                    className={`${
                                        errors.typeId
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('typeId', {
                                        required: true,
                                        validate: (value) => value !== '',
                                    })}
                                >
                                    <option value="">
                                        {lotTypes.status === 'loading'
                                            ? t('common.loading')
                                            : t('lots.create.selectAType')}
                                    </option>
                                    {lotTypes.status === 'success' &&
                                        lotTypes.types.map((type) => (
                                            <option
                                                key={type.id}
                                                value={type.id}
                                            >
                                                {type.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
                        {errors.typeId && (
                            <p className="text-red-600 ml-2">
                                {t('common.errors.fieldRequired')}
                            </p>
                        )}
                    </div>
                    <div className="w-1/4">
                        <label
                            htmlFor="metricId"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('lots.lotMetric')}*
                            <div className="mt-1">
                                <select
                                    id="metricId"
                                    className={`${
                                        errors.metricId
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('metricId', {
                                        required: true,
                                        validate: (value) => value !== '',
                                    })}
                                >
                                    <option value="">
                                        {lotMetrics.status === 'loading'
                                            ? t('common.loading')
                                            : t('lots.create.selectAMetric')}
                                    </option>
                                    {lotMetrics.status === 'success' &&
                                        lotMetrics.data.map((metric) => (
                                            <option
                                                key={metric.id}
                                                value={metric.id}
                                            >
                                                {metric.nameEn}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
                        {errors.metricId && (
                            <p className="text-red-600 ml-2">
                                {t('common.errors.fieldRequired')}
                            </p>
                        )}
                    </div>
                </div>
                {/* START DESCRIPTION ENGLISH */}
                <div className="w-full mt-4">
                    <label
                        htmlFor="descriptionEn"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('common.descriptionEn')}*
                        <div className="mt-1">
                            <input
                                id="descriptionEn"
                                type="text"
                                className={`${
                                    errors.descriptionEn
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('descriptionEn', {
                                    required: true,
                                })}
                            />
                            {errors.descriptionEn && (
                                <p className="text-red-600 ml-2">
                                    {errors.descriptionEn.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </label>
                </div>
                {/* START DESCRIPTION SPANISH */}
                <div className="w-full mt-4">
                    <label
                        htmlFor="descriptionEs"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('common.descriptionEs')}*
                        <div className="mt-1">
                            <input
                                id="descriptionEs"
                                type="text"
                                className={`${
                                    errors.descriptionEs
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                }`}
                                {...register('descriptionEs', {
                                    required: true,
                                })}
                            />
                            {errors.descriptionEs && (
                                <p className="text-red-600 ml-2">
                                    {errors.descriptionEs.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </label>
                </div>
                {/* START GENERAL SPECIFICATIONS */}
                <div className="w-full">
                    <p className="block mt-4">
                        <span className="text-sm font-medium text-gray-700">
                            {t('lots.generalSpecifications')}
                        </span>
                    </p>
                    <div
                        role="textbox"
                        className={`bg-white h-52 overflow-y-auto border border-slate-200 p-2 rounded-lg my-4 ${
                            generalSpecFocus && 'ring-2 ring-indigo-700'
                        }`}
                        onClick={focusEditor}
                        onKeyDown={focusEditor}
                    >
                        <Editor
                            ref={editor}
                            editorState={editorState}
                            onChange={setEditorState}
                            placeholder=""
                            onFocus={() => setGeneralSpecFocus(true)}
                            onBlur={() => setGeneralSpecFocus(false)}
                        />
                    </div>
                </div>
                {/* END GENERAL SPECIFICATIONS */}

                <div className="mt-4 text-center">
                    {addRequestStatus === 'error' && (
                        <p className="text-red-600 mb-2">
                            {t('common.errors.unknown')}
                        </p>
                    )}
                    {addRequestStatus === 'success' && (
                        <p className="text-green-600 mb-2">
                            {t('lots.create.success')}
                        </p>
                    )}
                    <button
                        disabled={addRequestStatus === 'loading'}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        {addRequestStatus === 'loading'
                            ? t('wait.message')
                            : t('common.save')}
                    </button>
                    {/* <Button
                        className="mt-4 bg-transparent w-full"
                        onClick={() => toggle()}
                        label={t('common.cancel')}
                        variant="link"
                    /> */}
                </div>
            </form>
        </div>
    );
}
