import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ILotFamily } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ILotFamilyState {
    data: ILotFamily[];
    status: Status;
    error: string | null;
}

const initialState: ILotFamilyState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchLotFamilies = createAsyncThunk(
    'lotFamilies/fetchLotFamilies',
    async () => {
        const response = await APIClient.getData('/lots/families');
        return response.data;
    }
);

export const lotFamiliesSlice = createSlice({
    name: 'lotFamilies',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchLotFamilies.pending, (state) => {
                state.data = [];
                state.status = Status.loading;
            })
            .addCase(fetchLotFamilies.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched bids to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchLotFamilies.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            });
    },
});
