/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

// import PageHeader from '../../../components/PageHeader';

import LotColor from './admin/LotColor';
import LotFamily from './admin/family/LotFamily';
import LotMetric from './admin/LotMetric';

export default function LotsList() {
    return (
        <div className="relative mb-20">
            <LotFamily />
            <LotColor />
            <LotMetric />
        </div>
    );
}
