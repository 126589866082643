import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBid, IBidFile, IBidUpdateAttr, IBuyLot } from '../interfaces';
import { updateBuyPositions } from './buyPositionsSlice';
import { createOfferLots, updateOfferLot } from './offerLotsSlice';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BidsState {
    data: IBid | null;
    status: Status;
    error: string | null;
}

const initialState: BidsState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const fetchSingleBid = createAsyncThunk(
    'singleBid/fetchSingleBid',
    async (id: string) => {
        const response = await APIClient.getData(`/bids/${id}`);
        return response.data;
    }
);

export const updateBidStage = createAsyncThunk(
    'singleBid/updateBidStage',
    async (data: { id: string; back: boolean }) => {
        const response = await APIClient.putData(
            `/bids/stage/${data.id}?back=${data.back}`
        );

        return response.data;
    }
);

export const updateBidProvGroup = createAsyncThunk(
    'singleBid/updateBidProvGroup',
    async ({ id, groups }: { id: string; groups: string[] }) => {
        await APIClient.putData(`/bids/${id}/groups`, {
            data: groups,
        });
    }
);

export const updateBid = createAsyncThunk(
    'singleBid/updateBid',
    async ({ data, id }: { data: IBidUpdateAttr; id: string }) => {
        const response = await APIClient.putData(`/bids/${id}`, {
            data,
        });
        return response.data;
    }
);

export const deleteBidProvGroup = createAsyncThunk(
    'singleBid/deleteBidProvGroup',
    async ({ id, groups }: { id: string; groups: string[] }) => {
        await APIClient.deleteData(`/bids/${id}/groups`, {
            data: groups,
        });
    }
);

export const createBidFiles = createAsyncThunk(
    'singleBid/createBidFiles',
    async (data: IBidFile[]) => {
        const response = await APIClient.postData(`/bids/files`, data);
        return response.data;
    }
);
export const singleBidSlice = createSlice({
    name: 'singleBid',
    initialState,
    reducers: {
        updateBidPositions: (state, action) => {
            let totalToUpdate = 0;
            const upBuyLots: IBuyLot[] = [];

            if (
                action.payload.newPositions &&
                action.payload.newPositions.length > 0
            ) {
                state.data?.buyLots?.forEach((bl) => {
                    const { positions } = bl;

                    action.payload.newPositions.forEach(
                        (up: {
                            lotPositionId: string;
                            volume: number | undefined;
                        }) => {
                            totalToUpdate += up.volume ?? 0;
                            positions?.push({
                                lotPositionId: up.lotPositionId,
                                volume: up.volume || 0,
                                buyLotId: bl.id || '',
                            });
                        }
                    );

                    upBuyLots.push(bl);
                });
            }

            if (
                action.payload.updatedPositions &&
                action.payload.updatedPositions.length > 0
            ) {
                state.data?.buyLots?.forEach((bl) => {
                    const { positions } = bl;

                    action.payload.newPositions.forEach(
                        (up: {
                            lotPositionId: string;
                            volume: number | undefined;
                        }) => {
                            totalToUpdate += up.volume ?? 0;
                            positions?.push({
                                lotPositionId: up.lotPositionId,
                                volume: up.volume || 0,
                                buyLotId: bl.id || '',
                            });
                        }
                    );

                    action.payload.updatedPositions.forEach(
                        (up: {
                            lotPositionId: string;
                            volume: number | undefined;
                        }) => {
                            if (positions) {
                                const position = positions?.find(
                                    (p) => p.lotPositionId === up.lotPositionId
                                );
                                if (position && position.volume && up.volume) {
                                    totalToUpdate +=
                                        up.volume - position.volume;
                                }
                                const positionIndex = positions?.findIndex(
                                    (p) => p.lotPositionId === up.lotPositionId
                                );

                                if (positionIndex !== -1) {
                                    if (up.volume && up.volume > 0) {
                                        positions[positionIndex].volume =
                                            up.volume;
                                    } else {
                                        positions.splice(positionIndex, 1);
                                    }
                                }
                            }
                        }
                    );
                    upBuyLots.push(bl);
                });
            }
            if (state.data && state.data.totalVolume) {
                state.data.totalVolume += totalToUpdate;
                state.data.buyLots = upBuyLots;
            }
        },
        removeFile: (state, action) => {
            if (state.data && state.data.files) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const newFiles = state.data.files.filter(
                    (f) => f.key !== action.payload
                );
                state.data.files = newFiles;
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSingleBid.pending, (state) => {
                state.data = null;
                state.status = Status.loading;
            })
            .addCase(fetchSingleBid.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array

                state.data = action.payload;
            })
            .addCase(fetchSingleBid.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(updateBidStage.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(updateBidStage.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(updateBidProvGroup.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(updateBidProvGroup.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(deleteBidProvGroup.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(deleteBidProvGroup.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? 'Unknown error';
            })
            .addCase(updateBuyPositions.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(createOfferLots.fulfilled, (state, action) => {
                state.status = Status.success;
                const findIndex = state.data?.buyLots?.findIndex(
                    (bl) => bl?.id === action?.payload?.buyLotId
                );
                if (
                    findIndex &&
                    findIndex !== -1 &&
                    state.data &&
                    state.data.buyLots
                ) {
                    state.data.buyLots[findIndex].offers = action.payload;
                }
            })
            .addCase(updateOfferLot.fulfilled, (state, action) => {
                state.status = Status.success;
                const findIndex = state.data?.buyLots?.findIndex(
                    (bl) => bl?.id === action?.payload?.buyLotId
                );
                if (
                    findIndex &&
                    findIndex !== -1 &&
                    state.data &&
                    state.data.buyLots
                ) {
                    state.data.buyLots[findIndex].offers = action.payload;
                }
            })
            .addCase(updateBid.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateBid.rejected, (state) => {
                state.status = Status.error;
            })
            .addCase(updateBid.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = {
                    ...state.data,
                    ...action.payload,
                };
            })
            .addCase(createBidFiles.rejected, (state) => {
                state.status = Status.error;
            })
            .addCase(createBidFiles.fulfilled, (state, action) => {
                state.status = Status.success;
                if (state.data) {
                    state.data = {
                        ...state.data,
                        files: action.payload ?? [],
                    };
                }
            });
    },
});

export const { updateBidPositions, removeFile } = singleBidSlice.actions;
