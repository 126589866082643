import writeXlsxFile from 'write-excel-file';
// import * as XLSX from 'xlsx';

// import { ILot, ILotColor } from '../../../../modules/bids/interfaces';

export default async function createFile(lots, colors) {
    const lotsPosJSON = lots
        .map((lot) =>
            lot?.positions?.map((position) => ({
                code: lot.code,
                position: position.position,
                volume: 0,
                metric: lot.metric?.nameEn,
                type: lot.type.name,
                descripcion: lot.descriptionEn,
                thickness: position.thickness,
                thicknessToleranceLess: position.thicknessToleranceLess,
                thicknessToleranceOver: position.thicknessToleranceOver,
                length: position.length,
                width: position.width,
                height: position.height,
                diameter: position.diameter,
                diameterUnit: position.diameterUnit,
                'kg/mt': position.kgMt,
                color: colors.find((c) => c.id === position.colorId)?.nameEn,
                backside: colors.find((c) => c.id === position.backSide)
                    ?.nameEn,
                'SS/Designation/Coating': position.ssDesignationCoating,
            }))
        )
        .flat();

    let currentCode = '';
    let currentPosition = 0;
    let color = '#ffffff';

    const objects = lots
        .map((lot) =>
            lot?.positions?.map((position) => {
                if (currentCode !== lot.code || currentCode === '') {
                    const newColor =
                        currentPosition % 2 === 0 ? '#ffffff' : '#aabbcc';
                    color = newColor;
                    currentPosition += 1;
                    currentCode = lot.code;
                }
                return [
                    { type: String, value: lot.code, backgroundColor: color },
                    {
                        type: String,
                        value: lot.type.name,
                        backgroundColor: color,
                    },
                    {
                        type: String,
                        value: lot.descriptionEn || '',
                        backgroundColor: color,
                    },
                    {
                        type: Number,
                        value: position.position || 0,
                        backgroundColor: color,
                    },
                    {
                        type: Number,
                        value: position.length || 0,
                        backgroundColor: color,
                    },
                    {
                        type: Number,
                        value: position.width || 0,
                        backgroundColor: color,
                    },
                    {
                        type: Number,
                        value: position.height || 0,
                        backgroundColor: color,
                    },
                    {
                        type: Number,
                        value: position.diameter || 0,
                        backgroundColor: color,
                    },
                    {
                        type: String,
                        value: position.diameterUnit || '',
                        backgroundColor: color,
                    },
                    {
                        type: String,
                        value: position.kgMt?.toString() || '',
                        backgroundColor: color,
                    },
                    {
                        type: String,
                        value:
                            colors.find((c) => c.id === position.colorId)
                                ?.nameEn || '',
                        backgroundColor: color,
                    },
                    {
                        type: String,
                        value:
                            colors.find((c) => c.id === position.backSide)
                                ?.nameEn || '',
                        backgroundColor: color,
                    },
                    {
                        type: String,
                        value: position.ssDesignationCoating || '',
                        backgroundColor: color,
                    },
                    { type: Number, value: 0, backgroundColor: '#b5f5ff' },
                    {
                        type: String,
                        value: lot.metric?.nameEn || '',
                        backgroundColor: color,
                    },
                    {
                        type: Number,
                        value: position.thickness || 0,
                        backgroundColor: color,
                    },
                    {
                        type: Number,
                        value: position.thicknessToleranceLess || 0,
                        backgroundColor: color,
                    },
                    {
                        type: Number,
                        value: position.thicknessToleranceOver || 0,
                        backgroundColor: color,
                    },
                ];
            })
        )
        .flat();

    // const objectsFinal = [];
    // const group = groupByCode(objects);
    // console.log(group);

    // Object.values(group).forEach((g: any) => {
    //     g.forEach((c: any) => {
    //         console.log(c);
    //     });
    // });

    const header = [
        'code',
        'type',
        'description',
        'position',
        'length',
        'width',
        'height',
        'diameter',
        'diameterUnit',
        'kgMt',
        'color',
        'backside',
        'ssDesignationCoating',
        'volume',
        'metric',
        'thickness',
        'thicknessToleranceLess',
        'thicknessToleranceOver',
    ].map((c) => ({
        value: c,
        backgroundColor: '#000000',
        color: '#ffffff',
        fontWeight: 'bold',
    }));

    const data = [header, ...objects];
    await writeXlsxFile(data, {
        fileName: 'file.xlsx',
    });

    // const worksheet = XLSX.utils.json_to_sheet(lotsPosJSON);

    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Posiciones Lotes');
    // XLSX.writeFile(workbook, 'lotes-original.xlsx');
    return lotsPosJSON;
}
