/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { IQualyOffer } from './interfaces';

type FormData = {
    family: string;
    provider: string;
    paymentTerm: string;
};

export default function Filter({
    reviewOfferLots,
    filter,
}: {
    reviewOfferLots: IQualyOffer[];
    filter: (family: string, provider: string, paymentTerm?: string) => void;
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const { register, watch, setValue } = useForm<FormData>();

    const [currentFilter, setCurrentFilter] = useState({
        family: '',
        provider: '',
        paymentTerms: '',
    });

    const providerFilter = watch('provider');
    const familyFilter = watch('family');
    const paymentTermsFilter = watch('paymentTerm');

    const [providers, setProviders] = useState<string[]>([]);
    const [families, setFamilies] = useState<string[]>([]);
    const [paymentTerms, setPaymentTerms] = useState<string[]>([]);

    useEffect(() => {
        if (searchParams.has('prov')) {
            const provi = providers.filter(
                (p) => p === searchParams.get('prov')
            )[0];
            setValue('provider', provi ?? '');
        }
        if (searchParams.has('fam')) {
            const fami = families.filter(
                (f) => f === searchParams.get('fam')
            )[0];
            setValue('family', fami ?? '');
        }
        if (searchParams.has('pay')) {
            const payi = paymentTerms.filter(
                (p) => p === searchParams.get('pay')
            )[0];
            setValue('paymentTerm', payi ?? '');
        }
    }, [setValue, searchParams, providers, families, paymentTerms]);

    useEffect(() => {
        if (reviewOfferLots.length > 0 && paymentTerms.length === 0) {
            const terms = reviewOfferLots
                .map((r) => r.paymentTerms)
                .filter((fil) => fil !== undefined) as unknown as string[];
            const uniqueTerms = Array.from(new Set(terms));
            setPaymentTerms(uniqueTerms);
        }
    }, [paymentTerms.length, reviewOfferLots]);

    useEffect(() => {
        if (reviewOfferLots.length > 0 && providers.length === 0) {
            const provi = reviewOfferLots
                .map((r) => r.provider)
                .filter((fil) => fil !== undefined) as unknown as string[];
            const uniqueProv = Array.from(new Set(provi));
            setProviders(uniqueProv);
        }
    }, [providers, reviewOfferLots]);

    useEffect(() => {
        if (reviewOfferLots.length > 0 && families.length === 0) {
            const fami = reviewOfferLots
                .map((r) => r.lotFamily)
                .filter((fil) => fil !== undefined) as unknown as string[];
            const uniqueFam = Array.from(new Set(fami));
            setFamilies(uniqueFam);
        }
    }, [families, reviewOfferLots]);

    useEffect(() => {
        if (reviewOfferLots.length > 0 && paymentTerms.length === 0) {
            const terms = reviewOfferLots
                .map((r) => r.paymentTerms)
                .filter((fil) => fil !== undefined) as unknown as string[];
            const uniqueTerms = Array.from(new Set(terms));
            setPaymentTerms(uniqueTerms);
        }
    }, [paymentTerms.length, providers, reviewOfferLots]);

    useEffect(() => {
        if (reviewOfferLots.length > 0 && providers.length === 0) {
            const provi = reviewOfferLots
                .map((r) => r.provider)
                .filter((fil) => fil !== undefined) as unknown as string[];
            const uniqueProv = Array.from(new Set(provi));
            setProviders(uniqueProv);
        }
    }, [providers, reviewOfferLots]);

    useEffect(() => {
        if (reviewOfferLots.length > 0 && families.length === 0) {
            const fami = reviewOfferLots
                .map((r) => r.lotFamily)
                .filter((fil) => fil !== undefined) as unknown as string[];
            const uniqueFam = Array.from(new Set(fami));
            setFamilies(uniqueFam);
        }
    }, [families, reviewOfferLots]);

    useEffect(() => {
        register('family');
        register('provider');
    }, [register]);

    useEffect(() => {
        if (
            currentFilter.provider !== providerFilter ||
            currentFilter.family !== familyFilter ||
            currentFilter.paymentTerms !== paymentTermsFilter
        ) {
            const params = new URLSearchParams();
            setCurrentFilter({
                family: familyFilter,
                provider: providerFilter,
                paymentTerms: paymentTermsFilter,
            });
            filter(familyFilter, providerFilter, paymentTermsFilter);
            if (familyFilter && familyFilter !== '') {
                params.append('fam', familyFilter);
                setSearchParams(params);
            } else if (searchParams.has('fam')) {
                searchParams.delete('fam');
                setSearchParams(searchParams);
            }
            if (providerFilter && providerFilter !== '') {
                params.append('prov', providerFilter);
                setSearchParams(params);
            } else if (searchParams.has('prov')) {
                searchParams.delete('prov');
                setSearchParams(searchParams);
            }
            if (paymentTermsFilter && paymentTermsFilter !== '') {
                params.append('pay', paymentTermsFilter);
                setSearchParams(params);
            } else if (searchParams.has('pay')) {
                searchParams.delete('pay');
                setSearchParams(searchParams);
            }
        }
    }, [
        providerFilter,
        familyFilter,
        currentFilter.provider,
        currentFilter.family,
        filter,
        currentFilter.paymentTerms,
        paymentTermsFilter,

        searchParams,
        setSearchParams,
    ]);

    return (
        <div className="w-full flex space-x-4 mr-10">
            <div className="mt-4">
                <h3>Filtros:</h3>
            </div>
            <div className="w-1/3">
                <label
                    htmlFor="provider"
                    className="block text-sm font-medium text-gray-700"
                >
                    <div className="mt-1">
                        <select
                            id="provider"
                            {...register('provider', {
                                required: true,
                                validate: (value) => value !== '',
                            })}
                        >
                            <option value="">Proveedores</option>
                            {providers.map((provider) => (
                                <option key={provider} value={provider}>
                                    {provider}
                                </option>
                            ))}
                        </select>
                    </div>
                </label>
            </div>
            <div className="w-1/3">
                <label
                    htmlFor="family"
                    className="block text-sm font-medium text-gray-700"
                >
                    <div className="mt-1">
                        <select
                            id="family"
                            {...register('family', {
                                required: true,
                                validate: (value) => value !== '',
                            })}
                        >
                            <option value="">Familias</option>
                            {families.map((family) => (
                                <option key={family} value={family}>
                                    {family}
                                </option>
                            ))}
                        </select>
                    </div>
                </label>
            </div>
            <div className="w-2/3">
                <label
                    htmlFor="paymentTerm"
                    className="block text-sm font-medium text-gray-700"
                >
                    <div className="mt-1">
                        <select
                            id="paymentTerm"
                            {...register('paymentTerm', {
                                required: true,
                                validate: (value) => value !== '',
                            })}
                        >
                            <option value="">Términos de pago</option>
                            {paymentTerms.map((paymentTerm) => (
                                <option key={paymentTerm} value={paymentTerm}>
                                    {paymentTerm}
                                </option>
                            ))}
                        </select>
                    </div>
                </label>
            </div>
        </div>
    );
}
