/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column, useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../../store';
// import Create from './components/Create';
import PageHeader from '../../../../components/PageHeader';

import { Button } from '../../../../components/elements/Buttons';
import { ILotColor } from '../../../../modules/bids/interfaces';
import LoadingAnimated from '../../../../components/elements/Loading';
import RoleCheck from '../../../../components/RoleCheck';
import { UserRoles } from '../../../../modules/users/enums';
import useAuth from '../../../../context/useAuth';

import { fetchLotColors } from '../../../../modules/bids/features/lotColorsSlice';

export default function LotColor() {
    const { user } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Se colocó este nombre para reutilizarlo
    const admin = useSelector((state: RootState) => state.lotColors);

    const navigate = useNavigate();
    const [showCreate, setShowCreate] = useState(false);

    useEffect(() => {
        if (admin.status === 'idle') {
            dispatch(fetchLotColors());
        }
    }, [admin, dispatch]);

    const columns = useMemo<Column<ILotColor>[]>(
        () => [
            {
                Header: t('common.name').toString(),
                accessor: 'nameEn',
            },
            {
                Header: t('common.code').toString(),
                accessor: 'code',
            },
        ],

        [t]
    );

    const data = useMemo(
        () => (admin.data.length > 0 ? admin.data : []),
        [admin]
    );

    const tableInstance = useTable(
        {
            columns,
            data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative mb-20">
            <RoleCheck
                allowedRoles={[UserRoles.ADMIN, UserRoles.SHOPPING_LEADER]}
                currentRole={user?.role || 'USER'}
            >
                {/* <Create show={showCreate} toggle={() => setShowCreate(false)} /> */}
                <Button
                    className="absolute right-0 top-0 z-10"
                    label={t('lots.admin.color.add')}
                    type="button"
                    onClick={() => navigate('/lots/add')}
                />
            </RoleCheck>
            <PageHeader title={t('lots.admin.color.title')} />

            {admin.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {admin.status === 'success' && admin.data.length === 0 && (
                <div className="text-center text-gray-500">
                    <h2 className="text-2xl">{t('common.listEmpty')}</h2>
                    <RoleCheck
                        allowedRoles={[
                            UserRoles.ADMIN,
                            UserRoles.SHOPPING_LEADER,
                        ]}
                        currentRole={user?.role || 'USER'}
                    >
                        <Button
                            className="mt-6"
                            label={t('lots.create.buttonTitle')}
                            type="button"
                            onClick={() => setShowCreate(!showCreate)}
                        />
                    </RoleCheck>
                </div>
            )}

            {admin.status === 'success' && admin.data.length > 0 && (
                <div className="overflow-x-auto shadow-md">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-slate-200 hover:bg-slate-300"
                                            {...row.getRowProps()}
                                            onClick={() => {
                                                navigate(
                                                    `/lots/edit/${row.original.id}`
                                                );
                                            }}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2 cursor-pointer"
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
