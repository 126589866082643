/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useMemo, useState } from 'react';

import { Column, useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';

import { ILotPosition } from '../../../../modules/bids/interfaces';

function EditableCell({
    value: initialValue,
    id,
    // updateMyData, // This is a custom function that we supplied to our table instance
    recordValue,
}: {
    value: string;
    id: string;
    recordValue: (id: string, value: string) => void;
}) {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue ?? 0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (e: any) => {
        if (!e.target.value) {
            setValue('');
        }
        if (e.target.value === '') {
            setValue('');
        }
        if (parseFloat(e.target.value) >= 0) {
            setValue(e.target.value);
        }

        // updateMyData(index, id, value);
        // recordValue(type, offerPositionId, value);
    };

    // We'll only update the external data when the input is blurred
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onBlur = (e: any) => {
        // updateMyData(index, id, value);
        recordValue(id, value);

        if (e.target.value === '') {
            setValue('0');
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFocus = (e: any) => {
        if (e.target.value === '0') {
            setValue('');
        }
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    // if (type === 'comments') {
    //     return (
    //         <textarea
    //             value={value ?? ''}
    //             onChange={onChange}
    //             onBlur={onBlur}
    //             onFocus={onFocus}
    //         />
    //     );
    // }
    return (
        <div className="w-[150px] flex justify-center items-center ml-2 text-sm">
            <input
                className="text-center"
                type="number"
                step={0.1}
                value={value ?? 0}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        </div>
    );
}

export default function LotPositionList({
    lotPositions,
    type,
    onChange,
    volumes,
}: {
    lotPositions: ILotPosition[] | [];
    type: string;
    onChange: (id: string, value: string) => void;
    volumes: { id: string; value: string }[];
}) {
    const { t } = useTranslation();

    const getVolume = (volumeId: string) => {
        const volume = volumes.find((v) => v.id === volumeId)?.value;
        return volume && parseInt(volume, 10) > 0
            ? parseFloat(volume).toString()
            : '0';
    };

    const columnsRolledSteel = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.ssDesignationCoating').toString(),
                accessor: 'ssDesignationCoating',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => (
                    <EditableCell
                        {...cell}
                        id={cell.row.original.id}
                        value={getVolume(cell.row.original.id)}
                        recordValue={onChange}
                    />
                ),
            },
        ],

        [t]
    );
    const columnsPaintedRolledSteel = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.color').toString(),
                accessor: 'colorId',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => cell.row.original.lotColor.code,
            },
            {
                Header: t('lots.position.backSide').toString(),
                accessor: 'backSide',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => {
                    if (cell.row.original?.backSideColor?.code) {
                        return cell.row.original.backSideColor.code;
                    }
                    return '';
                },
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => (
                    <EditableCell
                        {...cell}
                        id={cell.row.original.id}
                        value={getVolume(cell.row.original.id)}
                        recordValue={onChange}
                    />
                ),
            },
        ],

        [t]
    );

    const columnsPlates = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.thicknessToleranceLess').toString(),
                accessor: 'thicknessToleranceLess',
            },
            {
                Header: t('lots.position.thicknessToleranceOver').toString(),
                accessor: 'thicknessToleranceOver',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => (
                    <EditableCell
                        {...cell}
                        id={cell.row.original.id}
                        value={getVolume(cell.row.original.id)}
                        recordValue={onChange}
                    />
                ),
            },
        ],

        [t]
    );

    const columnsPipe = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.diameter').toString(),
                accessor: 'diameter',
            },
            {
                Header: t('lots.position.diameterUnit').toString(),
                accessor: 'diameterUnit',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => (
                    <EditableCell
                        {...cell}
                        id={cell.row.original.id}
                        value={getVolume(cell.row.original.id)}
                        recordValue={onChange}
                    />
                ),
            },
        ],

        [t]
    );

    const columnsBarAngle = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.height').toString(),
                accessor: 'height',
            },
            {
                Header: t('lots.position.diameter').toString(),
                accessor: 'diameter',
            },
            {
                Header: t('lots.position.diameterUnit').toString(),
                accessor: 'diameterUnit',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => (
                    <EditableCell
                        {...cell}
                        id={cell.row.original.id}
                        value={getVolume(cell.row.original.id)}
                        recordValue={onChange}
                    />
                ),
            },
        ],

        [t]
    );

    const columnsTube = useMemo<Column<ILotPosition>[]>(
        () => [
            {
                Header: t('lots.position.branchCode').toString(),
                accessor: 'branchCode',
            },
            {
                Header: t('common.description').toString(),
                accessor: 'descriptionEn',
            },

            {
                Header: t('lots.position.thickness').toString(),
                accessor: 'thickness',
            },
            {
                Header: t('lots.position.length').toString(),
                accessor: 'length',
            },
            {
                Header: t('lots.position.width').toString(),
                accessor: 'width',
            },
            {
                Header: t('lots.position.height').toString(),
                accessor: 'height',
            },
            {
                Header: t('lots.position.kgMt').toString(),
                accessor: 'kgMt',
            },
            {
                Header: t('lots.position.volume').toString(),
                accessor: 'volume',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell }: any) => (
                    <EditableCell
                        {...cell}
                        id={cell.row.original.id}
                        value={getVolume(cell.row.original.id)}
                        recordValue={onChange}
                    />
                ),
            },
        ],

        [t]
    );

    const getColumns = (lotType: string): Column<ILotPosition>[] => {
        switch (lotType) {
            case 'Rolled Steel':
                return columnsRolledSteel;
            case 'Pipe':
                return columnsPipe;
            case 'Bar/Angle':
                return columnsBarAngle;
            case 'Tube':
                return columnsTube;
            case 'Plates':
                return columnsPlates;
            case 'Painted Rolled Steel':
                return columnsPaintedRolledSteel;
            default:
                return columnsRolledSteel;
        }
    };

    const tableInstance = useTable(
        {
            columns: getColumns(type),
            data: lotPositions,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="relative">
            {/* {status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )} */}
            {lotPositions.length > 0 && (
                <div className="overflow-x-auto border-b border-slate-500">
                    <table
                        className="table-auto w-full mx-auto text-xs"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-left"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-slate-200 hover:bg-slate-300"
                                            {...row.getRowProps()}
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2"
                                                        // eslint-disable-next-line react/prop-types
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            // eslint-disable-next-line react/prop-types
                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
