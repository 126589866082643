/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';
import PageHeader from '../../../components/PageHeader';
import {
    fetchQuestions,
    updateQuestion,
} from '../../../modules/questions/features/questionsSlice';
import { IReviewQuestion } from '../../../modules/questions/interfaces';
import { RootState, useAppDispatch } from '../../../store';

import CreateQuestion from './components/Create';

export default function QuestionsAdmin() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const questions = useSelector((state: RootState) => state.questions);

    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [questionsList, setQuestionsList] = useState<IReviewQuestion[]>([]);
    const [editedQuestion, setEditedQuestion] = useState<IReviewQuestion>();
    const [updateState, setUpdateState] = useState('idle');
    const [showCreate, setShowCreate] = useState(false);

    useEffect(() => {
        if (questions.status === 'idle') {
            dispatch(fetchQuestions());
        }
    }, [dispatch, questions.status]);

    useEffect(() => {
        if (questions.data && questions.data.length > 0) {
            setQuestionsList([...questions.data]);
        }
    }, [questions.data]);

    const upQuestion = async (question: IReviewQuestion) => {
        try {
            setUpdateState('loading');
            await dispatch(updateQuestion(question)).unwrap();
            setUpdateState('success');
            setEditedQuestion(undefined);
        } catch (error) {
            setUpdateState('error');
        }
    };

    const handleQuestionClick = async () => {
        if (!editedQuestion) {
            return;
        }

        try {
            setUpdateState('loading');
            await dispatch(updateQuestion(editedQuestion)).unwrap();
            setUpdateState('success');
            setEditedQuestion(undefined);
        } catch (error) {
            setUpdateState('error');
        }
    };

    const handleClose = () => {
        setSelectedQuestion('');
        setEditedQuestion(undefined);
        setUpdateState('idle');
    };

    return (
        <div className="mb-20 relative">
            <PageHeader title={t('questions.title')} />
            {questions.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
            <CreateQuestion
                show={showCreate}
                toggle={() => setShowCreate(false)}
            />
            <Button
                className="absolute right-0 top-0 z-10"
                label={t('questions.create')}
                type="button"
                onClick={() => setShowCreate(!showCreate)}
            />
            <div className="p-4">
                <h3 className="text-gray-600 italic mb-8">
                    Haz click en las preguntas para editarlas
                </h3>
                <ul>
                    {questionsList.map((question) => (
                        <li
                            className="border-b-2 border-gray-200 p-2"
                            key={question.id}
                        >
                            {selectedQuestion === question.id ? (
                                <div>
                                    <div className="mb-2">
                                        <input
                                            autoFocus
                                            type="text"
                                            value={question.title}
                                            onFocus={() => {
                                                setUpdateState('idle');
                                            }}
                                            onChange={(event) => {
                                                const questionIndex =
                                                    questionsList.findIndex(
                                                        (q) =>
                                                            q.id === question.id
                                                    );
                                                if (questionIndex !== -1) {
                                                    const newQuestion = {
                                                        ...questionsList[
                                                            questionIndex
                                                        ],
                                                        title: event.target
                                                            .value,
                                                    };
                                                    setEditedQuestion(
                                                        newQuestion
                                                    );
                                                    setQuestionsList([
                                                        ...questionsList.slice(
                                                            0,
                                                            questionIndex
                                                        ),
                                                        newQuestion,
                                                        ...questionsList.slice(
                                                            questionIndex + 1
                                                        ),
                                                    ]);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="flex space-x-4 items-center">
                                        {updateState !== 'success' ? (
                                            <Button
                                                variant="success"
                                                disabled={!editedQuestion}
                                                size="sm"
                                                onClick={() => {
                                                    handleQuestionClick();
                                                }}
                                                label={
                                                    updateState === 'loading'
                                                        ? t('wait.message')
                                                        : t('common.save')
                                                }
                                            />
                                        ) : (
                                            <span className="text-green-900">
                                                {t('common.saved')}
                                            </span>
                                        )}
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            onClick={() => {
                                                handleClose();
                                            }}
                                            label={t('common.close')}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex">
                                    <Button
                                        variant="link"
                                        size="sm"
                                        disabled={updateState === 'loading'}
                                        onClick={() => {
                                            const newQuestion = {
                                                ...question,
                                                active: !question.active,
                                            };
                                            upQuestion(newQuestion);
                                        }}
                                        icon={
                                            question.active ? (
                                                <span className="text-green-600">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                        />
                                                    </svg>
                                                </span>
                                            ) : (
                                                <span className="text-gray-600">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                        />
                                                    </svg>
                                                </span>
                                            )
                                        }
                                        showLabel={false}
                                        label="Activar/Desactivar"
                                    />
                                    <Button
                                        variant="link"
                                        className="w-full text-left"
                                        onClick={() => {
                                            setSelectedQuestion(question.id);
                                        }}
                                        label={question.title}
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                                />
                                            </svg>
                                        }
                                    />
                                    <select
                                        className="w-1/3"
                                        value={question.category}
                                        onChange={(e) => {
                                            const newQuestion = {
                                                ...question,
                                                category: e.target.value,
                                            };
                                            upQuestion(newQuestion);
                                        }}
                                    >
                                        <option value="process">Proceso</option>
                                        <option value="reception">
                                            Recepción
                                        </option>
                                    </select>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
